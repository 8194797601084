import React from 'react';
import { Typography } from '@material-ui/core/';

const style = {
    DragAndDropContainer: {
        display: 'inline-block',
        position: 'relative',
        width: '100%',
        height: '100%'
    },

    DragAndDrop: {
        border: 'dashed grey 4px',
        backgroundColor: 'rgba(255,255,255,.9)',
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        zIndex: '9999'
    },

    DragAndDropText: {
        position: 'absolute',
        top: '30%',
        right: '0',
        left: '0',
        textAlign: 'center',
        color: 'grey',
        fontSize: '36px'
    }
};

class DragAndDrop extends React.Component {
    state = {
        drag: false
    };

    dropRef = React.createRef();
    dragCounter = 0;

    componentDidMount() {
        const div = this.dropRef.current;
        div.addEventListener('dragenter', this.handleDragIn);
        div.addEventListener('dragleave', this.handleDragOut);
        div.addEventListener('dragover', this.handleDrag);
        div.addEventListener('drop', this.handleDrop);
    }

    componentWillUnmount() {
        const div = this.dropRef.current;
        div.removeEventListener('dragenter', this.handleDragIn);
        div.removeEventListener('dragleave', this.handleDragOut);
        div.removeEventListener('dragover', this.handleDrag);
        div.removeEventListener('drop', this.handleDrop);
    }

    handleDrag = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    handleDragIn = e => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter++;
        if (
            e.dataTransfer.items &&
            e.dataTransfer.items.length > 0 &&
            !this.state.drag
        ) {
            this.setState({ drag: true });
        }
    };

    handleDragOut = e => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter--;
        if (this.dragCounter === 0 && this.state.drag) {
            this.setState({ drag: false });
        }
    };

    handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ drag: false });
        if (e.dataTransfer.files && e.dataTransfer.files.length === 1) {
            this.props.handleDrop(e.dataTransfer.files);
            this.dragCounter = 0;
        }
    };

    render() {
        return (
            <div style={style.DragAndDropContainer} ref={this.dropRef}>
                {this.state.drag && (
                    <div style={style.DragAndDrop}>
                        <Typography style={style.DragAndDropText}>
                            Drop your speech recording here
                        </Typography>
                    </div>
                )}
                {this.props.children}
            </div>
        );
    }
}

export default DragAndDrop;
