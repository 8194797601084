import React from 'react';
import { Typography } from '@material-ui/core/';
import PropTypes from 'prop-types';
import { availableLanguages, exercisesData, exercises, infos } from './Texts';
import Result from './Result';
import SpeakTooFastPageIntro from './SpeakTooFastPageIntro';
import SpeakTooFastPageOneWordAtTime from './SpeakTooFastOneWordAtTime';
import RecordingExercise from '../RecordingExercise';
import Navigation from '../Navigation';
import LanguageChange from '../LanguageChange';

class SpeakTooFast extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        lastLanguage: PropTypes.string.isRequired,
        user: PropTypes.object.isRequired,
        mySpeeches: PropTypes.object.isRequired,
        exercises: PropTypes.object.isRequired,
        setUserState: PropTypes.func.isRequired,
    };

    state = {
        language: this.props.lastLanguage
    };

    exerciseType = 'speakTooFast';
    title = 'I Speak Too Fast';
    subTitle = 'And would like to slow down';
    maximumPages = 7;
   

    updateStateViaProps = (state, status) => {
        this.setState({ [state]: status });
    };


    menu = () => {
        return (
            <LanguageChange
                language={this.state.language}
                availableLanguages={availableLanguages}
                updateStateViaProps={this.updateStateViaProps}
            />
        );
    };

    unlockExercise = unlockNum => {
        const currentLock = this.props.exercises.speakTooFast.unlocked;

        const speakTooFast = {
            ...this.props.exercises.speakTooFast,
            unlocked: unlockNum > currentLock ? unlockNum : currentLock
        };

        const exercises = {
            ...this.props.exercises,
            speakTooFast
        };

        this.props.setUserState({ exercises });
    };

    getContent = pageNumber => {
        const props = {
            key: `SpeakTooFastRecordingExercise${pageNumber}`,
            // From Router
            history: this.props.history,
            lastLanguage: this.props.lastLanguage,
            user: this.props.user,
            mySpeeches: this.props.mySpeeches,
            exercises: this.props.exercises,
            setUserState: this.props.setUserState,
            // Defined here
            exerciseType: 'speakTooFast',
            exerciseLanguage: this.exerciseLanguage,
            exercisesContent: exercises[
                `exercise${exercisesData[pageNumber].exerciseNumber}`],
            exerciseNumber: exercisesData[pageNumber].exerciseNumber,
            titleExercise: exercisesData[pageNumber].titleExercise,
            title: exercisesData[pageNumber].title,
            infos: infos,
            result: Result,
            menu: this.menu,
            unlockExercise: this.unlockExercise,
            language: this.state.language,
        };
        if (pageNumber) {
            switch (pageNumber) {
                case 1:
                    return <SpeakTooFastPageIntro />;
                case 2:
                case 3:
                case 5:
                case 6:
                case 7:
                    return <RecordingExercise {...props} />;
                case 4:
                    return <SpeakTooFastPageOneWordAtTime {...props} />;
                default:
                    return <Typography>Coming soon ...</Typography>;
            }
        } else {
            return <Typography>Failed.</Typography>;
        }
    };

    exerciseLanguage = () => {
        switch (this.state.language) {
            case 'nl-NL':
                return 'NL';
            default:
                return 'EN';
        }
    };

    render() {
        const pageNumber = Math.max(1, Math.min(
            parseInt(new URLSearchParams(
                this.props.location.search).get('page')) || 1,
            this.props.exercises.speakTooFast.unlocked,
            this.maximumPages,
        ));
        return (
            <React.Fragment>
                <Navigation
                    history={this.props.history}
                    location={this.props.location}
                    title={this.title}
                    subTitle={this.subTitle}
                    maximumPages={this.maximumPages}
                    pageNumber={pageNumber}
                    unlockedNum={this.props.exercises[this.exerciseType]}
                >
                    {this.getContent(pageNumber)}
                </Navigation>
            </React.Fragment>
        );
    }
}
export default SpeakTooFast;
