import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Range from './Range';
import ContainerHeader from './ContainerHeader';

import {Paper, Grid} from '@material-ui/core/';

class Result extends PureComponent {
    static propTypes = {
        print: PropTypes.bool.isRequired,
        data: PropTypes.object.isRequired,
        parkiParams: PropTypes.shape({
            minVolume: PropTypes.number.isRequired,
            maxPitch: PropTypes.number.isRequired,
            maxPace: PropTypes.number.isRequired,
        }).isRequired,
    };

    render() {
        const {
            data: {
                absolute_peak_whole: volume,
                absolute_pitch_whole: pitch,
                speaking_rate: pace,
            },
            parkiParams: { minVolume, maxPitch, maxPace },
        }  = this.props;

        const innerContent = (
            <Paper elevation={1} className="customPaper">
                <Grid container spacing={24}>
                    <Grid item xs={6} md={4}>
                        <Range
                            print={this.props.print}
                            type="Volume"
                            value={volume}
                            hoverValue={`${(volume * 100).toFixed(0)}% of audio input`}
                            min_label="Silent"
                            max_label="Loud"
                            min={0}
                            max={1.5 * minVolume}
                            goodRange="top"
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Range
                            print={this.props.print}
                            type="Pitch"
                            value={pitch}
                            hoverValue={`${pitch.toFixed(0)} hertz`}
                            min_label="Low"
                            max_label="High"
                            min={0}
                            max={3 * maxPitch}
                            goodRange="bottom"
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Range
                            print={this.props.print}
                            type="Pace"
                            value={pace}
                            hoverValue={`${(pace * 60).toFixed(0)} syllables per minute`}
                            min_label="Slow"
                            max_label="Fast"
                            min={0}
                            max={3 * maxPace}
                            goodRange="bottom"
                        />
                    </Grid>
                </Grid>
            </Paper>
        );

        if (this.props.print) {
            return (
                <Fragment>
                    <ContainerHeader print={this.props.print} title="Overview" className="nm-print"/>
                    <div>{innerContent}</div>
                </Fragment>
            );
        } else {
            return <div className="container">{innerContent}</div>;
        }
    }
}

export default Result;