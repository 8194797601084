import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Plotly from 'plotly.js-dist';
import Range from './Range';
import ContainerHeader from './ContainerHeader';
import { isVersion } from '../../../../helpers';

import {Paper, Grid} from '@material-ui/core/';

class Result extends PureComponent {
    static propTypes = {
        print: PropTypes.bool.isRequired,
        data: PropTypes.object.isRequired,
        sentences: PropTypes.arrayOf(PropTypes.object),
        version: PropTypes.string.isRequired,
    };

    calculatePoints() {
        const { data: { range_good_pace } } = this.props;
        const sentences = this.props.sentences || [];
        const pace_ll = range_good_pace[0];
        const pace_ul = range_good_pace[1];
        return sentences.reduce((points, { wpm, std_pitch, transcript }) => {
            if (wpm && std_pitch) {
                points.push({
                    id: points.length,
                    x: wpm,
                    y: std_pitch,
                    text: transcript,
                    color: Math.max(
                        0,
                        Math.abs(wpm - (pace_ul + pace_ll) / 2) - (pace_ul - pace_ll) / 2
                    ),
                });
            }
            return points;
        }, []);
    }

    renderPlot() {
        const {
            data: {
                range_good_pace: [pace_ll, pace_ul],
                // eslint-disable-next-line no-unused-vars
                range_good_pitch: [pitch_ll, pitch_ul],
            },
        } = this.props;
        const scatterData = this.calculatePoints();

        const trace = {
            x: scatterData.map(d => d.x),
            y: scatterData.map(d => d.y),
            text: scatterData.map(d => d.text),
            mode: 'markers',
            hoverinfo: 'text',
            marker: {
                size: 12,
                color: scatterData.map(d => d.color),
                colorscale: [[0.0, '#3165fe'], [1.0, 'rgb(165,0,38)']],
                cauto: false,
                cmin: 0,
                cmax: 40,
            },
        };

        Plotly.newPlot(
            'plot',
            [trace],
            {
                margin: {
                    pad: 0,
                    t: 0,
                },
                xaxis: {
                    title: '',
                    zeroline: false,
                    fixedrange: true,
                },
                yaxis: {
                    title: 'Intonation',
                    zeroline: false,
                    fixedrange: true,
                },
                shapes: [
                    {
                        type: 'rect',
                        xref: 'x',
                        yref: 'paper',
                        x0: pace_ll,
                        y0: 0,
                        x1: pace_ul,
                        y1: 1,
                        fillcolor: '#d3d3d3',
                        opacity: 0.2,
                        line: {
                            width: 0,
                        },
                    },
                    {
                        type: 'rect',
                        xref: 'paper',
                        yref: 'y',
                        x0: 0,
                        y0: pitch_ll,
                        x1: 1,
                        y1: 65,
                        fillcolor: '#d3d3d3',
                        opacity: 0.2,
                        line: {
                            width: 0,
                        },
                    },
                ],
                annotations: [
                    {
                        x: -0.05,
                        y: (65 + pitch_ll) / 2,
                        xref: 'paper',
                        yref: 'y',
                        text: '<b>Good</b>',
                        showarrow: false,
                        ax: 0,
                        ay: 0,
                        font: {
                            color: '#3165fe',
                        },
                    },
                    {
                        x: -0.05,
                        y: pitch_ll / 2,
                        xref: 'paper',
                        yref: 'y',
                        text: '<b>Not enough</b>',
                        showarrow: false,
                        ax: 0,
                        ay: 0,
                        font: {
                            color: '#3165fe',
                        },
                    },
                    {
                        x: (pace_ul + pace_ll) / 2,
                        y: -0.15,
                        xref: 'x',
                        yref: 'paper',
                        text: '<b>Good pace</b>',
                        ax: 0,
                        ay: 0,
                        showarrow: false,
                        font: {
                            color: '#3165fe',
                        },
                    },
                    {
                        x: (pace_ul + pace_ll) / 2 + (pace_ul - pace_ll) * 0.7,
                        y: -0.15,
                        xref: 'x',
                        yref: 'paper',
                        text: '<b>Too fast</b>',
                        ax: 0,
                        ay: 0,
                        showarrow: false,
                        font: {
                            color: '#3165fe',
                        },
                    },
                    {
                        x: (pace_ul + pace_ll) / 2 - (pace_ul - pace_ll) * 0.7,
                        y: -0.15,
                        xref: 'x',
                        yref: 'paper',
                        text: '<b>Too slow</b>',
                        ax: 0,
                        ay: 0,
                        showarrow: false,
                        font: {
                            color: '#3165fe',
                        },
                    },
                    {
                        x: 1.0,
                        y: -0.2,
                        xref: 'paper',
                        yref: 'paper',
                        text: 'Pace in words per minute',
                        ax: 0,
                        ay: 0,
                        showarrow: false,
                    },
                ],
            },
            { displayModeBar: false }
        );
    }

    render() {
        const { data, version } = this.props;
        const {
            wpm,                // version < 2.0.0
            long_pauses_norm,   // version < 2.0.0
            speaking_rate,      // version >= 2.0.0
            pause_rate,         // version >= 2.0.0
            average_peak_whole_norm,
            average_pitch_whole,
            range_good_pace,
            range_good_peak,
            range_good_long_pauses, // version < 2.0.0
            range_good_pauses,      // version >= 2.0.0
            range_good_pitch,
        } = data;
        const isV = c => isVersion(version, c);

        const delta_good_pace = range_good_pace[1] - range_good_pace[0];
        const delta_good_peak = range_good_peak[1] - range_good_peak[0];
        const delta_good_pauses = isV({lt: '2.0.0'})
            ? range_good_long_pauses[1] - range_good_long_pauses[0]
            : range_good_pauses[1] - range_good_pauses[0];
        const delta_good_pitch = range_good_pitch[1] - range_good_pitch[0];

        const innerContent = (
            <Paper elevation={1} className="customPaper">
                <Grid container spacing={24}>
                    <Grid item xs={6} md={3}>
                        <Range
                            print={this.props.print}
                            type="Pitch"
                            value={average_pitch_whole}
                            hoverValue={`${average_pitch_whole.toFixed(0)} hertz variation`}
                            min_label="Monotonous"
                            max_label="Theatrical"
                            min={Math.max(0, range_good_pitch[0] - delta_good_pitch)}
                            max={range_good_pitch[1] + delta_good_pitch}
                            goodRange="center"
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Range
                            print={this.props.print}
                            type="Pace"
                            value={isV({lt: '2.0.0'}) ? wpm : speaking_rate * 60}
                            hoverValue={
                                isV({lt: '2.0.0'})
                                    ? `${Math.round(wpm)} words per minute`
                                    : `${Math.round(speaking_rate * 60)} syllables per minute`
                            }
                            min_label="Too slow"
                            max_label="Too fast"
                            min={
                                isV({lt: '2.0.0'})
                                    ? Math.max(0, range_good_pace[0] - delta_good_pace)
                                    : Math.max(0, range_good_pace[0] * 60 - delta_good_pace * 60)
                            }
                            max={
                                isV({lt: '2.0.0'})
                                    ? range_good_pace[1] + delta_good_pace
                                    : range_good_pace[1] * 60 + delta_good_pace * 60
                            }
                            goodRange="center"
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Range
                            print={this.props.print}
                            type="Pauses"
                            value={isV({lt: '2.0.0'}) ? long_pauses_norm * 100 : pause_rate * 60}
                            hoverValue={
                                isV({lt: '2.0.0'})
                                    ? `${(long_pauses_norm * 100).toFixed(1)}% of pauses longer than 1 second`
                                    : `${Math.round(pause_rate * 60)} pauses per minute`
                            }
                            min_label="None"
                            max_label="Too many"
                            min={
                                isV({lt: '2.0.0'})
                                    ? Math.max(0, range_good_long_pauses[0] * 100 - delta_good_pauses * 100)
                                    : Math.max(0, range_good_pauses[0] * 60 - delta_good_pauses * 60)
                            }
                            max={
                                isV({lt: '2.0.0'})
                                    ? range_good_long_pauses[1] * 100 + delta_good_pauses * 100
                                    : range_good_pauses[1] * 60 + delta_good_pauses * 60
                            }
                            goodRange="center"
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Range
                            print={this.props.print}
                            type="Power"
                            hoverValue={`${(average_peak_whole_norm * 100).toFixed(0)}% volume variation`}
                            value={average_peak_whole_norm * 100}
                            min_label="Flat"
                            max_label="Extreme"
                            min={Math.max(0, range_good_peak[0] * 100 - delta_good_peak * 100)}
                            max={range_good_peak[1] * 100 + delta_good_peak * 100}
                            goodRange="center"
                        />
                    </Grid>
                </Grid>
            </Paper>
        );

        if (this.props.print) {
            return (
                <Fragment>
                    <ContainerHeader print={this.props.print} title="Overview" className="nm-print"/>
                    <div>{innerContent}</div>
                </Fragment>
            );
        } else {
            return <div className="container">{innerContent}</div>;
        }
    }
}

export default Result;