import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Typography } from '@material-ui/core/';

const style = {
    Metronome: {
        width: '100%',
        height: '100%',
        textAlign: 'Center'
    },
    MetronomeOn: {
        margin: '4px',
        backgroundColor: '#2EA4FF',
        borderRadius: '24px',
        display: 'inline-block',
        minWidth: '40px',
        minHeight: '40px'
    },
    MetronomeOff: {
        margin: '4px',
        backgroundColor: 'unset',
        borderRadius: '24px',
        display: 'inline-block',
        minWidth: '40px',
        minHeight: '40px'
    },
    Bpm: {
        margin: '2px'
    },
    Slider: {
        margin: '1em 0 1em 0',
        padding: '0 0.5em 0 0.5em'
    }
};

class Metronome extends React.Component {
    static propTypes = {
        updateStateViaProps: PropTypes.func,
        expectionalStateName: PropTypes.string,
    };

    state = {
        styleMetronome1: style.MetronomeOn,
        styleMetronome2: style.MetronomeOff,
        value: 100,
        play: "▶"
    };

    interval = null;

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    startInterval = bpm => {
        const intervalTime = 60000 / this.state.value;
        this.setState({play: this.state.play === "❚❚" ? "▶" : "❚❚"})
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        } else {
            this.interval = setInterval(this.toggleMetronome, intervalTime);
        }
    };

    toggleMetronome = () => {
        this.setState({
            styleMetronome1: this.state.styleMetronome2,
            styleMetronome2: this.state.styleMetronome1
        });
    };

    handleChange = event => {
        const intervalTime = 60000 / event.target.value;
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = setInterval(this.toggleMetronome, intervalTime);
        }
        this.setState({ value: event.target.value });
        this.props.updateStateViaProps && this.props.expectionalStateName ? 
        this.props.updateStateViaProps(this.props.expectionalStateName, event.target.value)
        : console.log("Can't update parent state")
    };

    render() {
        return (
            <div>
                <Grid
                    container
                    direction="row-reverse"
                    alignItems="center"
                    style={{ textAlign: 'center' }}
                    spacing={8}
                >
                    <Grid item xs={4} sm={2}>
                        <div style={style.Metronome}>
                            <span style={this.state.styleMetronome1} />
                            <span style={this.state.styleMetronome2} />
                        </div>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            className="btn--primary"
                            onClick={() =>
                                this.startInterval(this.state.value)
                            }
                            style={style.Bpm}
                        >
                            {this.state.play}
                        </Button>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <Typography>{this.state.value} BPM</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <input
                            type="range"
                            min={40}
                            max={220}
                            value={this.state.value}
                            step={5}
                            style={style.Slider}
                            onChange={this.handleChange}
                            className="metronomeSlider"
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default Metronome;
