// eslint-disable-next-line
import React from 'react';
import Metronome from './Metronome'

export const availableLanguages = {
    'nl-NL': 'Dutch (Netherlands)',
    'en-US': 'English (United States)'
};

export const exercises = {
    exercise1: {
        NL: {
            text: [
                <>
                    Toen de schemering gevallen was, klom hij dus de muur over
                    en liet zich zakken in de tuin van de toverkol, griste in
                    allerijl een handvol raponsjes weg en bracht die naar zijn
                    vrouw. Ze maakte er dadelijk sla van en at het met grote
                    gulzigheid. Maar het smaakte haar zo bijzonder goed, dat ze
                    er de volgende dag nog driemaal zoveel trek in had.
                </>,
                <>
                    Wilde ze rust hebben, dan moest de man nog een keer in de
                    tuin klimmen. Dus begon hij in de avondschemering weer zijn
                    tocht, maar toen hij zich van de muur liet zakken, schrok
                    hij ontzettend want de toverkol stond voor hem. “Hoe durf
                    je,” sprak ze met toornige blik, “om in mijn tuin te
                    klimmen en als een dief mijn raponsjes te stelen? Dat zal
                    je duur komen te staan.” - “Ach,” antwoordde hij, “laat
                    genade gelden voor recht. Ik heb alleen uit nood gehandeld;
                    mijn vrouw heeft uw raponsjesbed vanuit haar raam gezien en
                    had er zo'n groot verlangen naar dat ze was gestorven als
                    ze er niet van gegeten had.”
                </>
            ],
            syllables: 270,
            syllablesFactor: [0.8, 1.2],
        },
        EN: {
            text: [
                <>
                    At twilight, he clambered down over the wall into the
                    garden of the enchantress, hastily clutched a handful of
                    rampion, and took it to his wife. She at once made herself
                    a salad of it, and ate it greedily. It tasted so good to
                    her – so very good, that the next day she longed for it
                    three times as much as before. If he was to have any rest,
                    her husband must once more descend into the garden.
                </>,
                <>
                    In the gloom of evening therefore, he let himself down
                    again; but when he had clambered down the wall he was
                    terribly afraid, for he saw the enchantress standing before
                    him. “How can you dare,” said she with angry look, “descend
                    into my garden and steal my rampion like a thief? You shall
                    suffer for it!” “Ah,” answered he, “let mercy take the
                    place of justice, I only made up my mind to do it out of
                    necessity. My wife saw your rampion from the window, and
                    felt such a longing for it that she would have died if she
                    had not got some to eat.”
                </>
            ],
            syllables: 210,
            syllablesFactor: [0.8, 1.2],
        }
    },
    exercise2: {
        NL: {
            text: [
                <>
                    Op [en] een [en] dag [en] stond [en] de [en] vrouw [en]
                    voor [en] dat [en] raam [en] en [en] keek [en] in [en] de
                    [en] tuin [en] naar [en] beneden; [en] daar [en] zag [en]
                    ze [en] een [en] heel [en] bed [en] beplant [en] met [en]
                    de [en] mooiste [en] raponsjes, [en] en [en] die [en] waren
                    [en] zo [en] fris [en] en [en] zo [en] groen, [en] dat [en]
                    ze [en] ervan [en] watertandde [en] en [en] een [en] hevig
                    [en] verlangen [en] kreeg [en] van [en] die [en] raponsjes
                    [en] te [en] eten. [en] Elke [en] dag [en] werd [en] dat
                    [en] verlangen [en] groter [en] en [en] omdat [en] ze [en]
                    wist [en] dat [en] ze [en] er [en] toch [en] niets [en] van
                    [en] krijgen [en] kon, [en] werd [en] ze [en] steeds [en]
                    magerder [en] en [en] zag [en] er [en] bleek [en] en [en]
                    ellendig [en] uit. [en] Toen [en] werd [en] haar [en] man
                    [en] bang [en] en [en] vroeg: [en] “Wat [en] scheelt [en]
                    er [en] aan, [en] lieve [en] vrouw?” [en] “Ach,” [en] zei
                    [en] ze, [en] “als [en] ik [en] geen [en] raponsjes [en]
                    uit [en] de [en] tuin [en] achter [en] ons [en] huis [en]
                    kan [en] krijgen, [en] dan [en] zal [en] ik [en] sterven.”
                </>
            ],
            syllables: 140,
            syllablesFactor: [0.8, 1.2],
        },
        EN: {
            text: [
                <>
                    One [and] day [and] the [and] woman [and] was [and]
                    standing [and] by [and] this [and] window [and] and [and]
                    looking [and] down [and] into [and] the [and] garden, [and]
                    when [and] she [and] saw [and] a [and] bed [and] which
                    [and] was [and] planted [and] with [and] the [and] most
                    [and] beautiful [and] rampion, [and] and [and] it [and]
                    looked [and] so [and] fresh [and] and [and] green [and]
                    that [and] she [and] longed [and] for [and] it, [and] she
                    [and] quite [and] pined [and] away, [and] and [and] began
                    [and] to [and] look [and] pale [and] and [and] miserable.
                    [and] Then [and] her [and] husband [and] was [and] alarmed,
                    [and] and [and] asked: [and] “What [and] ails [and] you,
                    [and] dear [and] wife?” [and] “Ah,” [and] she [and]
                    replied, [and] “if [and] I [and] can’t [and] eat [and] some
                    [and] of [and] the [and] rampion, [and] which [and] is
                    [and] in [and] the [and] garden [and] behind [and] our
                    [and] house, [and] I [and] shall [and] die.” [and] The
                    [and] man, [and] who [and] loved [and] her, [and] thought:
                    [and] “Sooner [and] than [and] let [and] your [and] wife
                    [and] die, [and] bring [and] her [and] some [and] of [and]
                    the [and] rampion [and] yourself, [and] let [and] it [and]
                    cost [and] what [and] it [and] will.”
                </>
            ],
            syllables: 126,
            syllablesFactor: [0.8, 1.2],
        }
    },
    exercise3: {
        NL: {
            text: [
                <>
                    Toen werd de to·ver·kol kal·mer en zei: “Als het zo is als
                    je ver·telt, dan zal ik je toe·staan zo·veel ra·pon·sjes
                    mee te ne·men als je wilt, maar op één voor·waar·de: mij
                    moet je het kind ge·ven, dat je vrouw ter we·reld zal
                    bren·gen. Het zal goed ver·zorgd wor·den en ik zal er een
                    moe·der voor zijn.” In zijn angst be·loof·de de man van
                    al·les en nau·we·lijks was het kind·je ge·bo·ren of de
                    to·ver·kol kwam bin·nen en gaf aan het kind de naam
                    Ra·pon·sje en nam het mee.
                </>
            ],
            syllables: 118,
            syllablesFactor: [0.8, 1.2],
        },
        EN: {
            text: [
                <>
                    Then the en·chan·tress al·lowed her an·ger to be soft·ened,
                    and said to him: “If the case be as you say, I will al·low
                    you to take a·way with you as much ram·pion as you will,
                    on·ly I make one con·di·tion, you must give me the child
                    which your wife will bring in·to the world; it shall be
                    well trea·ted, and I will care for it like a mo·ther.” The
                    man in his ter·ror con·sen·ted to eve·ry·thing, and when
                    the wo·man was brought to bed, the en·chan·tress ap·peared
                    at once, gave the child the name of Ra·pun·zel, and took it
                    a·way with her.
                </>
            ],
            syllables: 116,
            syllablesFactor: [0.8, 1.2],
        },
        ExerciseTool: 
            <Metronome />
             
    }
};

export const infos = {
    info1: [
        <>
            Take a deep breath and say it all at once. Don’t faint, it should
            not take more than 1 minute.
        </>
    ],
    info2: [
        <>
            We put an “and” between each word, but don’t pronounce it.
        </>
    ],
    info3: [
        <>For this exercise, use a metronome.</>,
        <>
            First time, you use 100 bpm (beats per minute) and say one syllable
            per beat. You can increase the pace until you cannot pronounce it
            clearly anymore.
        </>
    ]
};

export const exercisesData = [
    {
        /* Null */
    },
    {
        exerciseNumber: 1,
        title: 'Prestissimo',
        titleExercise: 'Exercise I Speak Too Fast - Advanced: Prestissimo'
    },
    {
        exerciseNumber: 2,
        title: 'Staccato',
        titleExercise: 'Exercise I Speak Too Fast - Advanced: Staccato'
    },
    {
        exerciseNumber: 3,
        title: 'Metronome',
        titleExercise: 'Exercise I Speak Too Fast - Advanced: Metronome'
    }
];
