import React from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import uuid4 from 'uuid4';

import { ScaleLoader } from 'react-spinners';
import { Button, Typography, Grid, SnackbarContent } from '@material-ui/core/';

import { isLocal } from '../../helpers';
import { rebase } from '../../rebase';
import Recording from '../Recording';

const style = {
    Failed: {
        maxWidth: '570px',
        margin: '0 auto',
        textAlign: 'center'
    },
    SnackbarInfo: {
        margin: '1em 0'
    }
};

class SpeechRecording extends React.Component {
    static propTypes = {
        // From Router
        user: PropTypes.object.isRequired,
        mySpeeches: PropTypes.object.isRequired,
        exercises: PropTypes.object.isRequired,
        setUserState: PropTypes.func.isRequired,
        parkiParams: PropTypes.object,
        // From index.js
        exerciseNumber: PropTypes.number.isRequired,
        exerciseType: PropTypes.string.isRequired,
        parkinsonException: PropTypes.bool,
        titleExercise: PropTypes.string.isRequired,
        // From RecordingExercise (parent)
        exerciseInfo: PropTypes.arrayOf(PropTypes.object).isRequired,
        language: PropTypes.string.isRequired,
        reportViewed: PropTypes.bool.isRequired,
        updateStateViaProps: PropTypes.func.isRequired,
        resetState: PropTypes.func.isRequired,
    };

    state = {
        recording: false,
        report: undefined
    };

    _isMounted = false;
    rebaseReportRef = null;
    recordingRef = React.createRef();
    uploadTask = null;
    maxFileSize = 50 * 2 ** 20;
    speechId = null;
    lastReports = '';
    bind = false;

    //#region upload development code
    //to activate test
    developmentTest = isLocal();

    addDevelopmentTest = () => {
        if (this.developmentTest)
            return (
                <div>
                    <input type="file" onChange={this.fileChangedHandler} />
                    <button
                        onClick={this.submitTest}
                        disabled={this.state.selectedFile === undefined}
                    >
                        Upload test
                    </button>
                </div>
            );
    };

    fileChangedHandler = event => {
        this.setState({ selectedFile: event.target.files[0] });
    };

    submitTest = event => {
        event.preventDefault();
        let file, title;
        this.props.updateStateViaProps('uploadStatus', 'COMPRESSING');
        file = this.state.selectedFile;
        title = this.props.titleExercise;
        if (file != null && title != null) this.upload(file, title);
    };
    //#endregion

    componentWillMount() {
        this._isMounted = true;
        if (!this.props.reportViewed) {
            const exerciseType = this.props.exercises[this.props.exerciseType];
            this.lastReports = exerciseType && exerciseType.lastReports;
            const id = this.lastReports &&
                this.lastReports[this.props.exerciseNumber.toString()];
            if (id) this.checkForDeletedSpeech(id);
        } else this.props.updateStateViaProps('reportViewed', false);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.report && prevState.report !== this.state.report) {
            if (this.state.report.status === 'DONE') this.setReport();
            else if (
                this.state.report.status === 'PROCESSING' &&
                !this.props.titleExercise.toLocaleLowerCase().includes(
                    'one, two, three test') &&
                !this.bind
            )
                this.saveRunningUpload();
            else this.props.updateStateViaProps('remove', true);
        }
    }

    checkForDeletedSpeech = id => {
        rebase
            .fetch(`speeches/${id}`, {
                context: this,
                onFailure: this.onSyncError
            })
            .then(speech => {
                if (!speech || speech.deleted) {
                    let exercises = this.props.exercises;
                    exercises[this.props.exerciseType].lastReports[
                        this.props.exerciseNumber.toString()
                    ] = null;

                    if (this._isMounted)
                        this.props.setUserState({ exercises });
                } else this.bindReport(id);
            });
    };

    bindReport = id => {
        this.rebaseReportRef = rebase.bindToState(`reports/${id}`, {
            context: this,
            state: 'report',
            onFailure: err => {
                console.log('Failed to get report. Error:', err);
            }
        });
        this.bind = true;
    };

    componentWillUnmount() {
        this._isMounted = false;
        if (this.rebaseReportRef) rebase.removeBinding(this.rebaseReportRef);
    }

    saveRunningUpload = () => {
        this.props.updateStateViaProps('remove', true);
        let lastReports;

        if (this.props.exercises[this.props.exerciseType])
            lastReports = {
                ...this.props.exercises[this.props.exerciseType].lastReports,
                [this.props.exerciseNumber.toString()]: this.speechId
            };
        else lastReports = {
            [this.props.exerciseNumber.toString()]: this.speechId
        };

        const type = {
            ...this.props.exercises[this.props.exerciseType],
            lastReports: lastReports
        };

        const exercises = {
            ...this.props.exercises,
            [this.props.exerciseType]: type
        };

        this.props.setUserState({ exercises });
    };

    onRecordingEnable = () => {
        this.setState({
            recording: true
        });
    };

    onRecordingDisable = () => {
        this.setState({
            recording: false
        });
    };

    onSubmit = async event => {
        event.preventDefault();
        let file, title;
        if (this.state.recording) {
            this.setState({ recording: false });
            this.props.updateStateViaProps('uploadStatus', 'COMPRESSING');
            file = await this.recordingRef.current.getAudioBlob();
            title = this.props.titleExercise;
            this.upload(file, title);
        } else {
            console.log('Nothing has been recorded');
        }
    };

    setReport = () => {
        this.props.updateStateViaProps('reportViewed', true);
        this.props.updateStateViaProps('report', this.state.report);
        this.props.updateStateViaProps('finish', true);
    };

    upload = (file, title) => {
        this.speechId = uuid4();
        const uploadsRef = firebase
            .storage()
            .ref()
            .child('uploads');
        const fileRef = uploadsRef.child(this.speechId);
        const metaData = {
            contentType: file.type,
            contentLanguage: this.props.language.split('-')[0],
            customMetadata: {
                language: this.props.language
            }
        };
        this.uploadTask = fileRef.put(file, metaData);
        this.props.updateStateViaProps('uploadStatus', 'UPLOADING');
        console.log(
            `Uploading (${file.size} bytes) as ${fileRef.name} ` +
                JSON.stringify(metaData)
        );
        this.uploadTask.on(
            'state_changed',
            upload => {
                this.props.updateStateViaProps(
                    'progress',
                    (upload.bytesTransferred / upload.totalBytes) * 100
                );
                switch (upload.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                    default:
                        console.log('Unknown snapshot status');
                }
            },
            error => {
                console.log('Upload failed: ' + error.code);
                this.props.updateStateViaProps('uploadStatus', 'READY');
                switch (error.code) {
                    case 'storage/canceled':
                        break;
                    default:
                        console.log(error);
                        this.props.updateStateViaProps(
                            'uploadStatus',
                            'FAILED'
                        );
                }
            },
            () => {
                console.log('Upload finished');
                // User rebase directly to avoid state mess
                let data = {
                    id: this.speechId,
                    title: title,
                    timestamp: Date.now(),
                    owner: this.props.user.uid,
                    ownerEmail: this.props.user.email,
                };
                if (this.props.parkinsonException) {
                    data.speechType = 'PLVT exercise';
                    if (this.props.parkiParams) {
                        data = {
                            ...data,
                            ...Object.fromEntries(
                                Object.entries(this.props.parkiParams)
                                    .map(([k, v]) => ['parki_' + k, v])
                            ),
                        }
                    }
                }
                rebase
                    .post(`speeches/${this.speechId}`, { data })
                    .then(() =>
                        rebase.post(
                            `users/${this.props.user.uid}/mySpeeches/${
                                this.speechId
                            }`,
                            { data: true }
                        )
                    )
                    .then(() =>
                        this.props.setUserState({
                            lastLanguage: this.props.language,
                        })
                    )
                    .then(() => this.waitForSpeech(this.speechId))
                    .then(() => {
                        console.log(`Generating report ${this.speechId}`);
                        this.props.updateStateViaProps('remove', true);
                        rebase.bindToState(`reports/${this.speechId}`, {
                            context: this,
                            state: 'report',
                            onFailure: err => {
                                console.log(
                                    'Failed to get report. Error:',
                                    err
                                );
                            }
                        });
                    })
                    .catch(err => {
                        console.log(err.message);
                    });
            }
        );
    };

    waitForSpeech = speechId =>
        new Promise(resolve => {
            const check = () => {
                if (speechId in this.props.mySpeeches) {
                    resolve();
                } else {
                    setTimeout(check, 100);
                }
            };
            setTimeout(check, 100);
        });

    getLoadingText = () => {
        if (this.state.report.step) {
            const { number, total, name } = this.state.report.step;
            switch (name) {
                case 'generate_transcript': // Old report
                case 'analyze_with_praat': // Report >= 2.0.0
                    return (
                        <Grid item xs={12}>
                            <Typography>Generating report.</Typography>
                            <Typography>
                                Winston is looking for his reading glasses.
                            </Typography>
                            <Typography>
                                Unbelievable but true, this can take a few
                                minutes.
                            </Typography>
                            <Typography>
                                ({number}/{total})
                            </Typography>
                        </Grid>
                    );
                case 'analyze_audio': // Old report
                case 'analyze_with_drift': // Report >= 2.0.0
                    return (
                        <Grid item xs={12}>
                            <Typography>Generating report.</Typography>
                            <Typography>
                                Winston needs some time to think.
                            </Typography>
                            <Typography>
                                He needs a whisky for that.
                            </Typography>
                            <Typography>
                                ({number}/{total})
                            </Typography>
                        </Grid>
                    );
                case 'finishing_up':
                    return (
                        <Grid item xs={12}>
                            <Typography>Generating report.</Typography>

                            <Typography>
                                Let Winston finish his glass of whisky... or
                                bottle.
                            </Typography>
                            <Typography>
                                <br />({number}/{total})
                            </Typography>
                        </Grid>
                    );
                default:
                    return (
                        <Grid item xs={12}>
                            <Typography>Generating report.</Typography>
                            <Typography>
                                Unbelievable but true, this can take a while.
                            </Typography>
                            <Typography>
                                <br /> ({number}/{total})
                            </Typography>
                        </Grid>
                    );
            }
        } else {
            return (
                <Grid item xs={12}>
                    <Typography>Generating report.</Typography>
                    <Typography>
                        Unbelievable but true, this can take a while.
                    </Typography>
                </Grid>
            );
        }
    };

    render() {
        return (
            <React.Fragment>
                {this.state.report === undefined ? (
                    <form onSubmit={this.onSubmit} ref={this.formRef}>
                        {this.props.exerciseInfo}
                        {this.props.parkinsonException ? (
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <Typography gutterBottom>
                                        Klik op de knop met de microfoon.
                                        Waarschijnlijk vraagt je browser om
                                        toestemming. Aanvaard dit.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography gutterBottom>
                                        Vanaf de klok start, begin je te
                                        spreken.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography gutterBottom>
                                        Spreek met een
                                        <strong className="is-custom-strong">
                                            &nbsp;luide
                                        </strong> en
                                        <strong className="is-custom-strong">
                                            &nbsp;lage
                                        </strong> stem.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography gutterBottom>
                                        Eenmaal klaar met spreken, klik op
                                        Submit.
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            <>
                                <Typography gutterBottom>
                                    Select the language of the exercise and
                                    click on the microphone.
                                </Typography>
                                <Typography gutterBottom>
                                    Your browser might ask permission to use
                                    the microphone. Start reading the text
                                    below once the counter starts.
                                </Typography>
                                <Typography gutterBottom>
                                    Once finished reading, click on submit.
                                </Typography>
                            </>
                        )}
                        <Recording
                            ref={this.recordingRef}
                            maxFileSize={this.maxFileSize}
                            onRecordingEnable={this.onRecordingEnable}
                            onRecordingDisable={this.onRecordingDisable}
                        />
                        <Button
                            className="icon-button btn--primary"
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={!this.state.recording}
                        >
                            Submit
                        </Button>
                        {this.addDevelopmentTest()}
                    </form>
                ) : this.state.report &&
                  this.state.report.status === 'ERROR' ? (
                    <Grid container spacing={24}>
                        <Grid item xs={12} style={style.Failed}>
                            <SnackbarContent
                                className="is-error-snackbar"
                                message="Upload failed. Please upload another speech."
                                style={style.SnackbarInfo}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                className="icon-button btn--primary"
                                onClick={() => {
                                    this.props.resetState();
                                    this.bind = false;
                                    this.setState({ report: undefined });
                                }}
                            >
                                Retry
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <div className="loader">
                        <ScaleLoader color="#36f" />
                        {this.state.report && this.state.report.status ? (
                            <Grid container spacing={16}>
                                {this.getLoadingText()}
                            </Grid>
                        ) : null}
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default SpeechRecording;
