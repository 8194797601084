import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Range extends Component {
	static propTypes = {
		print: PropTypes.bool.isRequired,
		type: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		hoverValue: PropTypes.string.isRequired,
		min_label: PropTypes.string.isRequired,
		max_label: PropTypes.string.isRequired,
		min: PropTypes.number.isRequired,
		max: PropTypes.number.isRequired,
		goodRange: PropTypes.oneOf(['bottom', 'center', 'top']),
	};

	render() {
		const { type, value, hoverValue, min_label, max_label, min, max, goodRange } = this.props;
		// Calculate percentage between the range of the min and max values
		// For debug: let valueInPercentage = {pitch: 0, pace: 100./3., pauses: 200./3., power: 100}[type.toLowerCase()];//((value - min) * 100) / (max - min);
		let valueInPercentage = (value - min) * 100 / (max - min);
		// Don't allow to be outside the range
		valueInPercentage = Math.min(100, Math.max(0, valueInPercentage));
		// Calculate position of arrow in relation to the bar
		let positionArrow = 15 + Math.round(220 - 220 * (valueInPercentage / 100));
		// Calculate position text. Text is 15px lower than arrow
		let positionText = positionArrow + 15;

		return (
			<div title={hoverValue}>
				<svg height="270" width="100%">
					<defs>
						<linearGradient
							id="grad_bottom"
							x1="0%"
							y1="0%"
							x2="0%"
							y2="100%"
						>
							<stop
								offset="50%"
								style={{stopColor: '#75beff', stopOpacity: 1}}
							/>
							<stop
								offset="66.7%"
								style={{stopColor: '#163ac9', stopOpacity: 1}}
							/>
						</linearGradient>
						<linearGradient
							id="grad_top"
							x1="0%"
							y1="0%"
							x2="0%"
							y2="100%"
						>
							<stop
								offset="33.3%"
								style={{stopColor: '#163ac9', stopOpacity: 1}}
							/>
							<stop
								offset="50%"
								style={{stopColor: '#75beff', stopOpacity: 1}}
							/>
						</linearGradient>
						<linearGradient
							id="grad_center"
							x1="0%"
							y1="0%"
							x2="0%"
							y2="100%"
						>
							<stop
								offset="16.7%"
								style={{stopColor: '#75beff', stopOpacity: 1}}
							/>
							<stop
								offset="33.3%"
								style={{stopColor: '#163ac9', stopOpacity: 1}}
							/>
							<stop
								offset="66.7%"
								style={{stopColor: '#163ac9', stopOpacity: 1}}
							/>
							<stop
								offset="83.3%"
								style={{stopColor: '#75beff', stopOpacity: 1}}
							/>
						</linearGradient>

						<path
							id="Triangle"
							d="M 0 0 L 18 10 L 0 20 z"
							fill="#5b5b5b"
							stroke="white"
							strokeWidth="1"
						/>
					</defs>

					<text x="82" y="10" fontSize="13" textAnchor="middle">
						{max_label}
					</text>
					<text x="82" y="265" fontSize="13" textAnchor="middle">
						{min_label}
					</text>
					<rect
						width="20"
						height="220"
						fill={`url(#grad_${goodRange})`}
						x="72"
						y="25"
					/>
					{
						goodRange !== 'bottom' &&
						< line
							x1="72"
							y1="98.3" // 25 + 220/3
							x2="92"
							y2="98.3" // 25 + 220/3
							style={{stroke: 'white', strokeWidth: 1}}
						/>
					}
					{
						goodRange !== 'top' &&
						<line
							x1="72"
							y1="171.7" // 25 + 220*2/3
							x2="92"
							y2="171.7" // 25 + 220*2/3
							style={{stroke: 'white', strokeWidth: 1}}
						/>
					}
					<use x="62" y={positionArrow} xlinkHref="#Triangle" />

					<text
						x="58"
						y={positionText}
						textAnchor="end"
						width="62"
						fontSize="15"
					>
						{type.toUpperCase()}
					</text>
				</svg>
			</div>
		);
	}
}

export default Range;
