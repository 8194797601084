import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import firebase from 'firebase';

import {
    AppBar,
    CssBaseline,
    Divider,
    Drawer,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    ListItemIcon,
    withStyles } from '@material-ui/core/';

import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import CloseIcon from '@material-ui/icons/Close';
import { site } from './../config.js';
import { rebase } from '../rebase';

const drawerWidth = 300;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: drawerWidth,
        backgroundColor: '#3E67FF',
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            display: 'none'
        },
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },

    CloseIcon: {
        position: 'absolute',
        right: '0px',
        top: '0px'
    },

    toolbar: {
        minHeight: '32px'
    },

    drawerPaper: {
        width: drawerWidth,
    },

    logo: {
        maxWidth: '250px',
        height: '75px',
        margin: '0 1em 1em 1em',
        cursor: 'pointer'
    },
});

const style = {
    Active: {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },

};

class Header extends React.Component {
    state = {
        mobileOpen: false,
    };

    static propTypes = {
        history: PropTypes.object.isRequired
    };

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };

    doSignOut = () => {
        rebase.reset();
        firebase.auth().signOut()
            .then(() => this.props.history.push('/'))
            .catch(err => console.log('Logout failed: ' + err));
    };

    closeWhenClick() {
        if (this.state.mobileOpen)
            this.handleDrawerToggle();
    }

    render() {
        const { classes, theme } = this.props;
        const currentLocation = this.props.history.location.pathname;
        const closeButtonClassNames = classes.CloseIcon + ' hide-on-desktop';

        const drawer = (
            <React.Fragment>
                <IconButton
                    aria-label="Close menu"
                    onClick={this.handleDrawerToggle}
                    className={closeButtonClassNames}
                >
                    <CloseIcon />
                </IconButton>

                <div className={classes.toolbar} />
                <Link to="/" className="nav-link">
                    <img
                        type="image/svg+xml"
                        src={site.logo}
                        alt={'Logo ' + site.name}
                        className={classes.logo}
                        onClick={() => {this.closeWhenClick()}}
                    />
                </Link>
                <Divider />
                <List>
                    <Link to="/reports/" className="nav-link">
                        <ListItem
                            button
                            key="Reports"
                            style={currentLocation.includes('reports') ? style.Active : null}
                            onClick={() => {this.closeWhenClick()}}
                        >
                            Reports
                        </ListItem>
                    </Link>
                    <Link to="/exercises/" className="nav-link">
                        <ListItem
                            button
                            key="Exercises"
                            style={currentLocation.includes('exercises') ? style.Active : null}
                            onClick={() => {this.closeWhenClick()}}
                        >
                            Exercises
                        </ListItem>
                    </Link>
                </List>
                <Divider />
                <List>

                    <Link to="/add-speech/" className="nav-link">
                        <ListItem
                            button
                            key="AddSpeech"
                            style={currentLocation.includes('add-speech') ? style.Active : null}
                            onClick={() => {this.closeWhenClick()}}
                        >
                            <ListItemIcon className={classes.icon}>
                                <AddIcon />
                            </ListItemIcon>
                            Add speech
                        </ListItem>
                    </Link>
                    <Link
                        to={currentLocation}
                        className="nav-link"
                        onClick={() => {this.doSignOut()}}
                   >
                        <ListItem
                            button
                            key="Logout">

                            <ListItemIcon className={classes.icon}>
                                <LogoutIcon />
                            </ListItemIcon>
                            Logout
                        </ListItem>
                    </Link>
                </List>
                <Divider />
                <List>
                    <a href={site.website}
                        target="_blank"
                        className="nav-link"
                        rel="noopener noreferrer"
                        title={'Website ' + site.name} >
                        <ListItem button key={'About ' + site.name}>
                            <ListItemText
                                primary={"About " +  site.name}
                                onClick={() => {this.closeWhenClick()}}
                            />
                        </ListItem>
                    </a>
                </List>
            </React.Fragment>
        );

        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="Open menu"
                            onClick={this.handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <nav className={classes.drawer}>
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden mdUp implementation="css">
                        <Drawer
                            container={this.props.container}
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={this.state.mobileOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>

                    <Hidden smDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
            </div>
        );
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
    container: PropTypes.object,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Header);