import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Card, CardMedia, withStyles } from '@material-ui/core/';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const styles = () => ({
    Paper: {
        width: '400px'
    }
});

const style = {
    Card: {
        width: '100%',
        position: 'relative'
    },
    TextContent: {
        margin: '1em 0 -1em'
    },
    Height100: {
        height: '100%' 
    }
};

class VideoPopUp extends React.Component {
    static propTypes = {
        children: PropTypes.any.isRequired,
        videoTitle: PropTypes.string.isRequired,
        videoLinkTitle: PropTypes.string.isRequired,
        videoLink: PropTypes.string.isRequired,
        videoTextContent: PropTypes.string.isRequired,
    };

    state = {
        open: false
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { fullScreen, classes } = this.props;

        return (
            <div style={style.Height100}>
                <Dialog
                    fullScreen={fullScreen}
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{ paper: classes.Paper }}
                >

                    <DialogTitle id="alert-dialog-title">
                        {this.props.videoTitle}
                    </DialogTitle>
                    <DialogContent>
                        <Card style={style.Card}>
                            <CardMedia
                                component="iframe"
                                width="260"
                                height="400"
                                title={this.props.videoLinkTitle}
                                src={this.props.videoLink}
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen={true}
                            />
                        </Card>
                        <DialogContentText
                            id="alert-dialog-description"
                            style={style.TextContent}
                        >
                            {this.props.videoTextContent} 
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleClose}
                            color="primary"
                            autoFocus
                        >
                            Ok Winston !
                        </Button>
                    </DialogActions>
                </Dialog>
                <div
                    variant="outlined"
                    color="primary"
                    onClick={this.handleClickOpen}
                    style={style.Height100}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}

VideoPopUp.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired
};

export default withMobileDialog({ breakpoint: 'xs' })(
    withStyles(styles)(VideoPopUp)
);
