import React from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import uuid4 from 'uuid4';

import { site } from './../config.js';

import {
    Paper,
    Typography,
    Button,
    FormControl,
    Grid, Divider,
    SnackbarContent,
} from '@material-ui/core/';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Dropzone from 'react-dropzone';
import Recording from './Recording';
import { rebase } from '../rebase';
import { languages } from '../helpers';

const style = {
    Paper: {
        padding: '1em',
        marginBottom: '1em'
    },

    ButtonPrimary: {
        margin: '1em'
    },

    Select: {
        width: '100%',
        maxWidth: '100%'
    },

    Divider: {
        margin: '1.5em 0'
    },
    addButton: {
        margin: '1em 0',
        minHeight: '40px'
    },

    SnackbarInfo: {
        width: '100%',
        margin: '1em 0',
        maxWidth: '100%'
    },
    Dropzone: {
        position: 'relative',
        width: '100%',
        height: '100%',
        borderWidth: '2px',
        borderColor: 'rgb(102, 102, 102)',
        borderStyle: 'dashed',
        borderRadius: '5px',
        textAlign: 'center',
        cursor: 'pointer'
    },
    DropzoneActief: {
        position: 'relative',
        width: '100%',
        height: '100%',
        borderWidth: '2px',
        borderColor: '#2EA4FF',
        borderStyle: 'dashed',
        borderRadius: '5px',
        textAlign: 'center',
        cursor: 'pointer'
    },
    DropzoneText: {
        lineHeight: '700%'
    }

};

class AddSpeechPage extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object,
        lastLanguage: PropTypes.string.isRequired,
        mySpeeches: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        setUserState: PropTypes.func.isRequired,
        lastSpeech: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.object.isRequired
        ])
    };

    state = {
        reportStatuses: {},
        recording: false,
        uploadStatus: 'READY',  // READY, COMPRESSING, UPLOADING, FAILED
        progress: 0,
        language: this.props.lastLanguage,
        addedFile: '',
        lastSpeechStatus: null,
        drag: false
    };

    rebaseReportRefs = {};
    formRef = React.createRef();
    languageRef = React.createRef();
    recordingRef = React.createRef();
    dragRef = React.createRef();
    uploadTask = null;

    maxFileSize = 50 * 2 ** 20;

    componentDidMount() {
        const { lastSpeech } = this.props;
        if ((typeof lastSpeech === 'string' || lastSpeech instanceof String) && 
            lastSpeech.length > 0)
                this.bindReport();

        if (this.props.location.droppedFile)
            this.handleDrop(this.props.location.droppedFile);
    }

    componentWillUnmount() {
        if (this.rebaseReportRef) 
            rebase.removeBinding(this.rebaseReportRef);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lastSpeech !== this.props.lastSpeech)
            this.bindReport()
    }

    bindReport = () => {
        this.rebaseReportRef = rebase.bindToState(
            `reports/${this.props.lastSpeech}/status`, {
            context: this,
            state: 'lastSpeechStatus',
            onFailure: err => {
                console.log(`Failed to get the status of the last uploaded 
                speech. Error:`, err);
            }
        });
    }

    onSubmit = async (event) => {
        event.preventDefault();

        let file, title;

        if (this.state.recording) {
            this.setState({ uploadStatus: 'COMPRESSING' });
            file = await this.recordingRef.current.getAudioBlob();
            title = 'Recording';
        } else {
            file = this.dragRef[0];
            title = file.name.replace(/\.[^/.]+$/, '');
        }

        this.upload(file, title);
    };

    upload = (file, title) => {
        const speechId = uuid4();
        const uploadsRef = firebase.storage().ref().child('uploads');
        const fileRef = uploadsRef.child(speechId);
        const metaData = {
            contentType: file.type,
            contentLanguage: this.state.language.split('-')[0],
            customMetadata: { language: this.state.language },
        };

        this.uploadTask = fileRef.put(file, metaData);
        this.setState({ uploadStatus: 'UPLOADING' });
        console.log(`Uploading (${file.size} bytes) as ${fileRef.name} ` + JSON.stringify(metaData));

        this.uploadTask.on(
            'state_changed',
            upload => {
                this.setState({
                    progress: (upload.bytesTransferred /
                        upload.totalBytes) * 100,
                });
                switch (upload.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                    default:
                        console.log('Unknown snapshot status');
                }
            },
            error => {
                console.log('Upload failed: ' + error.code);
                this.setState({ uploadStatus: 'READY' });
                switch (error.code) {
                    case 'storage/canceled':
                        break;
                    default:
                        console.log(error);
                        this.setState({ uploadStatus: 'FAILED' });
                }
            },
            () => {
                console.log('Upload finished');
                // User rebase directly to avoid state mess
                rebase.post(
                    `speeches/${speechId}`,
                    {
                        data: {
                            id: speechId,
                            title: title,
                            timestamp: Date.now(),
                            owner: this.props.user.uid,
                            ownerEmail: this.props.user.email,
                        },
                    })
                    .then(() => rebase.post(
                        `users/${this.props.user.uid}/mySpeeches/${speechId}`,
                        { data: true })
                    )
                    .then(() => this.props.setUserState({
                            lastSpeech: speechId,
                            lastLanguage: this.state.language,
                        })
                    )
                    .then(() =>this.waitForSpeech(speechId))
                    .then(() => {
                        console.log(`Navigating to report ${speechId}`);
                        this.props.history.push(`/reports/${speechId}/`);
                    })
                    .catch((err) => {
                        console.log(err.message);
                    });
            },
        );
    };

    waitForSpeech = speechId => new Promise(resolve => {
        const check = () => {
            if (speechId in this.props.mySpeeches) {
                resolve();
            } else {
                setTimeout(check, 100);
            }
        };
        setTimeout(check, 100);
    });

    onCancel = () => {
        if (this.state.uploadStatus === 'UPLOADING') {
            this.uploadTask.cancel();
            console.log('Cancelling upload');
        } else if (this.state.uploadStatus === 'COMPRESSING') {
            // TODO: Implement this!
        } else {
            this.props.history.goBack();
        }
    };

    onLanguageChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    uploadedFile = (event) => {
        this.setState({ addedFile: event[0].name });
    };

    onFileChange = () => {
        this.forceUpdate();
    };

    onRecordingEnable = () => {
        if (this.dragRef.value) this.dragRef.value = '';
        this.setState({ 
            addedFile: '',
            recording: true 
        });
    };

    onRecordingDisable = () => {
        this.setState({ recording: false });
    };

    checkAllRequirements = () => {
        return {
            fileSize: this.checkSize(),
            fileType: this.checkType(),
            singleUpload: this.checkSingleUpload(),
        }
    };

    checkSize = () => {
        if (this.dragRef
            && this.dragRef[0]
            && this.dragRef[0].size > this.maxFileSize) {
            return `File too big (more than ${
                (this.maxFileSize / 2 ** 20).toFixed(0)} MB)`;
        } else {
            return 'OK';
        }
    };

    checkType = () => {
        if(this.dragRef && this.dragRef[0])
        {    
            const type = this.dragRef[0].type.toString();
            if (type.startsWith('video') || type.startsWith('audio'))
                return 'OK';
            else 
                return 'You only can upload video and audio file';
        }
        else{
            return 'OK';
        }
    };

    checkSingleUpload = () => {
        const { lastSpeechStatus } = this.state;
        if (!lastSpeechStatus || lastSpeechStatus !== "PROCESSING" )
            return 'OK'; 
        else 
            return 'You cannot create two speech reports at the same time.';
    };

    canSubmit = requirements => {
        const reqOK = Object.values(requirements).every(
            value => value === 'OK');
        return reqOK && this.state.uploadStatus !== 'UPLOADING'
            && this.state.uploadStatus !== 'COMPRESSING';
    };

    errorMessages = requirements => {
        const messages = [];
        if (this.state.uploadStatus === 'FAILED') {
            messages.push('Upload failed :-(');
        }
        Object.values(requirements).forEach(value => {
            if (value && value !== 'OK') {
                messages.push(value);
            }
        });
        return messages;
    };

    handleDrop = (comingFile, rejectedfile) => {
        this.dragRef = comingFile;
        this.setState({
            addedFile: this.dragRef[0].name,
            drag: false
        });
    };

    handleStyle = (bool) => {
        this.setState({drag: bool});
    };

    render () {
        const requirements = this.checkAllRequirements();
        return (
            <main>
                <div className="container">
                    <Typography component="h1" variant="h4" gutterBottom>
                        Get feedback from {site.nameShort} himself.
                    </Typography>

                    <Paper elevation={1} style={style.Paper} className="customPaper">
                        <Typography component="h1" variant="title" gutterBottom>
                            Getting Started
                        </Typography>
                        <Typography>
                            First time with {site.nameShort}? Have a look at our <a
                            href="https://firebasestorage.googleapis.com/v0/b/winston-analytics-app.appspot.com/o/exercises%2FGetting%20Started.pdf?alt=media&token=0e1d6d34-9e4c-4bef-b2f6-071a6a992328"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Getting Started"
                        >
                            Getting Started Guide
                        </a>.
                        </Typography>
                    </Paper>

                    <form onSubmit={this.onSubmit} ref={this.formRef}>

                        <Paper elevation={1} style={style.Paper} className="customPaper">
                            <Typography component="h1" variant="title" gutterBottom>
                                Add your speech
                            </Typography>
                            <Typography gutterBottom>
                                Choose how you want to upload your speech to Winston.
                            </Typography>
                            <Grid container spacing={24} className="u-mt">
                                <Grid item xs={12} md={6}>
                                    <Typography component="h1" variant="subheading" gutterBottom>
                                        Record now with this device.
                                    </Typography>
                                    <Recording
                                        ref={this.recordingRef}
                                        maxFileSize={this.maxFileSize}
                                        onRecordingEnable={this.onRecordingEnable}
                                        onRecordingDisable={this.onRecordingDisable}
                                    />
                                    <Typography component="small" gutterBottom>
                                        {site.nameShort} hates low quality microphones. You cannot win a war with bad stuff or background noises of sabotage! The microphone of your smartphone, however, might do the job.
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Dropzone 
                                        onDrop={this.handleDrop} 
                                        style={!this.state.drag ? style.Dropzone : style.DropzoneActief}
                                        onDragEnter={() => this.handleStyle(true)}
                                        onDragLeave={() => this.handleStyle(false)}
                                    >
                                        <Typography style={style.DropzoneText}>
                                            Drop speech here or click to select a file
                                        </Typography>
                                        <Typography component="small" gutterBottom>
                                            {this.state.addedFile}
                                        </Typography>
                                    </Dropzone>
                                </Grid>
                            </Grid>
                            <Divider variant="middle" style={style.Divider} />
                            <Typography component="h1" variant="subheading" gutterBottom>
                                Select the language of your speech
                            </Typography>
                            <FormControl variant="filled">
                                <Select
                                    style={style.Select}
                                    ref={this.languageRef}
                                    name="language"
                                    onChange={this.onLanguageChange}
                                    value={this.state.language}
                                >
                                    {Object.keys(languages).map(
                                        code => <MenuItem key={code} value={code}>{languages[code]}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Paper>
                        {
                            this.state.uploadStatus === 'UPLOADING' &&
                            <Typography gutterBottom>
                                Uploading ({this.state.progress.toFixed(0)} %)
                            </Typography>
                        }
                        {
                            this.state.uploadStatus === 'COMPRESSING' &&
                            <Typography gutterBottom>
                                Compressing audio...
                            </Typography>
                        }
                        {
                            this.errorMessages(requirements).map((message, index) =>
                                <SnackbarContent
                                    className='is-error-snackbar'
                                    key={index}
                                    message={message}
                                    style={style.SnackbarInfo}
                                />
                            )
                        }
                        <div className="u-align-right">

                            <Button
                                style={style.ButtonPrimary}
                                variant="contained"
                                type="button"
                                onClick={this.onCancel}
                            >
                                Cancel
                            </Button>

                            <Button
                                variant="contained" color="primary"
                                type="submit"
                                disabled={!this.canSubmit(requirements)}
                                className="btn--primary"
                            >
                                Upload
                            </Button>
                            <Typography component="small" className="smallText">
                                By uploading your speech, you agree 
                                that {site.nameShort} and his team can use 
                                the data you upload to improve the tool.
                            </Typography>
                        </div>
                    </form>
                </div>
            </main>
        );
    }
}

export default AddSpeechPage;