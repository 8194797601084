/* eslint react/display-name: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
    Typography,
    Grid,
    SnackbarContent,
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    CardActions,
    Avatar,
    IconButton,
} from '@material-ui/core/';

import VisibilityIcon from '@material-ui/icons/Visibility';
import { withStyles } from '@material-ui/core/styles';
import VideoPopUp from './VideoPopUp';

import { isParki } from '../helpers';


const style = {
    SnackbarInfo: {
        marginTop: '1em',
        maxWidth: '100%'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },

    Card: {
        height: '100%',
        position: 'relative'
    },

    CardContent: {
        marginBottom: '50px'
    },

    CardActions: {
        position: 'absolute',
        bottom: '0'
    },
    BoldText: {
        fontSize: '1rem'
    },
};

const ExerciseCard = props => {
    const cardContent = (
        <React.Fragment>
            <CardHeader
                avatar={<Avatar>{props.number}</Avatar>}
                title={props.title}
                classes={{title: props.classes.BoldText}}
            />
            <CardMedia
                style={style.media}
                image={props.imageUrl}
                title={props.imageTitle}
            />
            <CardContent style={style.CardContent}>
                {props.children}
            </CardContent>
            <CardActions
                disableActionSpacing
                style={style.CardActions}
            >
                <IconButton aria-label="Give it a shot">
                    <VisibilityIcon/>
                </IconButton>
            </CardActions>
        </React.Fragment>
    );
    if (props.link) {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <Link to={props.link} className="nav-link">
                    <Card style={style.Card}>
                        {cardContent}
                    </Card>
                </Link>
            </Grid>
        );
    } else if (props.video) {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <Card style={style.Card} className="has-pointer">
                    <VideoPopUp
                        videoTitle={props.video.title}
                        videoLink={props.video.link}
                        videoLinkTitle={props.video.linkTitle}
                        videoTextContent={props.video.textContent}
                    >
                        {cardContent}
                    </VideoPopUp>
                </Card>
            </Grid>
        );
    } else {
        return (
            <Grid item xs={12} sm={6} lg={4}>
                <Card
                    style={style.Card}
                    className="has-pointer"
                    onClick={props.onClick}
                >
                    {cardContent}
                </Card>
            </Grid>
        );
    }
};

ExerciseCard.propTypes = {
    number: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    imageTitle: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    link: PropTypes.string,
    video: PropTypes.shape({
        title: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        linkTitle: PropTypes.string.isRequired,
        textContent: PropTypes.node.isRequired,
    }),
    children: PropTypes.node,
};

const ExercisesPage = props => {
    const exerciseCards = {
        standard: [
            number => <ExerciseCard
                number={number}
                title="Getting Started"
                key="Getting Started"
                imageUrl="https://storage.googleapis.com/winston-public/churchill.jpg"
                imageTitle="Winston Churchill's portrait"
                classes={props.classes}
                onClick={() => {
                    window.open(
                        'https://firebasestorage.googleapis.com/v0/b/winston-analytics-app.appspot.com/o/exercises%2FGetting%20Started.pdf?alt=media&token=0e1d6d34-9e4c-4bef-b2f6-071a6a992328',
                        '_blank'
                    )
                }}
            >
                <Typography>
                    This short tutorial explains how to record your first
                    speech and to let Winston have his critical opinion about
                    it.
                </Typography>
                <Typography>
                    It also provides a sample text to test the quality of your
                    microphone.
                </Typography>
            </ExerciseCard>,
            number => <ExerciseCard
                number={number}
                title="I Speak Too Fast"
                key="I Speak Too Fast"
                imageUrl="https://storage.googleapis.com/winston-public/tortoise_hare.jpg"
                imageTitle="The Tortoise and the Hare"
                classes={props.classes}
                link="/speaktoofast/"
            >
                <Typography>
                    You are passionate about your topic, you are enthusiastic
                    about your story. All this energy is converted in an
                    incredible fast pace!
                </Typography>
                <Typography>
                    However, the audience cannot follow anymore, and you start
                    to swallow the end of your words.
                </Typography>
                <Typography>
                    The exercises first create awareness about your speaking
                    pace, then provide a speaking drill and finishes with
                    realistic speech content.
                </Typography>
            </ExerciseCard>,
            number => <ExerciseCard
                number={number}
                title="I Speak Too Fast – Advanced"
                key="I Speak Too Fast – Advanced"
                imageUrl="https://storage.googleapis.com/winston-public/tortoise_sleeping_hare.jpg"
                imageTitle="The Tortoise and the Hare, which is sleeping"
                classes={props.classes}
                link="/speaktoofast-advanced/"
            >
                <Typography>
                    The previous track of exercises taught you how to control
                    the pace of your voice, but there is more!
                </Typography>
                <Typography>
                    If you want to <i>play</i> with your pace and to grow your
                    second nature of speaking, you are now ready to proceed to
                    this next track.
                </Typography>
            </ExerciseCard>,
        ],
        videos: [
            number => <ExerciseCard
                number={number}
                title="Warm je stem op"
                key="Warm je stem op"
                imageUrl="https://storage.googleapis.com/winston-public/WarmJeStemOp1.png"
                imageTitle="Thomas toont hoe je je stem opwarmt"
                classes={props.classes}
                video={{
                    title: 'Word een betere spreker: Warm je stem op',
                    link: 'https://player.vimeo.com/video/317307522?autoplay=1&title=0&byline=0&portrait=0',
                    linkTitle: 'LearnFromMe: Word een betere spreker 01 warm je stem op',
                    textContent: (
                        <>
                            Warm je stemgereedschap op en word een betere
                            spreker met Thomas Vervaet.
                            <br />
                            Bekend van Toastmasters Gent, TEDxGhent en Winston
                            Analytics.
                        </>
                    ),
                }}
            >
                <Typography>
                    Warm voor je volgende presentatie je stem op, het
                    belangrijkste gereedschap van de spreker. Zo krijg je een
                    warm geluid met een groter bereik en zonder stemproblemen.
                </Typography>
            </ExerciseCard>,
            number => <ExerciseCard
                number={number}
                title="Stel vragen"
                key="Stel vragen"
                imageUrl="https://storage.googleapis.com/winston-public/StelVragen2.PNG"
                imageTitle="Thomas legt uit dat je vragen dient te stellen"
                classes={props.classes}
                video={{
                    title: 'Word een betere spreker: Stel vragen',
                    link: 'https://player.vimeo.com/video/318983510?autoplay=1&title=0&byline=0&portrait=0',
                    linkTitle: 'LearnFromMe: Word een betere spreker 02 stel vragen',
                    textContent: (
                        <>
                            Zorg dat je vragen stelt, anders zul je falen.
                            <br />
                            Word een betere spreker met Thomas Vervaet.
                            <br />
                            Bekend van Toastmasters Gent, TEDxGhent en
                            SpeechCoachingBot Winston Analytics.
                        </>
                    ),
                }}
            >
                <Typography>
                    Stel vragen, anders zul je falen. Focus op je publiek en
                    alles komt goed.
                </Typography>
            </ExerciseCard>,
            number => <ExerciseCard
                number={number}
                title="Regisseer jezelf"
                key="Regisseer jezelf"
                imageUrl="https://storage.googleapis.com/winston-public/RegisseerJezelf3.PNG"
                imageTitle="Thomas legt uit dat je jezelf kunt regisseren"
                classes={props.classes}
                video={{
                    title: 'Regisseer jezelf',
                    link: 'https://player.vimeo.com/video/318984239?autoplay=1&title=0&byline=0&portrait=0',
                    linkTitle: 'LearnFromMe: word een betere spreker 03 regisseer jezelf',
                    textContent: (
                        <>
                            Regisseer jezelf en word een betere spreker met
                            Thomas Vervaet.
                            <br />
                            Bekend van Toastmasters Gent, TEDxGhent en Winston
                            Analytics.
                        </>
                    ),
                }}
            >
                <Typography>
                    Regisseer jezelf in de laatste seconden om snel je impact
                    te vergroten: tips voor podium- en stemgebruik.
                </Typography>
            </ExerciseCard>,
        ],
        parkinson: [
            number => <ExerciseCard
                number={number}
                title="Parkinson – Uitroepen"
                key="Parkinson – Uitroepen"
                imageUrl="https://storage.googleapis.com/winston-public/mouth.jpeg"
                imageTitle="Mouth of old woman"
                classes={props.classes}
                link="/stemoefeningen/?words=uitroepen"
            >
                <Typography>
                    We geven hier enkele oefeningen die kaderen in de Pitch
                    Limiting Voice Treatment (PLVT). Dit is dé behandeling voor
                    spraakproblemen bij Parkinsonpatiënten.
                </Typography>
                <Typography>
                    Deze reeks is de eerste in de rij, waarbij we focussen op
                    uitroepen, zoals hé! ja! zo!
                </Typography>
            </ExerciseCard>,
            number => <ExerciseCard
                number={number}
                title="Parkinson – één lettergreep"
                key="Parkinson – één lettergreep"
                imageUrl="https://storage.googleapis.com/winston-public/mouth.jpeg"
                imageTitle="Mouth of old woman"
                classes={props.classes}
                link="/stemoefeningen/?words=eenLettergreep"
            >
                <Typography>
                    Dit reeks oefeningen focust op woorden met één lettergreep
                    en kadert ook binnen de PLVT-behandeling voor Parkinson.
                </Typography>
            </ExerciseCard>,
            number => <ExerciseCard
                number={number}
                title="Parkinson – twee lettergrepen"
                key="Parkinson – twee lettergrepen"
                imageUrl="https://storage.googleapis.com/winston-public/mouth.jpeg"
                imageTitle="Mouth of old woman"
                classes={props.classes}
                link="/stemoefeningen/?words=tweeLettergrepen"
            >
                <Typography>
                    Als je de PLVT-oefeningen met woorden met één lettergreep
                    onder de knie hebt, kun je hier overgaan naar woorden met
                    twee lettergrepen.
                </Typography>
            </ExerciseCard>,
            number => <ExerciseCard
                number={number}
                title="Parkinson – drie lettergrepen"
                key="Parkinson – drie lettergrepen"
                imageUrl="https://storage.googleapis.com/winston-public/mouth.jpeg"
                imageTitle="Mouth of old woman"
                classes={props.classes}
                link="/stemoefeningen/?words=drieLettergrepen"
            >
                <Typography>
                    Hier kun je PLVT-oefeningen doen met woorden van drie
                    lettergrepen.
                </Typography>
            </ExerciseCard>,
            number => <ExerciseCard
                number={number}
                title="Parkinson – vier lettergrepen"
                key="Parkinson – vier lettergrepen"
                imageUrl="https://storage.googleapis.com/winston-public/mouth.jpeg"
                imageTitle="Mouth of old woman"
                classes={props.classes}
                link="/stemoefeningen/?words=vierLettergrepen"
            >
                <Typography>
                    Hier kun je PLVT-oefeningen doen met woorden van vier
                    lettergrepen.
                </Typography>
            </ExerciseCard>,
            number => <ExerciseCard
                number={number}
                title="Parkinson – generalisatie"
                key="Parkinson – generalisatie"
                imageUrl="https://storage.googleapis.com/winston-public/mouth.jpeg"
                imageTitle="Mouth of old woman"
                classes={props.classes}
                link="/stemoefeningen/?words=generalisatie"
            >
                <Typography>
                    Hier kun je PLVT-oefeningen door middel van een
                    gespreksonderwerp.
                </Typography>
            </ExerciseCard>,
        ],
    };
    const orderedExerciseCards = isParki() ? [
        ...exerciseCards.parkinson,
        ...exerciseCards.standard,
        ...exerciseCards.videos,
    ] : [
        ...exerciseCards.standard,
        ...exerciseCards.videos,
        ...exerciseCards.parkinson,
    ];
    return (
        <main>
            <Grid container spacing={24}>
                {
                    orderedExerciseCards.map(
                        (ThisCard, index) => ThisCard(index + 1)
                    )
                }
            </Grid>
            <SnackbarContent
                message="More exercises coming soon!"
                style={style.SnackbarInfo}
            />
        </main>
    )
};

ExercisesPage.propTypes = {
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(style)(ExercisesPage);
