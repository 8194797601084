import React from 'react';
import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';

import { rebase } from '../rebase';

const style = {
    Paper: {
        padding: '1em',
        marginBottom: '1em',
    },
    QuestionLabel: {
        marginTop: '2em',
    },
};

class Questionnaire extends React.Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        setUserState: PropTypes.func.isRequired,
    };

    state = {
        responses: {
            goedGeslapen: { answer: undefined },
            medicatieGenomen: { answer: undefined },
            hoeVoelen: { answer: undefined },
            opVoedingGelet: { answer: undefined },
        },
        submitting: false,
    };

    onSubmit = event => {
        event.preventDefault();
        this.setState({ submitting: true });
        const timestamp = Date.now();
        const data = {
            responder: this.props.user.uid,
            responderEmail: this.props.user.email,
            timestamp: timestamp,
            responses: this.state.responses,
        };
        rebase.push('questionnaireResponses', { data })
            .then(() => this.props.setUserState({
                lastQuestionnaireResponse: timestamp,
            }))
            .catch(err => {
                console.log('Failed to push comment to Firebase:', err);
            });
    };

    Question = ({ question, questionKey, answers }) => <>
        <label htmlFor={questionKey}>
            <Typography variant="h6" style={style.QuestionLabel}>
                {question}
            </Typography>
        </label>
        <FormControl required={true} margin="dense">
            <RadioGroup
                name={questionKey}
                value={this.state.responses[questionKey].answer}
                onChange={event => this.setState({
                    responses: {
                        ...this.state.responses,
                        [questionKey]: {
                            question: question,
                            possibleAnswers: answers,
                            answer: event.target.value,
                        },
                    }
                })}
            >
                {answers.map(answer => (
                    <FormControlLabel
                        label={answer}
                        value={answer}
                        key={answer}
                        control={
                            <Radio
                                style={style.Radio}
                                color="primary"
                                required={true}
                            />
                        }
                    />
                ))}
            </RadioGroup>
        </FormControl>
    </>;


    render () {
        return (
            <main>
                <Paper
                    elevation={1}
                    style={style.Paper}
                    className="customPaper container"
                >
                    <form onSubmit={this.onSubmit}>
                        <Typography component="h1" variant="h4" gutterBottom>
                            Dagelijkse vragenlijst
                        </Typography>
                        <this.Question
                            question="Heb je goed geslapen vannacht?"
                            questionKey="goedGeslapen"
                            answers={['Ja', 'Nee']}
                        />
                        <this.Question
                            question="Heb je je medicatie tijdig genomen?"
                            questionKey="medicatieGenomen"
                            answers={['Ja', 'Nee']}
                        />
                        <this.Question
                            question="Hoe voel je je nu?"
                            questionKey="hoeVoelen"
                            answers={['Goed', 'Gemiddeld', 'Slecht']}
                        />
                        <this.Question
                            question="Heb je vandaag op je voeding gelet?"
                            questionKey="opVoedingGelet"
                            answers={['Ja', 'Nee']}
                        />
                        <div>
                            <Button
                                variant="contained"
                                className="btn--primary"
                                color="primary"
                                type="submit"
                                disabled={this.state.submitting}
                            >
                                Antwoorden verzenden
                            </Button>
                        </div>
                    </form>
                </Paper>
            </main>
        )
    }
}

export default Questionnaire
