import React from 'react';
import {  MenuItem, Select } from '@material-ui/core/';
import PropTypes from 'prop-types';

const style = {
    LanguageSelection: {
        display: 'inline',
        textAlign: 'center'
    }
};

class LanguageChange extends React.Component {
    static propTypes = {
        language: PropTypes.string.isRequired,
        availableLanguages: PropTypes.object.isRequired,
        updateStateViaProps: PropTypes.func.isRequired,
    };

    handleChange = event => {
        this.props.updateStateViaProps( "language",event.target.value );
    };
  
    render() {
        return (
            <React.Fragment>
                <div style={style.LanguageSelection}>
                    <Select
                        ref={this.languageRef}
                        onChange={this.handleChange}
                        value={
                            this.props.availableLanguages[this.props.language]
                                ? this.props.language
                                : 'en-US'
                        }
                    >
                        {Object.keys(this.props.availableLanguages).map(code => (
                            <MenuItem key={code} value={code}>
                                {this.props.availableLanguages[code]}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </React.Fragment>
        );
    }
}
export default LanguageChange;
