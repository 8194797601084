import React from 'react';
import { Button, Typography, Grid, LinearProgress } from '@material-ui/core/';
import PropTypes from 'prop-types';

const style = {
    ProgressionBar: {
        margin: '1em 0 1em'
    },
    NavigateButton: {
        margin: 'auto',
        marginRight: '0'
    }
};

class Navigation extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        children: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        subTitle: PropTypes.string,
        maximumPages: PropTypes.number.isRequired,
        pageNumber: PropTypes.number.isRequired,
        unlockedNum: PropTypes.object.isRequired,
    };

    checkUnlock = () => {
        const unlockedNum = this.props.unlockedNum;
        if (!unlockedNum || !unlockedNum.unlocked) return true;
        else if (unlockedNum.unlocked <= this.props.pageNumber) return true;
        else return false;
    };

    changePage = pageNumber => {
        const { pathname, search } = this.props.location;
        const params = new URLSearchParams(search);
        params.set('page', pageNumber.toString());
        this.props.history.push(`${pathname}?${params.toString()}`);
    };

    render() {
        const maximumPages = this.props.maximumPages;
        const pageNumber = this.props.pageNumber;
        const normalise = value => ((value - 0) * 100) / (maximumPages - 1);
        return (
            <React.Fragment>
                <main>
                    <div className="container">
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                        >
                            <Grid>
                                <Grid>
                                    <Typography component="h1" variant="h4">
                                        {this.props.title}
                                    </Typography>
                                    {this.props.subTitle && (
                                        <Typography component="h1">
                                            {this.props.subTitle}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid style={style.NavigateButton}>
                                <Button
                                    variant="contained"
                                    type="button"
                                    color="primary"
                                    className="icon-button btn--primary"
                                    disabled={pageNumber === 1}
                                    onClick={
                                        () => this.changePage(pageNumber - 1)
                                    }
                                >
                                    Previous
                                </Button>

                                <Button
                                    variant="contained"
                                    type="button"
                                    color="primary"
                                    className="icon-button btn--primary"
                                    disabled={
                                        pageNumber === maximumPages ||
                                        this.checkUnlock()
                                    }
                                    onClick={
                                        () => this.changePage(pageNumber + 1)
                                    }
                                >
                                    Next
                                </Button>
                            </Grid>
                        </Grid>
                        <LinearProgress
                            style={style.ProgressionBar}
                            variant="determinate"
                            value={normalise(pageNumber - 1)}
                        />
                        {this.props.children}
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                        >
                            <Grid />
                            <Grid style={style.NavigateButton}>
                                <Button
                                    variant="contained"
                                    type="button"
                                    color="primary"
                                    className="icon-button btn--primary"
                                    disabled={pageNumber === 1}
                                    onClick={
                                        () => this.changePage(pageNumber - 1)
                                    }
                                >
                                    Previous
                                </Button>

                                <Button
                                    variant="contained"
                                    type="button"
                                    color="primary"
                                    className="icon-button btn--primary"
                                    disabled={
                                        pageNumber === maximumPages ||
                                        this.checkUnlock()
                                    }
                                    onClick={
                                        () => this.changePage(pageNumber + 1)
                                    }
                                >
                                    Next
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}
export default Navigation;
