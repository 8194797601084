import React from 'react';

export const availableLanguages = {
    'nl-NL': 'Dutch (Netherlands)',
    'en-US': 'English (United States)'
};

export const exercises = {
    exercise2: {
        NL: {
            text: [<>Één twee drie test</>],
            syllables: 4,
            syllablesFactor: [0.5, 1.75],
        },
        EN: {
            text: [<>One two three test</>],
            syllables: 4,
            syllablesFactor: [0.5, 1.75],
        }
    },
    exercise3: {
        NL: {
            text: [
                <>
                    Er waren eens een man en een vrouw, die al heel lang
                    tevergeefs hadden gehoopt, dat zij een kind zouden krijgen;
                    maar eindelijk leek het erop dat de lieve God hun wens toch
                    zou vervullen. Nu hadden deze mensen aan de achterkant van
                    hun huis een klein raam, waardoor je in een prachtige tuin
                    kon kijken, die vol stond met prachtige bloemen en planten.
                    Die tuin was echter door een hoge muur omgeven en niemand
                    waagde het er binnen te gaan, want er woonde een toverkol
                    die heel machtig was en door iedereen werd gevreesd.
                </>,
                <>
                    Op een dag stond de vrouw voor dat raam en keek in de tuin
                    naar beneden; daar zag ze een heel bed beplant met de
                    mooiste raponsjes, en die waren zo fris en zo groen, dat ze
                    ervan watertandde en een hevig verlangen kreeg van die
                    raponsjes te eten. Elke dag werd dat verlangen groter en
                    omdat ze wist dat ze er toch niets van krijgen kon, werd ze
                    steeds magerder en zag er bleek en ellendig uit. Toen werd
                    haar man bang en vroeg: “Wat scheelt er aan, lieve vrouw?”
                    - “Ach,” zei ze, “als ik geen raponsjes uit de tuin achter
                    ons huis kan krijgen, dan zal ik sterven.” De man hield
                    veel van haar en dacht: “Voor je je eigen vrouw laat
                    omkomen, moet je haar maar van die raponsjes halen, wat het
                    je ook kost.” Toen de schemering gevallen was, klom hij dus
                    de muur over en liet zich zakken in de tuin van de
                    toverkol, griste in allerijl een handvol raponsjes weg en
                    bracht die naar zijn vrouw. Ze maakte er dadelijk sla van
                    en at het met grote gulzigheid. Maar het smaakte haar zo
                    bijzonder goed, dat ze er de volgende dag nog driemaal
                    zoveel trek in had.
                </>
            ],
            syllables: 403,
            syllablesFactor: [0.8, 1.2],
        },
        EN: {
            text: [
                <>
                    There were once a man and a woman who had long in vain
                    wished for a child. At length the woman hoped that God was
                    about to grant her desire. These people had a little window
                    at the back of their house from which a splendid garden
                    could be seen, which was full of the most beautiful flowers
                    and herbs. It was, however, surrounded by a high wall, and
                    no one dared to go into it because it belonged to an
                    enchantress, who had great power and was dreaded by all the
                    world.
                </>,
                <>
                    One day the woman was standing by this window and looking
                    down into the garden, when she saw a bed which was planted
                    with the most beautiful rampion, and it looked so fresh and
                    green that she longed for it, she quite pined away, and
                    began to look pale and miserable. Then her husband was
                    alarmed, and asked: “What ails you, dear wife?” “Ah,” she
                    replied, “if I can’t eat some of the rampion, which is in
                    the garden behind our house, I shall die.” The man, who
                    loved her, thought: “Sooner than let your wife die, bring
                    her some of the rampion yourself, let it cost what it
                    will.” At twilight, he clambered down over the wall into
                    the garden of the enchantress, hastily clutched a handful
                    of rampion, and took it to his wife. She at once made
                    herself a salad of it, and ate it greedily. It tasted so
                    good to her – so very good, that the next day she longed
                    for it three times as much as before. If he was to have any
                    rest, her husband must once more descend into the garden.
                </>
            ],
            syllables: 330,
            syllablesFactor: [0.8, 1.2],
        }
    },
    exercise4: {
        NL: {
            text: [
                <>
                    Toen de schemering gevallen was klom hij dus de muur over
                    en liet zich zakken in de tuin van de toverkol, griste in
                    allerijl een handvol raponsjes weg en bracht die naar zijn
                    vrouw. Ze maakte er dadelijk sla van en at het met grote
                    gulzigheid. Maar het smaakte haar zo bijzonder goed, dat ze
                    er de volgende dag nog driemaal zoveel trek in had. Wilde
                    ze rust hebben, dan moest de man nog een keer in de tuin
                    klimmen.
                </>
            ],
            syllables: 110,
            syllablesFactor: [0.8, 1.2],
        },
        EN: {
            text: [
                <>
                    At twilight, he clambered down over the wall into the
                    garden of the enchantress, hastily clutched a handful of
                    rampion, and took it to his wife. She at once made herself
                    a salad of it, and ate it greedily. It tasted so good to
                    her, so very good, that the next day she longed for it
                    three times as much as before. If he was to have any rest,
                    her husband must once more descend into the garden.
                </>
            ],
            syllables: 97,
            syllablesFactor: [0.8, 1.2],
        }
    },
    exercise5: {
        NL: {
            text: [
                <>
                    Dus begon hij in de
                    <strong className="is-custom-strong">
                        {' '}
                        avondschemering{' '}
                    </strong>
                    weer zijn tocht, maar toen hij zich van de muur liet
                    zakken,
                    <strong className="is-custom-strong"> schrok </strong>hij
                    ontzettend want de toverkol stond
                    <strong className="is-custom-strong"> voor </strong>hem.
                    “Hoe
                    <strong className="is-custom-strong"> durf </strong>je,”
                    sprak ze met toornige blik, “om in mijn
                    <strong className="is-custom-strong"> tuin </strong>te
                    klimmen en als een dief mijn
                    <strong className="is-custom-strong"> raponsjes </strong>
                    te stelen? Dat zal je
                    <strong className="is-custom-strong"> duur </strong>komen
                    te staan.” - “Ach,” antwoordde hij, “laat
                    <strong className="is-custom-strong"> genade </strong>
                    gelden voor
                    <strong className="is-custom-strong"> recht</strong>. Ik
                    heb alleen uit
                    <strong className="is-custom-strong"> nood </strong>
                    gehandeld; mijn vrouw heeft uw
                    <strong className="is-custom-strong">
                        {' '}
                        raponsjesbed{' '}
                    </strong>
                    vanuit haar raam gezien en had er zo'n groot
                    <strong className="is-custom-strong"> verlangen </strong>
                    naar dat ze was gestorven als ze er
                    <strong className="is-custom-strong"> niet </strong>van
                    gegeten had.”
                </>
            ],
            syllables: 133,
            syllablesFactor: [0.8, 1.2],
        },
        EN: {
            text: [
                <>
                    In the gloom of
                    <strong className="is-custom-strong"> evening </strong>
                    therefore, he let himself down again; but when he had
                    clambered down the wall he was terribly
                    <strong className="is-custom-strong"> afraid</strong>, for
                    he saw the enchantress standing
                    <strong className="is-custom-strong"> before </strong>him.
                    “How can you{' '}
                    <strong className="is-custom-strong"> dare</strong>,” said
                    she with angry look, “descend into my
                    <strong className="is-custom-strong"> garden </strong>and
                    steal my
                    <strong className="is-custom-strong"> rampion </strong>like
                    a thief? You shall
                    <strong className="is-custom-strong"> suffer </strong>for
                    it!” “Ah,” answered he, “let
                    <strong className="is-custom-strong"> mercy </strong>take
                    the place of
                    <strong className="is-custom-strong"> justice</strong>, I
                    only made up my mind to do it out of
                    <strong className="is-custom-strong"> necessity</strong>.
                    My wife saw your
                    <strong className="is-custom-strong"> rampion </strong>from
                    the window, and felt such a
                    <strong className="is-custom-strong"> longing </strong>for
                    it that she would have
                    <strong className="is-custom-strong"> died </strong>if she
                    had
                    <strong className="is-custom-strong"> not </strong>got some
                    to eat.”
                </>
            ],
            syllables: 117,
            syllablesFactor: [0.8, 1.2],
        }
    },
    exercise6: {
        NL: {
            text: [
                <>
                    <span role="img" aria-label="waitHand" />
                    Toen werd de toverkol kalmer ✋ en zei: ✋ “Als het zo is
                    als je vertelt, ✋ dan zal ik je toestaan ✋ zoveel
                    raponsjes mee te nemen als je wilt, ✋ maar op één
                    voorwaarde: ✋ mij moet je het kind geven, ✋ dat je vrouw
                    ter wereld zal brengen. ✋ Het zal goed verzorgd worden ✋
                    en ik zal er een moeder voor zijn.” ✋ In zijn angst
                    beloofde de man van alles ✋ en nauwelijks was het kindje
                    geboren ✋ of de toverkol kwam binnen ✋ en gaf aan het
                    kind de naam Raponsje ✋ en nam het mee.
                </>
            ],
            syllables: 118,
            syllablesFactor: [0.8, 1.2],
        },
        EN: {
            text: [
                <>
                    <span role="img" aria-label="waitHand" />
                    Then the enchantress ✋ allowed her anger to be softened,
                    ✋ and said to him: ✋ “If the case be as you say, ✋ I
                    will allow you ✋ to take away with you ✋ as much rampion
                    as you will, ✋ only I make one condition, ✋ you must give
                    me the child ✋ which your wife will bring into the world;
                    ✋ it shall be well treated, ✋ and I will care for it like
                    a mother.” ✋ The man in his terror ✋ consented to
                    everything, ✋ and when the woman was brought to bed, ✋
                    the enchantress appeared at once, ✋ gave the child the
                    name of Rapunzel, ✋ and took it away with her.
                </>
            ],
            syllables: 110,
            syllablesFactor: [0.8, 1.2],
        }
    },
    exercise7: {
        NL: {
            text: [
                <>
                    Er waren eens een man en een vrouw,
                    <strong className="is-custom-strong">&nbsp;1 </strong>die
                    al heel lang tevergeefs hadden gehoopt,
                    <strong className="is-custom-strong">&nbsp;1 </strong>dat
                    zij een kind zouden krijgen;
                    <strong className="is-custom-strong">&nbsp;1-2 </strong>
                    maar eindelijk leek het erop dat de lieve God hun wens toch
                    zou vervullen.
                    <strong className="is-custom-strong">&nbsp;1-2-3 </strong>
                    Nu hadden deze mensen aan de achterkant van hun huis een
                    klein raam,
                    <strong className="is-custom-strong">&nbsp;1 </strong>
                    waardoor je in een prachtige tuin kon kijken,
                    <strong className="is-custom-strong">&nbsp;1 </strong>die
                    vol stond met prachtige bloemen en planten;
                    <strong className="is-custom-strong">&nbsp;1-2-3 </strong>
                    Die tuin was echter door een hoge muur omgeven,
                    <strong className="is-custom-strong">&nbsp;1 </strong>en
                    niemand waagde het er binnen te gaan,
                    <strong className="is-custom-strong">&nbsp;1 </strong>
                    want er woonde een toverkol die heel machtig was,
                    <strong className="is-custom-strong">&nbsp;1 </strong>
                    en door iedereen werd gevreesd.
                    <strong className="is-custom-strong">
                        &nbsp;1-2-3-4-5{' '}
                    </strong>
                </>,
                <>
                    Op een dag stond de vrouw voor dat raam en keek in de tuin
                    naar beneden;
                    <strong className="is-custom-strong">&nbsp;1-2 </strong>
                    daar zag ze een heel bed beplant met de mooiste raponsjes,
                    <strong className="is-custom-strong">&nbsp;1 </strong>en
                    die waren zo fris en zo groen,
                    <strong className="is-custom-strong">&nbsp;1 </strong>dat
                    ze ervan watertandde,
                    <strong className="is-custom-strong">&nbsp;1 </strong>en
                    een hevig verlangen kreeg van die raponsjes te eten.
                    <strong className="is-custom-strong">&nbsp;1-2-3 </strong>
                    Elke dag werd dat verlangen groter,
                    <strong className="is-custom-strong">&nbsp;1 </strong>en
                    omdat ze wist dat ze er toch niets van krijgen kon,
                    <strong className="is-custom-strong">&nbsp;1 </strong>werd
                    ze steeds magerder,
                    <strong className="is-custom-strong">&nbsp;1 </strong>en
                    zag er bleek en ellendig uit.
                    <strong className="is-custom-strong">&nbsp;1-2-3 </strong>
                    Toen werd haar man bang,
                    <strong className="is-custom-strong">&nbsp;1 </strong>en
                    vroeg,
                    <strong className="is-custom-strong">&nbsp;1 </strong>“Wat
                    scheelt er aan,
                    <strong className="is-custom-strong">&nbsp;1 </strong>lieve
                    vrouw?”.
                    <strong className="is-custom-strong">&nbsp;1-2-3 </strong>
                    “Ach,”
                    <strong className="is-custom-strong">&nbsp;1 </strong>zei
                    ze
                    <strong className="is-custom-strong">&nbsp;1 </strong>“als
                    ik geen raponsjes uit de tuin achter ons huis kan krijgen,
                    <strong className="is-custom-strong">&nbsp;1 </strong>dan
                    zal ik sterven.
                    <strong className="is-custom-strong">&nbsp;1-2-3 </strong>”
                    De man hield veel van haar,
                    <strong className="is-custom-strong">&nbsp;1 </strong>
                    en dacht,
                    <strong className="is-custom-strong">&nbsp;1 </strong>
                    “Voor je je eigen vrouw laat omkomen,
                    <strong className="is-custom-strong">&nbsp;1 </strong>moet
                    je haar maar van die raponsjes halen,
                    <strong className="is-custom-strong">&nbsp;1 </strong>wat
                    het je ook kost”.
                    <strong className="is-custom-strong">
                        &nbsp;1-2-3-4-5{' '}
                    </strong>
                </>,
                <>
                    Toen de schemering gevallen was,
                    <strong className="is-custom-strong">&nbsp;1 </strong>klom
                    hij dus de muur over,
                    <strong className="is-custom-strong">&nbsp;1 </strong>en
                    liet zich zakken in de tuin van de toverkol,
                    <strong className="is-custom-strong">&nbsp;1 </strong>
                    griste in allerijl een handvol raponsjes weg,
                    <strong className="is-custom-strong">&nbsp;1 </strong>en
                    bracht die naar zijn vrouw.
                    <strong className="is-custom-strong">&nbsp;1-2-3 </strong>
                    Ze maakte er dadelijk sla van,
                    <strong className="is-custom-strong">&nbsp;1 </strong>en at
                    het met grote gulzigheid.
                    <strong className="is-custom-strong">&nbsp;1-2-3 </strong>
                    Maar het smaakte haar zo bijzonder goed
                    <strong className="is-custom-strong">&nbsp;1 </strong>dat
                    ze er de volgende dag nog driemaal zoveel trek in had.
                    <strong className="is-custom-strong">
                        &nbsp;1-2-3-4-5{' '}
                    </strong>
                </>
            ],
            syllables: 403,
            syllablesFactor: [0.8, 1.2],
        },
        EN: {
            text: [
                <>
                    There were once a man and a woman who had long in vain
                    wished for a child.
                    <strong className="is-custom-strong">&nbsp;1-2-3 </strong>
                    At length the woman hoped that God was about to grant her
                    desire.
                    <strong className="is-custom-strong">&nbsp;1-2-3 </strong>
                    These people had a little window at the back of their house
                    from which a splendid garden could be seen,
                    <strong className="is-custom-strong">&nbsp;1 </strong>which
                    was full of the most beautiful flowers and herbs.
                    <strong className="is-custom-strong">&nbsp;1-2-3 </strong>
                    It was, however, surrounded by a high wall, and no one
                    dared to go into it because it belonged to an enchantress,
                    <strong className="is-custom-strong">&nbsp;1 </strong>who
                    had great power and was dreaded by all the world.
                    <strong className="is-custom-strong">
                        &nbsp;1-2-3-4-5{' '}
                    </strong>
                </>,

                <>
                    One day the woman was standing by this window and looking
                    down into the garden;
                    <strong className="is-custom-strong">&nbsp;1-2 </strong>
                    when she saw a bed which was planted with the most
                    beautiful rampion,
                    <strong className="is-custom-strong">&nbsp;1 </strong>and
                    it looked so fresh and green that she longed for it,
                    <strong className="is-custom-strong">&nbsp;1 </strong>she
                    quite pined away;
                    <strong className="is-custom-strong">&nbsp;1-2 </strong>and
                    began to look pale and miserable.
                    <strong className="is-custom-strong">&nbsp;1-2-3 </strong>
                    Then her husband was alarmed,
                    <strong className="is-custom-strong">&nbsp;1 </strong>
                    and asked.
                    <strong className="is-custom-strong">&nbsp;1-2-3 </strong>
                    “What ails you,
                    <strong className="is-custom-strong">&nbsp;1 </strong>
                    dear wife?”.
                    <strong className="is-custom-strong">&nbsp;1-2-3 </strong>
                    “Ah,”
                    <strong className="is-custom-strong">&nbsp;1 </strong>she
                    replied,
                    <strong className="is-custom-strong">&nbsp;1 </strong>“if I
                    can’t eat some of the rampion,
                    <strong className="is-custom-strong">&nbsp;1 </strong>which
                    is in the garden behind our house,
                    <strong className="is-custom-strong">&nbsp;1 </strong>I
                    shall die.
                    <strong className="is-custom-strong">&nbsp;1-2-3 </strong>”
                    The man,
                    <strong className="is-custom-strong">&nbsp;1 </strong>who
                    loved her,
                    <strong className="is-custom-strong">&nbsp;1 </strong>
                    thought,
                    <strong className="is-custom-strong">&nbsp;1 </strong>
                    “Sooner than let your wife die,
                    <strong className="is-custom-strong">&nbsp;1 </strong>bring
                    her some of the rampion yourself,
                    <strong className="is-custom-strong">&nbsp;1 </strong>let
                    it cost what it will”.
                    <strong className="is-custom-strong">
                        &nbsp;1-2-3-4-5{' '}
                    </strong>
                </>,
                <>
                    At twilight,
                    <strong className="is-custom-strong">&nbsp;1 </strong>he
                    clambered down over the wall into the garden of the
                    enchantress,
                    <strong className="is-custom-strong">&nbsp;1 </strong>
                    hastily clutched a handful of rampion,
                    <strong className="is-custom-strong">&nbsp;1 </strong>and
                    took it to his wife.
                    <strong className="is-custom-strong">&nbsp;1-2-3 </strong>
                    She at once made herself a salad of it,
                    <strong className="is-custom-strong">&nbsp;1 </strong>and
                    ate it greedily.
                    <strong className="is-custom-strong">&nbsp;1-2-3 </strong>
                    It tasted so good to her,
                    <strong className="is-custom-strong">&nbsp;1 </strong>so
                    very good,
                    <strong className="is-custom-strong">&nbsp;1 </strong>that
                    the next day she longed for it three times as much as
                    before.
                    <strong className="is-custom-strong">&nbsp;1-2-3 </strong>
                    If he was to have any rest,
                    <strong className="is-custom-strong">&nbsp;1 </strong>her
                    husband must once more descend into the garden.
                    <strong className="is-custom-strong">
                        &nbsp;1-2-3-4-5{' '}
                    </strong>
                </>
            ],
            syllables: 340,
            syllablesFactor: [0.8, 1.2],
        }
    }
};

export const infos = {
    info2: [<>{/* For one two three test information */}</>],
    info3: [<>{/* For baseline */}</>],
    info4: [
        <>
            Reading one word at a time is not a form of slam poetry, but a tool
            to control your reading speed. Your eyes don’t lose time jumping
            from one line to another but can stay focused on one point. Here,
            you choose the speed of going to the next word to adjust your
            speaking pace. It works like a teleprompter: an external technical
            tool limits the amount of data you can read.
        </>,
        <>
            Read the text aloud using the arrow keys and analyse it with
            Winston. Try to keep a natural way of speaking. Try not to make
            noise when pressing keys or clicking: this noise can be erroneously
            counted as syllables. A progress bar indicates how many words are
            left.
        </>
    ],
    info5: [
        <>
            Another way to slow down your pace is to deliberately put the
            emphasis of each sentence on some well chosen words. When doing
            this, you stress those words by pronouncing them slightly louder.
            Naturally, you will start to pause slightly before and/or after
            pronouncing them. For this exercise, read aloud the text by putting
            the stress on the{' '}
            <strong className="is-custom-strong">highlighted words</strong>.
        </>
    ],
    info6: [
        <>
            In this exercise read the text at a normal pace, but pause at each
            hand (
            <span role="img" aria-label="waitHand">
                ✋
            </span>
            ) for more than 1 second. A trick is to count “one, two, three” in
            your head before continuing. This is a first step to use deliberate
            pauses to slow down your pace.
        </>
    ],
    info7: [
        <>
            Let’s take pausing to the next level: here, we use punctuation in
            the text to pause:
        </>,
        //cheat
        <ul>
            <li>
                Comma between strongly coupled clauses (short pause): count
                mentally to one
                <strong className="is-custom-strong">&nbsp;1 </strong>
            </li>
            <li>
                Other use of commas: count mentally to two
                <strong className="is-custom-strong">&nbsp;1-2 </strong> (as in
                the previous exercise)
            </li>
            <li>
                Period (long pause): count mentally to three
                <strong className="is-custom-strong">&nbsp;1-2-3 </strong>
            </li>
            <li>
                New paragraph (very long pause): count mentally to five
                <strong className="is-custom-strong">&nbsp;1-2-3-4-5 </strong>
            </li>
        </ul>,
        <>
            Note: in Dutch we omit the comma before “en” and “of”, while we
            actually do pause then.
        </>
    ]
};

export const exercisesData = [
    {
        /* Null */
    },
    {
        /* Introduction */
    },
    {
        exerciseNumber: 2,
        title: 'One, two, three device testing',
        titleExercise: 'Exercise I Speak Too Fast: One, two, three test'
    },
    {
        exerciseNumber: 3,
        title: 'Baseline',
        titleExercise: 'Exercise I Speak Too Fast: Your baseline'
    },
    {
        exerciseNumber: 4,
        title: 'One word at time',
        titleExercise: 'Exercise I Speak Too Fast: One word at time'
    },
    {
        exerciseNumber: 5,
        title: 'Emphasis',
        titleExercise: 'Exercise I Speak Too Fast: Emphasis'
    },
    {
        exerciseNumber: 6,
        title: 'Scanning Speech',
        titleExercise: 'Exercise I Speak Too Fast: Scanning Speech'
    },
    {
        exerciseNumber: 7,
        title: 'Punctuation to pause',
        titleExercise: 'Exercise I Speak Too Fast: Punctuation to pause'
    }
];
