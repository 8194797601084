import React from 'react';
import PropTypes from 'prop-types';
import { ScaleLoader } from 'react-spinners';
import { Link } from 'react-router-dom';

import { rebase } from '../rebase';
import EditSpeechForm from './EditSpeechForm';
import SpeechDetails from './SpeechDetails';
import FaktionResultsPage from '../faktion/js/pages/UploadPage';

import { site } from './../config.js';

import {Paper, Typography, Button, Grid, IconButton} from '@material-ui/core/';
import SadIcon from '@material-ui/icons/SentimentDissatisfied';
import Edit from '@material-ui/icons/Edit';
import Print from '@material-ui/icons/Print';
import Check from '@material-ui/icons/Check';
import Comment from '@material-ui/icons/Comment';

const style = {
    NotFound: {
        fontSize: '20px',
        textAlign: 'center'
    },
    SadIcon: {
        width: '100px',
        fontSize: '100px',
    },
    DivViewHeight: {
        height: '60vh'
    },
    Button:{
        backgroundColor: "#3E67FF",
        color: 'white',
        margin: '1em 3px 0 0',
        minHeight: '40px'
    }
};

class ReportPage extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        print: PropTypes.bool.isRequired,
        speech: PropTypes.object.isRequired,
        parkiParams: PropTypes.object.isRequired,
        updateSpeech: PropTypes.func.isRequired,
        shareSpeech: PropTypes.func.isRequired,
        unShareSpeech: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        getUserId: PropTypes.func.isRequired,
        getUserEmail: PropTypes.func.isRequired,
    };

    state = {
        reportId: undefined,
        report: undefined,
        comments: {},
        editEnabled: false,
        commentEnabled: false
    };

    rebaseReportRef = null;
    rebaseCommentsRef = null;

    componentDidMount() {
        if(this.props.speech)
        {
            const reportId = this.props.match.params.reportId;
            this.setState({ reportId });
            this.rebaseReportRef = rebase.bindToState(
                `reports/${reportId}`,
                {
                    context: this,
                    state: 'report',
                    onFailure: err => {
                        console.log('Failed to get report. Error:', err);
                    },
                },
            );
            this.rebaseCommentsRef = rebase.bindToState(
                `speechComments/${this.props.speech.id}`,
                {
                    context: this,
                    state: 'comments',
                    onFailure: err => {
                        console.log('Failed to get comments. Error:', err);
                    },
                }
            );
        }
    }

    componentWillUnmount(){
        if(this.rebaseReportRef)
            rebase.removeBinding(this.rebaseReportRef);

        if(this.rebaseCommentsRef)
            rebase.removeBinding(this.rebaseCommentsRef);
    }

    getLoadingText = () => {
        if (this.state.report.step) {
            const {number, total, name} = this.state.report.step;
            switch (name) {
                case 'generate_transcript':  // Old report
                case 'analyze_with_praat':   // Report >= 2.0.0
                    return (
                        <p className="background-text">
                            Generating report.<br />
                            Winston is looking for his reading glasses.<br />
                            Unbelievable but true, this can take a few minutes.<br />
                            ({number}/{total})
                        </p>
                    );
                case 'analyze_audio':       // Old report
                case 'analyze_with_drift':  // Report >= 2.0.0
                    return (
                        <p className="background-text">
                            Generating report.<br />
                            Winston needs some time to think.<br />
                            He needs a whisky for that.<br />
                            ({number}/{total})
                        </p>
                    );
                case 'finishing_up':
                    return (
                        <p className="background-text">
                            Generating report.<br />
                            Let Winston finish his glass of whisky...
                            or bottle.<br />
                            ({number}/{total})
                        </p>
                    );
                default:
                    return (
                        <p className="background-text">
                            Generating report.<br />
                            Unbelievable but true, this can take a while.<br />
                            ({number}/{total})
                        </p>
                    );
            }
        } else {
            return (
                <p className="background-text">
                    Generating report.<br />
                    Unbelievable but true, this can take a while.
                </p>
            );
        }
    };

    informationPaper = speech => (
        <Paper elevation={1} className="customPaper">
            {this.state.editEnabled ? (
                <EditSpeechForm
                    speech={speech}
                    updateSpeech={this.props.updateSpeech}
                    shareSpeech={this.props.shareSpeech}
                    unShareSpeech={this.props.unShareSpeech}
                    user={this.props.user}
                    getUserId={this.props.getUserId}
                    getUserEmail={this.props.getUserEmail}
                    comments={this.state.comments}
                    commentEnabled={this.state.commentEnabled}
                />
            ) : (
                <SpeechDetails
                    speech={speech}
                    shareSpeech={this.props.shareSpeech}
                    user={this.props.user}
                    getUserEmail={this.props.getUserEmail}
                    commentEnabled={this.state.commentEnabled}
                    comments={this.state.comments}
                />
            )}

            {this.state.reportId && (
                <React.Fragment>
                    {speech.owner === this.props.user.uid && (
                        <Button
                            variant="contained"
                            color="primary"
                            style={style.Button}
                            onClick={() => {
                                this.setState(prevState => ({
                                    editEnabled: !prevState.editEnabled,
                                    commentEnabled: false 
                                }));
                            }}
                        >
                            {this.state.editEnabled ? <Check /> : <Edit />}
                        </Button>
                    )}

                    {!this.state.editEnabled && 
                        ((speech.sharedWith && 
                        speech.sharedWith[this.props.user.uid]) ||
                        speech.owner === this.props.user.uid) && (
                        <Button
                            variant="contained"
                            color="primary"
                            style={style.Button}
                            onClick={() => {
                                this.setState(prevState => ({
                                    commentEnabled: !prevState.commentEnabled
                                }));
                            }}
                            disabled={this.state.editEnabled}
                        >
                            {this.state.commentEnabled ? (
                                <Check />
                            ) : (
                                <Comment />
                            )}
                        </Button>
                    )}

                    <Link
                        to={{
                            pathname: `/reports/${this.state.reportId}/print`
                        }}
                        className="nav-link"
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            style={style.Button}
                        >
                            <Print />
                        </Button>
                    </Link>
                </React.Fragment>
            )}
        </Paper>
    );


    renderNoPrint = speech => (
        <main>
            <div className="container">
                {this.props.speech.owner === this.props.user.uid ? (
                    <React.Fragment>
                        <Typography component="h1" variant="h4" gutterBottom>
                            {speech.title}
                        </Typography>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Typography component="h1" variant="h4" gutterBottom>
                            {speech.title}
                        </Typography>
                        {this.props.speech.ownerEmail && (
                        <Typography gutterBottom>
                            Shared by {this.props.speech.ownerEmail}
                        </Typography>
                        )}
                    </React.Fragment>
                )}

                {this.informationPaper(speech)}

                {this.state.report && this.state.report.status === 'DONE' ? (
                    <FaktionResultsPage
                        print={false}
                        session={this.state.report}
                        speech={speech}
                        parkiParams={this.props.parkiParams}
                    />
                ) : this.state.report &&
                  this.state.report.status === 'ERROR' ? (
                    <p className="background-text loader">
                        Upload failed. Please upload another speech.
                    </p>
                ) : (
                    <div className="loader">
                        <ScaleLoader color="#36f" />
                        {this.state.report && this.state.report.status
                            ? this.getLoadingText()
                            : null}
                    </div>
                )}
            </div>
        </main>
    );

    renderPrint = speech => (
        <div className="print-report">
            <Grid container spacing={24}>
                <Grid item md={6}>
                    <Typography component="h2" variant="h4" gutterBottom>
                        Speech Analysis<br />In-Depth Report
                    </Typography>
                </Grid>
                <Grid item md={6} className="alignRight">
                    <img
                        className="logo"
                        type="image/svg+xml"
                        src={site.logo}
                        alt="Logo Winston Analytics"
                        height="75"
                    />
                </Grid>
            </Grid>
            <br /><br />
            <Typography component="h1" variant="h4" gutterBottom className="nm-print">{speech.title}</Typography>
            {
                speech.ownerEmail &&
                <Typography gutterBottom>
                    <strong>Account:</strong> {speech.ownerEmail}
                </Typography>
            }
            <Typography>
                <strong>Recorded on:</strong> {new Date(speech.timestamp).toLocaleString()}
            </Typography>
            {
                speech.comment &&
                <Typography>
                    <strong>Comment:</strong> {speech.comment}
                </Typography>
            }
            <br />
            {
                (
                    this.state.report &&
                    this.state.report.status === 'DONE'
                ) ? (
                    <FaktionResultsPage
                        print={true}
                        session={this.state.report}
                        speech={speech}
                        parkiParams={this.props.parkiParams}
                    />
                ) : (
                    this.state.report &&
                    this.state.report.status === 'ERROR'
                ) ? (
                    <Typography>
                        Upload failed. Please upload another speech.
                    </Typography>
                ) : (
                    <Typography>
                        Still loading...
                    </Typography>
                )
            }
        </div>
    );
        
    tryGoBack = () => {
        const previousPath = this.props.history.location.pathname;
        this.props.history.goBack();
        if (this.props.history.location.pathname === previousPath)
            this.props.history.push('/');
    };

    render() {
        const speech = this.props.speech;
        if (!speech) {
            return (
                <main>
                    <div className="container">
                        <div className="is-flex" style={style.DivViewHeight}>
                            <div style={style.NotFound}>
                                <IconButton>
                                    <SadIcon style={style.SadIcon} />
                                </IconButton>
                                <Typography
                                    component="h2"
                                    variant="h3"
                                    gutterBottom
                                >
                                    Report Not Found!
                                </Typography>

                                <Typography component="h2" variant="h5">
                                    <Button
                                        href
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className="btn--primary"
                                        onClick={() => this.tryGoBack()}
                                    >
                                        Go back
                                    </Button>
                                </Typography>
                            </div>
                        </div>
                    </div>
                </main>
            );
        } else {
            if (this.props.print) {
                document.body.classList.add('print');
                return this.renderPrint(speech);
            } else {
                document.body.classList.remove('print');
                return this.renderNoPrint(speech);
            }
        }
    }
}


export default ReportPage;