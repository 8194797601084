import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    withStyles,
    withMobileDialog
} from '@material-ui/core/';

const styles = () => ({
    Paper: {
        width: '560px'
    }
});

const BrowserError = (props) => {
    const { fullScreen, classes, 
        firebaseMakeConnections, updateStateViaProps } = props;
    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: classes.Paper }}
            >
                <DialogTitle id="alert-dialog-title">
                    This browser is not supported
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Winston cannot provide a decent user experience
                        with your current browser. Please use Chrome or
                        Firefox instead.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            firebaseMakeConnections();
                            updateStateViaProps('browserError',false);
                        }}
                        color="primary"
                    >
                        Continue anyway in this browser
                    </Button>
                </DialogActions>
            </Dialog>
            <div variant="outlined" color="primary" />
        </React.Fragment>
    );
}

BrowserError.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    firebaseMakeConnections: PropTypes.func.isRequired,
    updateStateViaProps: PropTypes.func.isRequired
};

export default withMobileDialog({ breakpoint: 'xs' })(
    withStyles(styles)(BrowserError)
);
