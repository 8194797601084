import React from 'react';
import PropTypes from 'prop-types';

import FaMicrophone from 'react-icons/lib/fa/microphone';
import FaTrashO from 'react-icons/lib/fa/trash-o';
import TiMediaPause from 'react-icons/lib/ti/media-pause';
import TiMediaRecord from 'react-icons/lib/ti/media-record';

import { convertDuration } from '../helpers';
// import Mp3Worker from '../mp3.worker';
import WavWorker from '../wav.worker';

import { Button } from '@material-ui/core/';


const style = {
    addButton: {
        margin: '1em 0',
        minHeight: '40px'
    }
};

class Recording extends React.Component {
    static propTypes = {
        maxFileSize: PropTypes.number.isRequired,
        onRecordingEnable: PropTypes.func.isRequired,
        onRecordingDisable: PropTypes.func.isRequired,
    };

    state = {
        status: 'NULL', // NULL, RECORDING, PAUSED
        recordDuration: 0,
        recordLength: 0,
    };

    audioInput = null;
    audioNode = null;
    audioSampleRate = null;

    recordedData = [];
    recordStream = null;
    recordBufferLength = 4096;

    isPaused = false;
    isProcessingAudio = false;

    sizeFactor = 2;

    startRecording = () => {
        const audioCtx = new (window.AudioContext
            || window.webkitAudioContext)();

        if (audioCtx.createScriptProcessor) {
            this.audioNode = audioCtx.createScriptProcessor(
                this.recordBufferLength, 1, 1);
        } else if (audioCtx.createJavaScriptNode) {
            this.audioNode = audioCtx.createJavaScriptNode(
                this.recordBufferLength, 1, 1);
        } else {
            console.log('WebAudio not supported!');
        }
        this.audioNode.connect(audioCtx.destination);

        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                this.setState({ status: 'RECORDING'});
                this.audioInput = audioCtx.createMediaStreamSource(stream);
                this.audioInput.connect(this.audioNode);
                this.recordStream = stream;
                this.audioNode.onaudioprocess = this.processAudio;
                this.audioSampleRate = audioCtx.sampleRate;
            })
            .catch(err => {
                console.log(err);
                this.resetRecording();
            });
    };

    stopRecording = () => {
        this.recordStream.getTracks().forEach(track => {
            track.stop()
        });
        this.audioInput.disconnect();
        this.audioNode.disconnect();
        this.audioNode.onaudioprocess = null;
    };

    resetRecording = async () => {
        this.stopRecording();
        while (this.isProcessingAudio) {
            await (() => new Promise(r => setTimeout(r, 50)))();
        }
        this.recordedData = [];
        this.setState({
            status: 'NULL',
            recordDuration: 0,
            recordLength: 0,
        });
        this.props.onRecordingDisable();
    };

    processAudio = event => {
        if (this.paused) return;
        if (this.sizeFactor * this.state.recordLength
            >= this.props.maxFileSize) {
            this.setState({ status: 'PAUSED' });
            this.isPaused = true;
            return;
        }
        this.isProcessingAudio = true;
        this.recordedData.push(
            new Float32Array(event.inputBuffer.getChannelData(0))
        );
        const recordLength = this.state.recordLength + this.recordBufferLength;
        const recordDuration = 1000 * recordLength / this.audioSampleRate;
        this.setState(
            { recordLength, recordDuration },
            () => { this.isProcessingAudio = false; },
        );
    };

    getAudioBlob = async () => {
        this.stopRecording();
        while (this.isProcessingAudio) {
            await (() => new Promise(r => setTimeout(r, 50)))();
        }
        // const encodedAudio = await this.encodeAudio(
        //     {
        //         channels: 1,
        //         sampleRate: this.audioSampleRate,
        //         kbps: 128,
        //         recordLength: this.state.recordLength,
        //         recordedData: this.recordedData,
        //     }
        // );
        // return new Blob(encodedAudio, { type: 'audio/mp3' })
        const encodedAudio = await this.encodeAudio(
            {
                sampleRate: this.audioSampleRate,
                recordLength: this.state.recordLength,
                recordedData: this.recordedData,
            }
        );
        return new Blob(encodedAudio, { type: 'audio/wav' })
    };

    encodeAudio = (config) => new Promise(resolve => {
        const webWorker = new WavWorker();
        webWorker.addEventListener('message', event => {
            resolve(event.data);
            webWorker.terminate();
        });
        webWorker.postMessage(config);
    });

    onStart = () => {
        this.props.onRecordingEnable();
        this.startRecording();
    };

    onPause = () => {
        this.setState({ status: 'PAUSED' });
        this.paused = true;
    };

    onResume = () => {
        this.setState({ status: 'RECORDING' });
        this.paused = false;
    };

    onDelete = async () => {
        await this.resetRecording();
    };

    render () {
        return (
            <React.Fragment>
                {
                    this.state.status === 'NULL' &&
                    <Button
                        variant="contained" color="primary"
                        className="icon-button btn--primary"
                        type="button"
                        onClick={this.onStart}
                        style={style.addButton}
                    >
                        <FaMicrophone size={20} />
                    </Button>
                }
                {
                    this.state.status === 'RECORDING' &&
                    <Button
                        variant="contained" color="primary"
                        className="icon-button btn--primary"
                        type="button"
                        onClick={this.onPause}
                    >
                        <TiMediaPause size={20} />
                    </Button>
                }
                {
                    this.state.status === 'PAUSED' &&
                    (
                        this.sizeFactor * this.state.recordLength < this.props.maxFileSize
                    ) &&
                    <Button
                        variant="contained" color="primary"
                        className="icon-button btn--primary"
                        type="button"
                        onClick={this.onResume}
                    >
                        <TiMediaRecord size={20} />
                    </Button>
                }
                {
                    this.state.status !== 'NULL' &&
                    <Button
                        variant="contained" color="primary"
                        className="icon-button btn--primary"
                        type="button"
                        onClick={this.onDelete}
                    >
                        <FaTrashO size={20} />
                    </Button>
                }
                {
                    this.state.recordDuration > 0 &&
                    convertDuration(this.state.recordDuration)
                }
                {
                    this.state.recordLength > 0 &&
                    ` (${
                        // Factor 2 for wav file
                        (this.sizeFactor * this.state.recordLength / 2 ** 20)
                            .toFixed(1)
                        } of ${
                        (this.props.maxFileSize / 2 ** 20).toFixed(0)
                        } MB)`
                }
            </React.Fragment>
        );
    }
}

export default Recording;