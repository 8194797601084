import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
    CardHeader,
    CardContent,
    CardActions,
    IconButton,
    Typography,
} from '@material-ui/core/';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import VideoIcon from '@material-ui/icons/LiveTv';

import DeleteModal from './DeleteModal';

const style = {
    small: {
        paddingTop: '1em',
        fontSize: '.85em',
        color: 'rgba(0, 0, 0, 0.54)',
    },

    CardContent: {
        marginBottom: '50px',
    },

    CardActions: {
        position: 'absolute',
        bottom: '0',
    },
};

class SpeechCard extends React.Component {
    static propTypes = {
        path: PropTypes.string.isRequired,
        onDelete: PropTypes.func.isRequired,
        speech: PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            comment: PropTypes.string,
            timestamp: PropTypes.number.isRequired,
            url: PropTypes.string,
            owner: PropTypes.string,
            ownerEmail: PropTypes.string,
            sharedWith: PropTypes.object,
        }).isRequired,
        user: PropTypes.object.isRequired,
    };

    state = {
        isOpen: false,
        success: false,
    };

    toggleModal = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    };

    confirmDeleteSpeech = () => {
        this.setState({
            isOpen: false,
            success: true,
        });

        this.props.onDelete(this.props.speech.id);
    };

    render() {
        const isOwner = this.props.speech.owner === this.props.user.uid;
        return (
            <React.Fragment>
                <Link 
                    to={this.props.path}
                    className="nav-link" 
                >
                    <CardHeader
                        className="has-pointer"
                        title={this.props.speech.title}
                        subheader={new Date(
                            this.props.speech.timestamp)
                            .toLocaleString()
                        }
                    />
                </Link>

                <CardContent style={style.CardContent}>
                    <Typography>{this.props.speech.comment}</Typography>
                    <Typography component="small" style={style.small}>
                        {!isOwner &&
                            this.props.speech.ownerEmail &&
                            (this.props.speech.sharedWith &&
                            this.props.user.uid in
                                this.props.speech.sharedWith ? (
                                <p>Shared by {this.props.speech.ownerEmail}</p>
                            ) : (
                                <p>Added by {this.props.speech.ownerEmail}</p>
                            ))}
                    </Typography>
                </CardContent>

                <CardActions disableActionSpacing style={style.CardActions}>
                    <Link to={this.props.path} className="nav-link" >
                        <IconButton
                            aria-label="See speech"
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Link>
                    {this.props.speech.url ? (
                        <Link to={this.props.path} className="nav-link" >
                            <IconButton aria-label="See speech">
                                <VideoIcon />
                            </IconButton>
                        </Link>
                    ) : null}
                    {isOwner && (
                        <IconButton
                            aria-label="Remove speech"
                            onClick={this.toggleModal}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                </CardActions>

                {this.state.isOpen && (
                    <DeleteModal
                        title={this.props.speech.title}
                        show={this.state.isOpen}
                        onDelete={this.confirmDeleteSpeech}
                        onClose={this.toggleModal}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default SpeechCard
