import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography, SnackbarContent } from '@material-ui/core/';
import { convertDuration, toMinute } from '../../../helpers';

class Result extends React.Component {
    static propTypes = {
        // From index.js
        exerciseNumber: PropTypes.number.isRequired,
        // From RecordingExercise (parent) or its inheritors
        stats: PropTypes.object.isRequired,
        averageSyllables: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
        expectionalState: PropTypes.string, //String ??????????
    };

    time = convertDuration(this.props.stats.clipped_duration * 1000);
    spm = toMinute(this.props.stats.speaking_rate);

    storyEvaluation = stats => {
        const { speaking_rate, range_good_pace } = stats;
        return (
            <>
                <Typography>
                    {speaking_rate < range_good_pace[0] ? (
                        <>
                            This is below {toMinute(range_good_pace[0])} spm.
                        </>
                    ) : speaking_rate > range_good_pace[1] ? (
                        <>
                            This is above {toMinute(range_good_pace[1])} spm.
                        </>
                    ) : (
                        <>
                            This is within the range 
                            of {toMinute(range_good_pace[0])} 
                            and {toMinute(range_good_pace[1])} spm.
                        </>
                    )}
                </Typography>
                <Typography>
                    For a story, we consider this to be
                    {speaking_rate < range_good_pace[0] ? (
                        <span> slow</span>
                    ) : speaking_rate > range_good_pace[1] ? (
                        <span> fast</span>
                    ) : (
                        <span> good</span>
                    )}
                    .
                </Typography>
            </>
        );
    };

    commonResult = () => {
        const { stats } = this.props;
        return (
            <>
                <Grid item xs={12}>
                    <Typography>
                        You spoke {stats.syllable_count} syllables 
                        in {this.time}. This brings your speaking rate on:
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Paper elevation={1} className="customPaper">
                        <Typography>{this.spm} spm</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    {this.storyEvaluation(stats)}
                </Grid>
            </>
        );
    };

    successText = {
        exercise1: (
            <>
                {this.commonResult()}
                <Grid item xs={12}>
                    <Typography>
                        The difficulty is to pronounce everything clearly. If
                        you were swallowing some letters, retry this exercise,
                        until you have full control over your voice.
                    </Typography>
                    <Typography>
                        If you wan to try out the other extreme, you can move
                        to the Staccato exercise.
                    </Typography>
                </Grid>
            </>
        ),

        exercise2: (
            <>
                {this.commonResult()}
                <Grid item xs={12}>
                    <Typography>
                        When doing this exercises, the difficulty might have
                        been to not pronounce the [and]. If you did it
                        successfully, you should have drastically reduced your
                        speaking pace.
                    </Typography>
                </Grid>
            </>
        ),

        exercise3: (
            <>
                {this.commonResult()}
                <Grid item xs={12}>
                    <Typography>
                        You should end-up closely around{' '}
                        {this.props.expectionalState
                            ? this.props.expectionalState
                            : 100} spm, 
                        the same pace as the metronome. Now try to
                        increase or decrease the pace to feel the difference.
                    </Typography>
                </Grid>
            </>
        )
    };

    failText = exerciseNumber => {
        const { stats, averageSyllables } = this.props;
        if (stats.syllable_count < averageSyllables * 0.8) {
            switch (exerciseNumber) {
                case 1:
                case 2:
                case 3:
                    return (
                        <>
                            Winston only heard {stats.syllable_count} syllables
                            while he should have heard {averageSyllables}. If
                            you have read the whole text, there might be
                            something wrong with your microphone. Please listen
                            to your recording on the bottom of your
                            speech report.
                        </>
                    );
                default:
                    return null;
            }
        } else {
            switch (exerciseNumber) {
                case 1:
                case 2:
                case 3:
                    return (
                        <>
                            Winston heard {stats.syllable_count} syllables
                            while he should have heard only {averageSyllables}.
                            This can be caused by noise, which can be falsely
                            interpreted as syllables. Please listen to your
                            recording on the bottom of your speech report to
                            find out.
                        </>
                    );
                default:
                    return null;
            }
        }
    };

    render() {
        return this.props.type === 'fail' ? (
            <SnackbarContent
                className="is-warning-snackbar"
                message={this.failText(this.props.exerciseNumber)}
            />
        ) : this.props.type === 'success' ? (
            this.successText[`exercise${this.props.exerciseNumber}`]
        ) : null;
    }
}

export default Result;
