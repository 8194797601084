import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';

import ResultHeader from '../components/result/ResultHeader';
import Result from '../components/result/Result';
import ParkiResult from '../components/result/ParkiResult';
import DetailsTab from '../components/result/DetailsTab';
import TranscriptTab from '../components/result/TranscriptTab';
import ContainerHeader from '../components/result/ContainerHeader';
import Timeline from '../components/Timeline.jsx';
import {
    speechType,
    pace,
    pause,
    pitch,
    volume,
} from '../components/result/utils/copy';

import {Paper, Typography} from '@material-ui/core/';
import { isVersion } from '../../../helpers';
import '../../style/custom.css';


export default class UploadPage extends Component {
    static propTypes = {
        print: PropTypes.bool.isRequired,
        session: PropTypes.object.isRequired,
        speech: PropTypes.object.isRequired,
        parkiParams: PropTypes.object.isRequired,
    };

    state = {};
    playerRef = React.createRef();

    componentDidMount() {
        if (this.props.print) return;

        const storage = firebase.storage();
        const wavRef = storage.ref(`wav_files/${this.props.session.id}.wav`);
        const wformRef = storage.ref(`waveforms/${this.props.session.id}.wform`);
        const pitchRef = storage.ref(`pitches/${this.props.session.id}.pitch`);

        wavRef.getDownloadURL()
            .then(url => this.setState({ wavUrl: url }));

        wformRef.getDownloadURL()
            .then(url => fetch(url))
            .then(response => response.json())
            .then(data => this.setState({ waveform: data }));

        pitchRef.getDownloadURL()
            .then(url => fetch(url))
            .then(response => response.json())
            .then(data => this.setState({ freqHz: data }));
    }

    componentDidUpdate(prevState) {
        if (!this.state.playerInitialized
            && this.state.wavUrl
            && this.state.waveform
            && this.state.freqHz) {
            // The timestamps we get from the <audio> tag change
            // at around 30fps. For smoother (60fps) scrolling we
            // need to animate between the timestamps instead.
            let lastFrame = 0;
            let lastPlayerTime = 0;
            const animateCurrentTime = t => {
                const player = this.playerRef.current;
                const isPlaying = !player.paused;
                const playerTime = player.currentTime;
                let newTime = playerTime;
                const diff = t - lastFrame;
                lastFrame = t;
                if (isPlaying && playerTime === lastPlayerTime) {
                    newTime += diff / 1000;
                }
                lastPlayerTime = playerTime;

                this.setState({
                    currentTime: newTime,
                    playing: isPlaying,
                });
            };

            this.setState({ playerInitialized: true });

            const update = (t) => {
                animateCurrentTime(t);
                this.setState({
                    animationId: window.requestAnimationFrame(update)
                });
            };

            this.setState({
                animationId: window.requestAnimationFrame(update)
            });
        }
    }

    componentWillUnmount() {
        window.cancelAnimationFrame(this.state.animationId);
    }

    onSeek(t) {
        const player = this.playerRef.current;
        this.setState({ currentTime: t });
        player.currentTime = t;
        if (player.paused) {
            player.play();
        } else {
            player.pause();
        }
    }

    render() {
        const { playing, currentTime } = this.state;
        const { print, session, speech, parkiParams } = this.props;
        const version = session.report_version || '1.0.0';
        const isV = c => isVersion(version, c);

        const speechTypeCopy =
            speechType(
                speech.speechType
            );
        const isParki = speech.speechType === 'PLVT exercise';

        const paceCopy = pace(
            isV({lt: '2.0.0'})
                ? session.stats.wpm
                : session.stats.speaking_rate,
            session.stats.range_good_pace,
        );

        const pitchCopy = pitch(
            session.average_pitch_whole,
            session.stats.range_good_pitch,
        );

        const pauseCopy = isV({lt: '2.0.0'})
            ? pause(
                session.stats.long_pauses_norm,
                session.stats.range_good_long_pauses,
            ) : pause(
                session.stats.pause_rate,
                session.stats.range_good_pauses,
            );

        const volumeCopy = volume(
            session.stats.average_peak_whole_norm,
            session.stats.range_good_peak
        );

        const overallSummary = (
            pitchCopy.shortSummary
            + ' ' + paceCopy.shortSummary
            + ' ' + pauseCopy.shortSummary
            + ' ' + volumeCopy.shortSummary
        );

        const divClass = print ? {} : {className: 'main-container'};

        return (
            <div {...divClass}>
                {
                    !isParki &&
                    <Result
                        print={print}
                        data={session.stats}
                        sentences={session.sentences}
                        version={version}
                    />
                }
                {
                    isParki &&
                    session.stats.absolute_peak_whole !== undefined &&
                    session.stats.absolute_pitch_whole !== undefined &&
                    session.stats.speaking_rate !== undefined &&
                    <ParkiResult
                        print={print}
                        data={session.stats}
                        parkiParams={parkiParams}
                    />
                }
                <div className="page-break"></div>
                {
                    speech.speechType && speechTypeCopy &&
                    <DetailsTab
                        print={print}
                        header={`The ${speech.speechType}`}
                        description="What kind of message are you conveying?"
                        pill={null}
                        title={speechTypeCopy.title}
                        highlight={speechTypeCopy.highlight}
                        summary={speechTypeCopy.summary}
                        tips={speechTypeCopy.tips}
                    />
                }

                {
                    !isParki &&
                    <ResultHeader
                        print={print}
                        hero="Vocal variety"
                        highlight="Your voice is an essential tool to become a better public speaker. Dive into this analysis and apply the tip to convey your message and connect with the audience. Vocal variety speaks to the soul."
                        summary={overallSummary}
                    />
                }

                <div className="page-break"></div>

                {
                    !isParki && <React.Fragment>
                        <DetailsTab
                            print={print}
                            header="Your pitch"
                            description="The rise and fall of the voice"
                            pill={{
                                value: `${session.stats.average_pitch_whole.toFixed(0)} Hz`,
                                info: `The human voice has a frequency of about 100 Hz (male) or 200 Hz (female). This value shows how much your voice fluctuates around your average voice frequency. Ideally, this should be between ${session.stats.range_good_pitch[0]} and ${session.stats.range_good_pitch[1]} Hz.`,
                            }}
                            title={pitchCopy.title}
                            highlight={pitchCopy.highlight}
                            summary={pitchCopy.summary}
                            tips={pitchCopy.tips}
                        />
                        <DetailsTab
                            print={print}
                            header="Your pace"
                            description="The speed of delivery"
                            pill={
                                isV({lt: '2.0.0'})
                                    ? {
                                        value: `${Math.round(session.stats.wpm)} wpm`,
                                        info: `The ideal pace to speak is between ${session.stats.range_good_pace[0]} and ${session.stats.range_good_pace[1]} words per minute (wpm)`,
                                    } : {
                                        value: `${Math.round(session.stats.speaking_rate * 60)} spm`,
                                        info: `The ideal pace to speak is between ${Math.round(session.stats.range_good_pace[0] * 60)} and ${Math.round(session.stats.range_good_pace[1] * 60)} syllables per minute (spm)`,
                                    }
                            }
                            title={paceCopy.title}
                            highlight={paceCopy.highlight}
                            summary={paceCopy.summary}
                            tips={paceCopy.tips}
                            exercises={paceCopy.exercises}
                            exercisesUrl={paceCopy.exercisesUrl}
                        />
                        <DetailsTab
                            print={print}
                            header="Your use of pause"
                            description="A pause turns a monologue into a dialogue with the audience."
                            pill={
                                isV({lt: '2.0.0'})
                                    ? {
                                        value: `${(session.stats.long_pauses_norm * 100).toFixed(1)}%`,
                                        info: `Ratio of pauses longer than 1 second between the words (ideally between ${session.stats.range_good_long_pauses[0] * 100}% and ${session.stats.range_good_long_pauses[1] * 100}%)`,
                                    } : {
                                        value: `${Math.round(session.stats.pause_rate * 60)} ppm`,
                                        info: `Number of pauses per minute (ideally between ${Math.round(session.stats.range_good_pauses[0] * 60)} and ${Math.round(session.stats.range_good_pauses[1] * 60)} ppm)`,
                                    }
                            }
                            title={pauseCopy.title}
                            highlight={pauseCopy.highlight}
                            summary={pauseCopy.summary}
                            tips={pauseCopy.tips}
                        />
                        <DetailsTab
                            print={print}
                            header="Your volume"
                            description="How often do you shift the gears of volume?"
                            pill={{
                                value: `${(session.stats.average_peak_whole_norm * 100).toFixed(0)}%`,
                                info: `Relative variation of the volume of your voice (ideally between ${session.stats.range_good_peak[0] * 100}% and ${session.stats.range_good_peak[1] * 100}%)`,
                            }}
                            title={volumeCopy.title}
                            highlight={volumeCopy.highlight}
                            summary={volumeCopy.summary}
                            tips={volumeCopy.tips}
                        />
                    </React.Fragment>
                }
                {
                    session.sentences &&
                    <TranscriptTab
                        print={print}
                        sentences={session.sentences}
                        pill={session.stats.n_words && {
                            value: `${session.stats.n_words} words`,
                            info: 'Number of words in your speech.',
                        }}
                    />
                }
                {this.state.wavUrl && this.state.freqHz && this.state.waveform ? (
                    <Fragment>
                        <Paper elevation={1} className="customPaper">
                            <ContainerHeader
                                title="Playback"
                                subtitle="Listen back to your recording"
                            />
                            <Typography gutterBottom>
                                Click on one of the graphs till you see a red line, click again to start the playback. Slide horizontally to scroll through the speech.
                            </Typography>
                            <Typography gutterBottom>
                                The first graph represents the volume, the second graph shows the variation in pitch.
                            </Typography>
                            <Timeline
                                freqHz={this.state.freqHz}
                                waveform={this.state.waveform}
                                currentTime={currentTime}
                                playing={playing}
                                zoom={0.3}
                                onSeek={this.onSeek.bind(this)}
                            />
                            <audio
                                ref={this.playerRef}
                                type="audio/wav"
                                src={this.state.wavUrl}
                            />
                        </Paper>
                    </Fragment>
                ) : null
                }
            </div>
        );
    }
}
