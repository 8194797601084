import React from 'react';
import { Typography, Grid, Paper } from '@material-ui/core/';

const style = {
    AdjustedWidth: {
        width: '100%',
        borderRadius: '4px'
    }
};

class HoudingEnOntspanning extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Paper elevation={1} className="customPaper">
                    <Typography component="h2" variant="title" gutterBottom>
                        Houding en ontspanning
                    </Typography>
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={7} >
                            <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    <Typography>
                                        Vooraleer je aan de spreekoefeningen
                                        begint, is een goede uitgangspositie
                                        heel belangrijk om je stem niet te
                                        vermoeien. Je zit dus het best
                                        ontspannen rechtop.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Hoe doe je dit?</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography>
                                        Let op het volgende:
                                    </Typography>
                                    <Typography component="ul" gutterBottom>
                                        <li>
                                            de voeten staan een stukje uit
                                            elkaar en vast op de grond
                                        </li>
                                        <li>
                                            de benen staan in een rechte hoek
                                            van 90°
                                        </li>
                                        <li>
                                            de onderrug steunt tegen de
                                            rugleuning
                                        </li>
                                        <li>de rug is recht</li>
                                        <li>
                                            de schouders zijn zo ontspannen
                                            mogelijk
                                        </li>
                                        <li>het hoofd is opgericht</li>
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}  >
                                    <Typography>
                                        Het is aan te raden om een stoel te
                                        gebruiken met een rugleuning die niet
                                        boven de schouders uitkomt. Ook een
                                        stevige zitting en armleuningen zijn
                                        belangrijk, zodat de patiënt geholpen
                                        wordt een opgerichte en daardoor
                                        actieve houding aan te nemen.
                                    </Typography>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                        <Grid item xs={12} sm={5}>
                                <img
                                    style={style.AdjustedWidth}
                                    src="https://storage.googleapis.com/winston-public/houding_plvt.jpg"
                                    alt="PLVT Houding"
                                />
                            </Grid>
                    </Grid>
                </Paper>
            </React.Fragment>
        );
    }
}
export default HoudingEnOntspanning;