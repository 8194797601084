import React from 'react';
import PropTypes from 'prop-types';
import SpeechCard from './SpeechCard';
import { site } from './../config.js';
import { Link } from 'react-router-dom';

// Material
import {
    Typography,
    Tooltip,
    Grid,
    Card,
    Button,
    TextField,
    SnackbarContent
} from '@material-ui/core/';
import AddIcon from '@material-ui/icons/Add';
import Search from '@material-ui/icons/Search';

import { debounce } from '../helpers';
import Fuse from 'fuse.js';
import DragAndDrop from './DragAndDrop.js';

const style = {
    Paper: {
        padding: '1em 1.5em',
        height: '100%',
        position: 'relative'
    },
    PaperAdd: {
        padding: '1em 1.5em',
        height: '100%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    AddIcon: {
        fontSize: '125px'
    },
    ContainerPadding: {
        paddingBottom: '16px'
    },
    Search: {
        marginTop: '-8px'
    }

};

class SpeechesPage extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        dataUsageWarning: PropTypes.bool.isRequired,
        mySpeeches: PropTypes.object.isRequired,
        receivedSpeeches: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        setUserState: PropTypes.func.isRequired,
        updateSpeech: PropTypes.func.isRequired,
        deleteSpeech: PropTypes.func.isRequired
    };

    state = {
        filter: ''
    };

    confirmDataUsageWarning = event => {
        event.preventDefault();
        this.props.setUserState({ dataUsageWarning: true });
    };

    onDelete = speechId => {
        this.props.deleteSpeech(speechId);
    };

    handleFilter = event => {
        this.handleFilterDebounce(event.target.value);
    };

    _handleFilter = event => {
        this.setState({ filter: event });
    };

    handleFilterDebounce = debounce(this._handleFilter, 250);

    cleanString = (string) => {
        if (string)
            return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        else return '';
    };

    filtering = speeches => {
        const { filter } = this.state;
        if (filter) {
            // Add new fields to filter on (remove accents, convert timestamps)
            speeches.forEach(speech => {
                speech.filterData =  [
                    this.cleanString(speech.title),
                    this.cleanString(speech.comment),
                    this.cleanString(new Date(speech.timestamp)
                        .toLocaleString()),
                    (speech.owner !== this.props.user.uid && speech.ownerEmail)
                        ? this.cleanString(`Shared by ${speech.ownerEmail}`)
                        : '',
                    this.cleanString(speech.speechType),
                    this.cleanString(speech.vrtRole),
                    this.cleanString(speech.vrtBrand),
                ].join(' ');
            });
            // https://fusejs.io/
            const options = {
                threshold: 0.3,
                tokenize: true,
                matchAllTokens: true,
                location: 0,
                distance: 150,
                maxPatternLength: 30,
                minMatchCharLength: 1,
                keys: ['filterData'],
            };

            const fuse = new Fuse(speeches, options);
            const filteredSpeeches = fuse.search(
                filter.normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim()
            );
            return filteredSpeeches.sort(
                (a, b) => a.timestamp - b.timestamp);
        } else return Object.values(speeches).sort(
            (a, b) => a.timestamp - b.timestamp);
    };

    handleDrop = droppedFile => {
        this.props.history.push({
            droppedFile: droppedFile,
            pathname: '/add-speech/'
        });
    };

    render() {
        const speeches = Object.values(this.props.mySpeeches).concat(
            Object.values(this.props.receivedSpeeches)
        );
        const filteredSpeeches = this.filtering(speeches);
        return (
            <main>
                <DragAndDrop handleDrop={this.handleDrop}>
                    <Grid container style={style.ContainerPadding}>
                        <Grid item xs={12} sm={8}>
                            <Typography
                                component="h1"
                                variant="h4"
                                gutterBottom
                            >
                                Speech Reports
                            </Typography>
                        </Grid>
                        {this.props.dataUsageWarning && (
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    className="searchInput"
                                    onChange={this.handleFilter}
                                    label={<Search style={style.Search} />}
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                />
                            </Grid>
                        )}
                    </Grid>
                    {this.props.dataUsageWarning === false ? (
                        <React.Fragment>
                            <SnackbarContent
                                className="is-error-snackbar"
                                message={
                                    site.nameShort +
                                    ' is quite greedy with your internet data usage (hundreds of megabytes). Please do not use it with mobile internet.'
                                }
                                action={
                                    <Button
                                        variant="contained"
                                        size="small"
                                        style={style.Button}
                                        onClick={this.confirmDataUsageWarning}
                                    >
                                        I understand!
                                    </Button>
                                }
                            />
                        </React.Fragment>
                    ) : (
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={6} lg={4}>
                                <Card elevation={1} style={style.PaperAdd}>
                                    <Tooltip title="Add new speech">
                                        <Link to="/add-speech/">
                                            <AddIcon
                                                color="disabled"
                                                style={style.AddIcon}
                                            />
                                        </Link>
                                    </Tooltip>
                                </Card>
                            </Grid>
                            {filteredSpeeches
                                .slice(0)
                                .reverse()
                                .map(speech => (
                                    <React.Fragment key={speech.id}>
                                        <Grid item xs={12} sm={6} lg={4}>
                                            <Card
                                                elevation={1}
                                                style={style.Paper}
                                            >
                                                <SpeechCard
                                                    key={speech.id}
                                                    speech={speech}
                                                    onDelete={this.onDelete}
                                                    path={`/reports/${speech.id}/`}
                                                    user={this.props.user}
                                                />
                                            </Card>
                                        </Grid>
                                    </React.Fragment>
                                ))}
                        </Grid>
                    )}
                </DragAndDrop>
            </main>
        );
    }
}

export default SpeechesPage;