import React from 'react';
import PropTypes from 'prop-types';

import {Paper, Typography, Divider} from '@material-ui/core/';

const style = {
    Divider: {
        margin: '1em 0',
    }
}

const ResultHeader = ({ print, hero, highlight, summary }) => {
    const innerContent = (
        <React.Fragment>
            <div className="page-break"></div>
            <Typography component="h1" variant={print ? "h4": "h6"} gutterBottom className="ff-header">Vocal variety</Typography>
            <Divider variant="middle" style={style.Divider} />
            <Typography gutterBottom>{highlight}</Typography>
            <br />
            <Typography component="h2" variant="h6" gutterBottom>How did you perform?</Typography>
            <Typography gutterBottom>{summary}</Typography>
        </React.Fragment>
    );
    if (print) {
        return innerContent;
    } else {
        return ( 
            <div className="container">
                <Paper elevation={1} className="customPaper">
                    {innerContent}
                </Paper>
            </div>
        );
    }
};

ResultHeader.propTypes = {
    print: PropTypes.bool.isRequired,
    hero: PropTypes.string.isRequired,
    highlight: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
};

export default ResultHeader;
