// eslint-disable-next-line
import React from 'react';

export const exercises = {
    exercise3: {
        NL: {
            text: [
                <>Ach!</>,
                <>Bah!</>,
                <>Boeh!</>,
                <>Bon!</>,
                <>Daar!</>,
                <>Dag!</>,
                <>Doei!</>,
                <>Foei!</>,
                <>Fout!</>,
                <>Fut!</>,
                <>Gak!</>,
                <>Goed!</>,
                <>Goh!</>,
                <>Gosh!</>,
                <>Gut!</>,
                <>Ha!</>,
                <>Hé!</>,
                <>Hè!</>,
                <>Hel!</>,
                <>Help!</>,
                <>Hier!</>,
                <>Hij!</>,
                <>Ho!</>,
                <>Hoe?</>,
                <>Hoi!</>,
                <>Hum!</>,
                <>Hup!</>,
                <>Ik!</>,
                <>Ja!</>,
                <>Jij!</>,
                <>Joh!</>,
                <>Kijk!</>,
                <>Kom!</>,
                <>Kul!</>,
                <>Leuk!</>,
                <>Nee!</>,
                <>Niet!</>,
                <>Nou!</>,
                <>Nu!</>,
                <>Och!</>,
                <>Oef!</>,
                <>Oeps!</>,
                <>Op!</>,
                <>Puf!</>,
                <>Puh!</>,
                <>Raf!</>,
                <>Stop!</>,
                <>Tuf!</>,
                <>Vies!</>,
                <>Vlug!</>,
                <>Waar?</>,
                <>Wacht!</>,
                <>Waf!</>,
                <>Wat?</>,
                <>Weg!</>,
                <>Wel!</>,
                <>Wie?</>,
                <>Wij!</>,
                <>Yes!</>,
                <>Zeg!</>,
                <>Zie!</>,
                <>Zo!</>,
            ],
            pickRandom: 10,
            syllables: 10,
            syllablesFactor: [0.6, 2.0],
        }
    },
    exercise4: {
        NL: {
            text: [
                <>aai</>,
                <>aak</>,
                <>aal</>,
                <>aap</>,
                <>bad</>,
                <>bal</>,
                <>bed</>,
                <>been</>,
                <>beer</>,
                <>bek</>,
                <>bes</>,
                <>bier</>,
                <>biet</>,
                <>bij</>,
                <>boef</>,
                <>boek</>,
                <>boer</>,
                <>boom</>,
                <>boor</>,
                <>boot</>,
                <>bos</>,
                <>bot</>,
                <>buik</>,
                <>bus</>,
                <>daar</>,
                <>dag</>,
                <>dak</>,
                <>dam</>,
                <>dan</>,
                <>das</>,
                <>dat</>,
                <>deel</>,
                <>den</>,
                <>deur</>,
                <>die</>,
                <>dij</>,
                <>dik</>,
                <>dit</>,
                <>doen</>,
                <>door</>,
                <>doos</>,
                <>dop</>,
                <>duif</>,
                <>duim</>,
                <>dun</>,
                <>dus</>,
                <>een</>,
                <>eik</>,
                <>fel</>,
                <>foor</>,
                <>fout</>,
                <>gaan</>,
                <>gaap</>,
                <>gat</>,
                <>geel</>,
                <>geen</>,
                <>gek</>,
                <>goal</>,
                <>goed</>,
                <>gooi</>,
                <>haai</>,
                <>haar</>,
                <>haas</>,
                <>ham</>,
                <>heel</>,
                <>heer</>,
                <>heet</>,
                <>hel</>,
                <>hem</>,
                <>hen</>,
                <>hier</>,
                <>hij</>,
                <>hoe</>,
                <>hoed</>,
                <>hol</>,
                <>hoog</>,
                <>hoop</>,
                <>hout</>,
                <>huis</>,
                <>hun</>,
                <>iep</>,
                <>ijs</>,
                <>jaar</>,
                <>jam</>,
                <>jas</>,
                <>jong</>,
                <>juf</>,
                <>kaal</>,
                <>kaas</>,
                <>kam</>,
                <>kan</>,
                <>kat</>,
                <>keer</>,
                <>kei</>,
                <>kin</>,
                <>kip</>,
                <>koe</>,
                <>koek</>,
                <>koek</>,
                <>kok</>,
                <>kom</>,
                <>koor</>,
                <>kop</>,
                <>kou</>,
                <>koud</>,
                <>kous</>,
                <>kuil</>,
                <>laan</>,
                <>lang</>,
                <>lap</>,
                <>leer</>,
                <>lees</>,
                <>leeuw</>,
                <>leuk</>,
                <>lid</>,
                <>lief</>,
                <>lijn</>,
                <>lip</>,
                <>loer</>,
                <>lok</>,
                <>lood</>,
                <>maak</>,
                <>maar</>,
                <>mag</>,
                <>mam</>,
                <>man</>,
                <>map</>,
                <>meer</>,
                <>mei</>,
                <>mep</>,
                <>mes</>,
                <>mijn</>,
                <>moet</>,
                <>mok</>,
                <>muis</>,
                <>mus</>,
                <>naam</>,
                <>naar</>,
                <>nat</>,
                <>neef</>,
                <>net</>,
                <>neus</>,
                <>niet</>,
                <>nieuw</>,
                <>nok</>,
                <>oog</>,
                <>ook</>,
                <>oom</>,
                <>oor</>,
                <>oud</>,
                <>paal</>,
                <>pad</>,
                <>pan</>,
                <>paus</>,
                <>peen</>,
                <>peer</>,
                <>peil</>,
                <>pen</>,
                <>pet</>,
                <>pijn</>,
                <>poes</>,
                <>pop</>,
                <>pot</>,
                <>puf</>,
                <>raam</>,
                <>raar</>,
                <>rat</>,
                <>reep</>,
                <>rek</>,
                <>reu</>,
                <>rijm</>,
                <>roep</>,
                <>rok</>,
                <>rood</>,
                <>room</>,
                <>ruit</>,
                <>sier</>,
                <>sik</>,
                <>soep</>,
                <>sok</>,
                <>sop</>,
                <>tak</>,
                <>tape</>,
                <>tas</>,
                <>taupe</>,
                <>teen</>,
                <>teil</>,
                <>thee</>,
                <>tien</>,
                <>tijd</>,
                <>toen</>,
                <>tol</>,
                <>top</>,
                <>tuin</>,
                <>uil</>,
                <>uk</>,
                <>vaak</>,
                <>vaas</>,
                <>vak</>,
                <>veeg</>,
                <>veel</>,
                <>veeli</>,
                <>vel</>,
                <>vief</>,
                <>vier</>,
                <>vies</>,
                <>vijf</>,
                <>vis</>,
                <>voet</>,
                <>vol</>,
                <>vuil</>,
                <>vuur</>,
                <>waar</>,
                <>waf</>,
                <>was</>,
                <>wat</>,
                <>wave</>,
                <>week</>,
                <>weer</>,
                <>weg</>,
                <>wel</>,
                <>wiel</>,
                <>wies</>,
                <>wij</>,
                <>wijn</>,
                <>wil</>,
                <>wit</>,
                <>wol</>,
                <>zaag</>,
                <>zak</>,
                <>zal</>,
                <>zee</>,
                <>zeep</>,
                <>zeer</>,
                <>zes</>,
                <>ziek</>,
                <>zin</>,
                <>zon</>,
                <>zout</>,
                <>zus</>,
                <>zuur</>,
            ],
            pickRandom: 10,
            syllables: 10,
            syllablesFactor: [0.6, 2.0],
        }
    },
    exercise5: {
        NL: {
            text: [
                <>Aalst</>,
                <>acht</>,
                <>arm</>,
                <>arts</>,
                <>badge</>,
                <>bank</>,
                <>bars</>,
                <>beurt</>,
                <>blad</>,
                <>blauw</>,
                <>bles</>,
                <>blij</>,
                <>bloem</>,
                <>bloot</>,
                <>boks</>,
                <>bonk</>,
                <>boord</>,
                <>bord</>,
                <>brak</>,
                <>brief</>,
                <>bril</>,
                <>broek</>,
                <>broer</>,
                <>brol</>,
                <>brood</>,
                <>brug</>,
                <>bruin</>,
                <>dicht</>,
                <>drab</>,
                <>dram</>,
                <>drie</>,
                <>drink</>,
                <>drop</>,
                <>druk</>,
                <>echt</>,
                <>eend</>,
                <>eens</>,
                <>erwt</>,
                <>Ests</>,
                <>fake</>,
                <>feest</>,
                <>film</>,
                <>fIets</>,
                <>flan</>,
                <>flans</>,
                <>fleece</>,
                <>flens</>,
                <>fleur</>,
                <>flits</>,
                <>flor</>,
                <>frak</>,
                <>fruit</>,
                <>geld</>,
                <>glas</>,
                <>gras</>,
                <>groep</>,
                <>groot</>,
                <>grut</>,
                <>gunst</>,
                <>hand</>,
                <>helm</>,
                <>hemd</>,
                <>hond</>,
                <>hoofd</>,
                <>iets</>,
                <>inkt</>,
                <>jeans</>,
                <>jurk</>,
                <>kaart</>,
                <>kast</>,
                <>kerk</>,
                <>kers</>,
                <>kind</>,
                <>klak</>,
                <>klam</>,
                <>klein</>,
                <>kleur</>,
                <>klok</>,
                <>knauw</>,
                <>knoop</>,
                <>knop</>,
                <>koets</>,
                <>kort</>,
                <>krant</>,
                <>krat</>,
                <>kring</>,
                <>krom</>,
                <>kruip</>,
                <>kwaad</>,
                <>lamp</>,
                <>land</>,
                <>licht</>,
                <>maand</>,
                <>maart</>,
                <>mank</>,
                <>melk</>,
                <>mens</>,
                <>mond</>,
                <>moord</>,
                <>munt</>,
                <>must</>,
                <>naast</>,
                <>nacht</>,
                <>oogst</>,
                <>paard</>,
                <>park</>,
                <>pels</>,
                <>plaats</>,
                <>pomp</>,
                <>prat</>,
                <>prins</>,
                <>rijst</>,
                <>rijst</>,
                <>schaap</>,
                <>schaar</>,
                <>scherm</>,
                <>schip</>,
                <>schoft</>,
                <>school</>,
                <>schoon</>,
                <>sjaal</>,
                <>slaap</>,
                <>slecht</>,
                <>smaak</>,
                <>smal</>,
                <>snars</>,
                <>snert</>,
                <>snoep</>,
                <>snol</>,
                <>soms</>,
                <>Spaans</>,
                <>spar</>,
                <>specht</>,
                <>spel</>,
                <>spleen</>,
                <>sport</>,
                <>spurt</>,
                <>staan</>,
                <>steeds</>,
                <>steen</>,
                <>step</>,
                <>sterk</>,
                <>stift</>,
                <>stoel</>,
                <>stoep</>,
                <>stok</>,
                <>straat</>,
                <>straf</>,
                <>strand</>,
                <>streep</>,
                <>stug</>,
                <>swipe</>,
                <>taart</>,
                <>tram</>,
                <>trap</>,
                <>trein</>,
                <>trui</>,
                <>vast</>,
                <>vla</>,
                <>vlees</>,
                <>vlieg</>,
                <>vlug</>,
                <>vork</>,
                <>vraag</>,
                <>vriend</>,
                <>vrouw</>,
                <>warm</>,
                <>woest</>,
                <>wolk</>,
                <>woord</>,
                <>wraak</>,
                <>zwart</>,
            ],
            pickRandom: 10,
            syllables: 10,
            syllablesFactor: [0.6, 2.0],
        }
    },
    exercise6: {
        NL: {
            text: [
                <>aaibaar</>,
                <>aaien</>,
                <>auteur</>,
                <>auto</>,
                <>bakken</>,
                <>banaan</>,
                <>beker</>,
                <>bellen</>,
                <>bezem</>,
                <>bezig</>,
                <>bijna</>,
                <>binnen</>,
                <>boeken</>,
                <>boerin</>,
                <>bomen</>,
                <>boter</>,
                <>boven</>,
                <>buiging</>,
                <>buiten</>,
                <>bureau</>,
                <>buren</>,
                <>café</>,
                <>daarom</>,
                <>datum</>,
                <>delen</>,
                <>dienaar</>,
                <>dippen</>,
                <>doerak</>,
                <>duwen</>,
                <>eiland</>,
                <>enig</>,
                <>eten</>,
                <>foto</>,
                <>fuiken</>,
                <>gebied</>,
                <>gebit</>,
                <>gedaan</>,
                <>geheel</>,
                <>gehoor</>,
                <>gelaat</>,
                <>gelijk</>,
                <>geluid</>,
                <>gemak</>,
                <>gemeen</>,
                <>genoeg</>,
                <>getal</>,
                <>gevaar</>,
                <>geven</>,
                <>gewoon</>,
                <>gierig</>,
                <>gieter</>,
                <>gitaar</>,
                <>hagel</>,
                <>halen</>,
                <>hamer</>,
                <>harig</>,
                <>hebben</>,
                <>heilig</>,
                <>helaas</>,
                <>hevig</>,
                <>hoelang</>,
                <>hoeveel</>,
                <>hoewel</>,
                <>hoezeer</>,
                <>horen</>,
                <>huren</>,
                <>iemand</>,
                <>jammer</>,
                <>jaren</>,
                <>jarig</>,
                <>kamer</>,
                <>kappen</>,
                <>karig</>,
                <>katten</>,
                <>keihard</>,
                <>kennen</>,
                <>kennis</>,
                <>Keurig</>,
                <>kijken</>,
                <>koekoek</>,
                <>kofﬁe</>,
                <>komen</>,
                <>koppig</>,
                <>laten</>,
                <>later</>,
                <>lelijk</>,
                <>lepel</>,
                <>leren</>,
                <>leven</>,
                <>lezen</>,
                <>lichaam</>,
                <>lijken</>,
                <>lokaal</>,
                <>luipaard</>,
                <>lukken</>,
                <>malen</>,
                <>manier</>,
                <>mannen</>,
                <>meenam</>,
                <>meubel</>,
                <>middag</>,
                <>minuut</>,
                <>moedig</>,
                <>moeras</>,
                <>molen</>,
                <>mollig</>,
                <>muren</>,
                <>muziek</>,
                <>namen</>,
                <>nijdig</>,
                <>nodig</>,
                <>noemen</>,
                <>nummer</>,
                <>nuttig</>,
                <>olijk</>,
                <>papier</>,
                <>pedaal</>,
                <>peper</>,
                <>piloot</>,
                <>poedel</>,
                <>poeder</>,
                <>puzzel</>,
                <>radio</>,
                <>raken</>,
                <>rijden</>,
                <>rijtuig</>,
                <>roepen</>,
                <>roken</>,
                <>rollen</>,
                <>ruiken</>,
                <>ruilen</>,
                <>ruzie</>,
                <>samen</>,
                <>sappig</>,
                <>seizoen</>,
                <>sieraad</>,
                <>sofa</>,
                <>succes</>,
                <>suiker</>,
                <>tafel</>,
                <>tegen</>,
                <>tellen</>,
                <>terras</>,
                <>terug</>,
                <>tijdig</>,
                <>toeval</>,
                <>tomaat</>,
                <>tussen</>,
                <>uren</>,
                <>vader</>,
                <>veilig</>,
                <>vettig</>,
                <>vieren</>,
                <>voeding</>,
                <>voelen</>,
                <>vorig</>,
                <>waarom</>,
                <>wafel</>,
                <>wakker</>,
                <>wassen</>,
                <>weiland</>,
                <>weinig</>,
                <>welkom</>,
                <>weten</>,
                <>wippen</>,
                <>wonen</>,
                <>zielig</>,
                <>zitten</>,
                <>zoeken</>,
                <>zonnig</>,
                <>zuinig</>,
            ],
            pickRandom: 10,
            syllables: 20,
            syllablesFactor: [0.6, 2.0],
        }
    },
    exercise7: {
        NL: {
            text: [
                <>aalbes</>,
                <>aalglad</>,
                <>aalmoes</>,
                <>Aalput</>,
                <>Aalsters</>,
                <>aambeeld</>,
                <>aanblik</>,
                <>aanbod</>,
                <>aanbouw</>,
                <>aandeel</>,
                <>aandoen</>,
                <>aangaat</>,
                <>aangeeft</>,
                <>aankeek</>,
                <>aankomt</>,
                <>aanleg</>,
                <>aanpak</>,
                <>aanpak</>,
                <>aanschouwd</>,
                <>aantal</>,
                <>aanval</>,
                <>aanzien</>,
                <>aardig</>,
                <>achter</>,
                <>adres</>,
                <>advies</>,
                <>afloop</>,
                <>afscheid</>,
                <>album</>,
                <>aldoor</>,
                <>aldus</>,
                <>algauw</>,
                <>almaar</>,
                <>altaar</>,
                <>altijd</>,
                <>alvast</>,
                <>alweer</>,
                <>angstig</>,
                <>antwoord</>,
                <>arbeid</>,
                <>armoe</>,
                <>avond</>,
                <>baarlijk</>,
                <>badhuis</>,
                <>bakpan</>,
                <>benieuwd</>,
                <>biljoen</>,
                <>billijk</>,
                <>bochtig</>,
                <>boksen</>,
                <>boodschap</>,
                <>boosheid</>,
                <>borstel</>,
                <>bosjes</>,
                <>botsing</>,
                <>broeken</>,
                <>buikpijn</>,
                <>buurman</>,
                <>daarbij</>,
                <>daardoor</>,
                <>daarheen</>,
                <>daarmee</>,
                <>daarnaast</>,
                <>daartoe</>,
                <>daarvan</>,
                <>daarvoor</>,
                <>dagboek</>,
                <>daling</>,
                <>danspaar</>,
                <>deftig</>,
                <>denken</>,
                <>dertien</>,
                <>dertig</>,
                <>dierlijk</>,
                <>ditmaal</>,
                <>dokter</>,
                <>donder</>,
                <>donker</>,
                <>doodmoe</>,
                <>doordat</>,
                <>doorgaan</>,
                <>doorheen</>,
                <>draaglijk</>,
                <>droevig</>,
                <>eenheid</>,
                <>eenmaal</>,
                <>eenzaam</>,
                <>eerbied</>,
                <>eerlijk</>,
                <>eindigt</>,
                <>elkaar</>,
                <>erbij</>,
                <>erdoor</>,
                <>erheen</>,
                <>erkend</>,
                <>ermee</>,
                <>ernaar</>,
                <>ernaast</>,
                <>ernstig</>,
                <>ertoe</>,
                <>ervaart</>,
                <>ervan</>,
                <>ervoor</>,
                <>feestdag</>,
                <>feestmaal</>,
                <>fontein</>,
                <>fortuin</>,
                <>gastheer</>,
                <>gebruik</>,
                <>geduld</>,
                <>geestig</>,
                <>gember</>,
                <>gerust</>,
                <>gezicht</>,
                <>goedheid</>,
                <>goedkoop</>,
                <>gordijn</>,
                <>grappig</>,
                <>groente</>,
                <>groeten</>,
                <>grondig</>,
                <>gunstig</>,
                <>haardvuur</>,
                <>haastig</>,
                <>handboek</>,
                <>handig</>,
                <>handschoen</>,
                <>handvol</>,
                <>hangen</>,
                <>harnas</>,
                <>heengaan</>,
                <>heerlijk</>,
                <>helling</>,
                <>helpen</>,
                <>herkend</>,
                <>herkent</>,
                <>hernam</>,
                <>hertog</>,
                <>hetgeen</>,
                <>hetzij</>,
                <>heuglijk</>,
                <>hierbij</>,
                <>hierdoor</>,
                <>hierheen</>,
                <>hiermee</>,
                <>hiertoe</>,
                <>hiervan</>,
                <>hiervoor</>,
                <>honger</>,
                <>hoofdpijn</>,
                <>hoogmoed</>,
                <>hoorbaar</>,
                <>houding</>,
                <>huisdeur</>,
                <>huurprijs</>,
                <>indien</>,
                <>ingaan</>,
                <>ingang</>,
                <>inhoud</>,
                <>inzien</>,
                <>jaarlijks</>,
                <>jasmijn</>,
                <>kalkoen</>,
                <>kantoor</>,
                <>kasteel</>,
                <>ketting</>,
                <>klaaglijk</>,
                <>kleding</>,
                <>kleuren</>,
                <>kleurig</>,
                <>knijpen</>,
                <>knikker</>,
                <>knobbel</>,
                <>knoﬂook</>,
                <>koetsier</>,
                <>koning</>,
                <>koopman</>,
                <>kostbaar</>,
                <>krachtig</>,
                <>kwalijk</>,
                <>lading</>,
                <>landgoed</>,
                <>landheer</>,
                <>landschap</>,
                <>langzaam</>,
                <>lastig</>,
                <>leeftijd</>,
                <>leerling</>,
                <>leiding</>,
                <>luchtig</>,
                <>luilak</>,
                <>maaltijd</>,
                <>maandag</>,
                <>machtig</>,
                <>markies</>,
                <>matras</>,
                <>meedoen</>,
                <>meegaan</>,
                <>mening</>,
                <>mentor</>,
                <>miljoen</>,
                <>minder</>,
                <>misdaad</>,
                <>mislukt</>,
                <>mistig</>,
                <>mixer</>,
                <>moeilijk</>,
                <>monster</>,
                <>morgen</>,
                <>mosterd</>,
                <>naartoe</>,
                <>neerviel</>,
                <>neiging</>,
                <>niemand</>,
                <>noodlot</>,
                <>normaal</>,
                <>ochtend</>,
                <>oerwoud</>,
                <>ofschoon</>,
                <>ofwel</>,
                <>omdat</>,
                <>omgaan</>,
                <>omgaat</>,
                <>omgang</>,
                <>omheen</>,
                <>omhoog</>,
                <>omlaag</>,
                <>omvang</>,
                <>omver</>,
                <>ondier</>,
                <>onheil</>,
                <>onrein</>,
                <>onrust</>,
                <>onschuld</>,
                <>ontbijt</>,
                <>ontbood</>,
                <>ontdekt</>,
                <>ontgaan</>,
                <>onthoud</>,
                <>ontmoet</>,
                <>ontroerd</>,
                <>ontving</>,
                <>ontvoerd</>,
                <>ontzag</>,
                <>ontzet</>,
                <>onweer</>,
                <>onzin</>,
                <>oordeel</>,
                <>oorlog</>,
                <>oorzaak</>,
                <>opdat</>,
                <>opgaan</>,
                <>ophield</>,
                <>oproep</>,
                <>opzet</>,
                <>opzij</>,
                <>pakten</>,
                <>paling</>,
                <>parfum</>,
                <>partij</>,
                <>pastoor</>,
                <>persoon</>,
                <>pijnlijk</>,
                <>pinda</>,
                <>plezier</>,
                <>plukken</>,
                <>portier</>,
                <>potlood</>,
                <>prachtig</>,
                <>praten</>,
                <>prettig</>,
                <>prijzen</>,
                <>prima</>,
                <>raadsel</>,
                <>recept</>,
                <>redding</>,
                <>rendier</>,
                <>reukzin</>,
                <>richting</>,
                <>rijkdom</>,
                <>roerloos</>,
                <>rugzak</>,
                <>ruimte</>,
                <>sandaal</>,
                <>scheiding</>,
                <>schildpad</>,
                <>schooltijd</>,
                <>schoonheid</>,
                <>schoonzoon</>,
                <>schotel</>,
                <>schouder</>,
                <>schuldig</>,
                <>sierlijk</>,
                <>signaal</>,
                <>simpel</>,
                <>slapen</>,
                <>slordig</>,
                <>soldaat</>,
                <>Spanje</>,
                <>spannen</>,
                <>spanning</>,
                <>sparen</>,
                <>spatel</>,
                <>speelplaats</>,
                <>spelen</>,
                <>speelkaart</>,
                <>spiegel</>,
                <>spijker</>,
                <>spion</>,
                <>spiraal</>,
                <>spoedig</>,
                <>spontaan</>,
                <>spreken</>,
                <>springen</>,
                <>sprookje</>,
                <>spruitje</>,
                <>Staking</>,
                <>stappen</>,
                <>steunen</>,
                <>stevig</>,
                <>stierlijk</>,
                <>stijging</>,
                <>stilte</>,
                <>stoppen</>,
                <>stralen</>,
                <>strekken</>,
                <>streven</>,
                <>structuur</>,
                <>sturen</>,
                <>sultan</>,
                <>tachtig</>,
                <>taxi</>,
                <>tempo</>,
                <>tenzij</>,
                <>termijn</>,
                <>terwijl</>,
                <>tijding</>,
                <>toegang</>,
                <>totdat</>,
                <>treurig</>,
                <>tuinman</>,
                <>tuinpoort</>,
                <>tulband</>,
                <>twintig</>,
                <>uitgaan</>,
                <>uitgang</>,
                <>uiting</>,
                <>uitkomt</>,
                <>uitleg</>,
                <>uitvoer</>,
                <>uitweg</>,
                <>uitzag</>,
                <>uitzien</>,
                <>uitziet</>,
                <>vaartuig</>,
                <>vaarwel</>,
                <>vampier</>,
                <>vandaag</>,
                <>vandaan</>,
                <>vandaar</>,
                <>vandoor</>,
                <>vanwaar</>,
                <>veerman</>,
                <>veertien</>,
                <>veertig</>,
                <>veldmuis</>,
                <>verder</>,
                <>verhaal</>,
                <>verliefd</>,
                <>versie</>,
                <>verslag</>,
                <>vesting</>,
                <>vijftien</>,
                <>vijftig</>,
                <>vochtig</>,
                <>voerman</>,
                <>voetbal</>,
                <>voldaan</>,
                <>voldoen</>,
                <>volmaakt</>,
                <>voorbeeld</>,
                <>voorbij</>,
                <>voordat</>,
                <>voordeel</>,
                <>voordeur</>,
                <>voorgoed</>,
                <>voorheen</>,
                <>voorhoofd</>,
                <>voorjaar</>,
                <>voorkeur</>,
                <>voorkomt</>,
                <>voornaam</>,
                <>voorschijn</>,
                <>voorval</>,
                <>voorzien</>,
                <>vrolijk</>,
                <>waanzin</>,
                <>waarbij</>,
                <>waardoor</>,
                <>waarheen</>,
                <>waarheid</>,
                <>waarlijk</>,
                <>waarmee</>,
                <>waartoe</>,
                <>waarvan</>,
                <>waarvoor</>,
                <>wachten</>,
                <>walvis</>,
                <>wanhoop</>,
                <>weerzien</>,
                <>weeshuis</>,
                <>welkom</>,
                <>wensen</>,
                <>werking</>,
                <>wijngaard</>,
                <>wijsheid</>,
                <>wimper</>,
                <>windhoek</>,
                <>winkel</>,
                <>winter</>,
                <>woestijn</>,
                <>woning</>,
                <>zakdoek</>,
                <>zakgeld</>,
                <>Zeeland</>,
                <>zeldzaam</>,
                <>zestien</>,
                <>zestig</>,
                <>zondaar</>,
                <>zondag</>,
            ],
            pickRandom: 10,
            syllables: 20,
            syllablesFactor: [0.6, 2.0],
        }
    },
    exercise8: {
        NL: {
            text: [
                <>aankomen</>,
                <>aansteker</>,
                <>aantallen</>,
                <>aanwijsblad</>,
                <>aanwijzen</>,
                <>aardappel</>,
                <>aardbeien</>,
                <>achterstand</>,
                <>achteruit</>,
                <>advocaat</>,
                <>afdroogdoek</>,
                <>afdruiprek</>,
                <>aftelrijm</>,
                <>afvalbak</>,
                <>afvoerbuis</>,
                <>afwassen</>,
                <>agenda</>,
                <>badhanddoek</>,
                <>badjassen</>,
                <>badkamer</>,
                <>bekenden</>,
                <>belangrijk</>,
                <>beroepen</>,
                <>beschrijven</>,
                <>betalen</>,
                <>bewaken</>,
                <>bezoeken</>,
                <>bikini</>,
                <>binnenband</>,
                <>bloempotten</>,
                <>boekenkast</>,
                <>boerderij</>,
                <>boodschappen</>,
                <>boomstammen</>,
                <>boomtakken</>,
                <>boterham</>,
                <>bovenkant</>,
                <>brandweerman</>,
                <>brandweerslang</>,
                <>broodtrommel</>,
                <>computer</>,
                <>controleur</>,
                <>dagverblijf</>,
                <>dakgootrand</>,
                <>dierentuin</>,
                <>dobbelsteen</>,
                <>donderdag</>,
                <>doorgeven</>,
                <>doorschuifkast</>,
                <>eigenwijs</>,
                <>etage</>,
                <>familie</>,
                <>filmtoestel</>,
                <>fotograaf</>,
                <>garage</>,
                <>geboorte</>,
                <>geduldig</>,
                <>getallen</>,
                <>gevaarlijk</>,
                <>gevoelig</>,
                <>gewoonlijk</>,
                <>gisteren</>,
                <>hagelslag</>,
                <>handeling</>,
                <>handwerkmand</>,
                <>haringen</>,
                <>hetzelfde</>,
                <>hoofdstukken</>,
                <>houthakker</>,
                <>huppelen</>,
                <>inmaakglas</>,
                <>intussen</>,
                <>kalender</>,
                <>karakter</>,
                <>kerstkaarten</>,
                <>kinderen</>,
                <>kleurpotlood</>,
                <>komkommer</>,
                <>koningen</>,
                <>krentenbrood</>,
                <>krokodil</>,
                <>kwartetten</>,
                <>leerkrachten</>,
                <>luchtaanval</>,
                <>luchtpostbrief</>,
                <>makelaar</>,
                <>makkelijk</>,
                <>minister</>,
                <>modellen</>,
                <>motorrace</>,
                <>nadenken</>,
                <>natuurlijk</>,
                <>nieuwjaarsdag</>,
                <>oefenen</>,
                <>oktober</>,
                <>omgekeerd</>,
                <>onderwijs</>,
                <>onderzoek</>,
                <>ongeluk</>,
                <>ongerust</>,
                <>ontdekken</>,
                <>onzichtbaar</>,
                <>oorbellen</>,
                <>opbergkast</>,
                <>operatie</>,
                <>ophaalbrug</>,
                <>ophaaldienst</>,
                <>opklapbed</>,
                <>opname</>,
                <>oppasser</>,
                <>opslagplaats</>,
                <>optimaal</>,
                <>paashaasje</>,
                <>papegaai</>,
                <>paraplu</>,
                <>parkeren</>,
                <>politie</>,
                <>poppenhuis</>,
                <>postkantoor</>,
                <>postzegel</>,
                <>potloden</>,
                <>potloodpunt</>,
                <>prentenboek</>,
                <>proberen</>,
                <>puzzelen</>,
                <>pyjama</>,
                <>receptie</>,
                <>relatie</>,
                <>rietstengel</>,
                <>rijbewijs</>,
                <>schilderen</>,
                <>schoenpoetsdoos</>,
                <>schoonmaken</>,
                <>schoonmoeder</>,
                <>slaapkamer</>,
                <>slaapwandel</>,
                <>smakelijk</>,
                <>sneeuwballen</>,
                <>spaarvarken</>,
                <>speelgoedgids</>,
                <>spinnenweb</>,
                <>spoorwegnet</>,
                <>sportschoenen</>,
                <>stofzuiger</>,
                <>storingen</>,
                <>supermarkt</>,
                <>tandpasta</>,
                <>tegelijk</>,
                <>tekenen</>,
                <>telefoon</>,
                <>teletekst</>,
                <>tomaten</>,
                <>traangasbom</>,
                <>treindiefstal</>,
                <>tweelingen</>,
                <>uithangbord</>,
                <>uitkijken</>,
                <>uitkijkpost</>,
                <>uitlaatklep</>,
                <>uitsluitend</>,
                <>vakantie</>,
                <>vergeten</>,
                <>verjaardag</>,
                <>verkleden</>,
                <>verkoper</>,
                <>verkouden</>,
                <>verpleging</>,
                <>vertellen</>,
                <>vervelend</>,
                <>verwarming</>,
                <>verwerking</>,
                <>vishengels</>,
                <>visnetten</>,
                <>voetballen</>,
                <>voetbalschoen</>,
                <>voetbalveld</>,
                <>voetgangers</>,
                <>voetsporen</>,
                <>voetstappen</>,
                <>volgende</>,
                <>voordeurbel</>,
                <>voorleesboek</>,
                <>voorlezen</>,
                <>voorwerpen</>,
                <>vulpeninkt</>,
                <>vulpennen</>,
                <>wandeling</>,
                <>wastafel</>,
                <>wervelwind</>,
                <>wijsvinger</>,
                <>winkelen</>,
                <>woningen</>,
                <>woordenschat</>,
                <>zebrapad</>,
                <>ziekenhuis</>,
                <>zonnebril</>,
                <>zwangerschap</>,
            ],
            pickRandom: 10,
            syllables: 30,
            syllablesFactor: [0.6, 2.0],
        }
    },
    exercise9: {
        NL: {
            text: [
                <>aangewezen</>,
                <>aankoopbedrag</>,
                <>aantekening</>,
                <>aantrekkelijk</>,
                <>aanvankelijk</>,
                <>aardappelteelt</>,
                <>aardedonker</>,
                <>abdicatie</>,
                <>aberratie</>,
                <>abstinentie</>,
                <>absurdisme</>,
                <>academisch</>,
                <>accidenteel</>,
                <>accordeon</>,
                <>accupunctuur</>,
                <>achterdochtig</>,
                <>achtervoegsel</>,
                <>achttienjarig</>,
                <>acteertalent</>,
                <>actieveling</>,
                <>ad hominem</>,
                <>adamskostuum</>,
                <>ademhaling</>,
                <>adjudant-chef</>,
                <>adverteerder</>,
                <>affirmatief</>,
                <>afgeborsteld</>,
                <>afgrijselijk</>,
                <>afhankelijk</>,
                <>afschuwelijk</>,
                <>afvallingskoers</>,
                <>afvallingsrace</>,
                <>afwisselend</>,
                <>afzichtelijk</>,
                <>afzonderlijk</>,
                <>alcoholvrij</>,
                <>allejezus</>,
                <>allemansvriend</>,
                <>alleseter</>,
                <>alleskunner</>,
                <>allesweter</>,
                <>alternatief</>,
                <>ambassade</>,
                <>ambassadeur</>,
                <>Amerikaan</>,
                <>analfabeet</>,
                <>anijslikeur</>,
                <>antiekwinkel</>,
                <>antipode</>,
                <>antropomorf</>,
                <>Antwerpenaar</>,
                <>apotheker</>,
                <>appelsienhuid</>,
                <>appelsiensap</>,
                <>appliqueren</>,
                <>aquarium</>,
                <>arbeidsklimaat</>,
                <>armageddon</>,
                <>artsenpraktijk</>,
                <>assistente</>,
                <>aubergine</>,
                <>audioloog</>,
                <>autobouwer</>,
                <>autorijschool</>,
                <>autozetel</>,
                <>avocado</>,
                <>avondeten</>,
                <>avondgebed</>,
                <>avondkleding</>,
                <>avondstudie</>,
                <>avonturier</>,
                <>bagagerek</>,
                <>ballerina</>,
                <>balletdanser</>,
                <>balletleraar</>,
                <>ballonvaarder</>,
                <>bananenschil</>,
                <>banketbakker</>,
                <>barokschilder</>,
                <>bedankbriefje</>,
                <>bedrijfsauto</>,
                <>beenbeschermer</>,
                <>beenhouwerij</>,
                <>begeleider</>,
                <>behangpapier</>,
                <>bejaardenhulp</>,
                <>bel-etage</>,
                <>belastingbrief</>,
                <>belgicisme</>,
                <>belladonna</>,
                <>belofteteam</>,
                <>beloftevol</>,
                <>benedictijn</>,
                <>bergbewoner</>,
                <>berichtgever</>,
                <>beroepsgericht</>,
                <>beschermengel</>,
                <>beschermingswal</>,
                <>besnuffelen</>,
                <>bewaarengel</>,
                <>bewondering</>,
                <>bezemwagen</>,
                <>bezettingstijd</>,
                <>bibliofiel</>,
                <>bibliotheek</>,
                <>bijzonderheid</>,
                <>blaasinstrument</>,
                <>blessuretijd</>,
                <>blikopener</>,
                <>bloedtransfusie</>,
                <>bloemenwinkel</>,
                <>bloody mary</>,
                <>boekverkoper</>,
                <>bondsvoorzitter</>,
                <>bosbessensap</>,
                <>bovenlichaam</>,
                <>bovenraampje</>,
                <>brandweerwagen</>,
                <>brave hendrik</>,
                <>brokkenmaker</>,
                <>broodbakkerij</>,
                <>bruggenbouwer</>,
                <>buitenlander</>,
                <>burgemeester</>,
                <>burgeroorlog</>,
                <>cadeauwinkel</>,
                <>caipirinha</>,
                <>calamares</>,
                <>calculator</>,
                <>caleidoscoop</>,
                <>caloriearm</>,
                <>calorierijk</>,
                <>cappuccino</>,
                <>categoriek</>,
                <>cellulitis</>,
                <>ceremonieel</>,
                <>charcuterie</>,
                <>chateaubriand</>,
                <>chocolade</>,
                <>chocopasta</>,
                <>circusartiest</>,
                <>circusnummer</>,
                <>cirkelvormig</>,
                <>clementine</>,
                <>combinatie</>,
                <>commanditair</>,
                <>commentator</>,
                <>commissaris</>,
                <>communicant</>,
                <>comparatief</>,
                <>compileren</>,
                <>computertijd</>,
                <>confectiepak</>,
                <>confidentie</>,
                <>confiserie</>,
                <>conservatief</>,
                <>contactpersoon</>,
                <>contraceptie</>,
                <>correspondent</>,
                <>crediteren</>,
                <>cuba libre</>,
                <>decennium</>,
                <>declasseren</>,
                <>deconfiture</>,
                <>depanneren</>,
                <>derdeklasser</>,
                <>dertienjarig</>,
                <>dertigjarig</>,
                <>detailhandel</>,
                <>detective</>,
                <>dialyse</>,
                <>dienst na verkoop</>,
                <>dierenwinkel</>,
                <>dijenkletser</>,
                <>dinosaurus</>,
                <>dinsdagavond</>,
                <>discussiegroep</>,
                <>doelgerichtheid</>,
                <>doktersbezoek</>,
                <>dokterspraktijk</>,
                <>doktersvoorschrift</>,
                <>dolenthousiast</>,
                <>donkergekleurd</>,
                <>donkerharig</>,
                <>donkerkleurig</>,
                <>doorbladeren</>,
                <>doorgedreven</>,
                <>doorgewinterd</>,
                <>drogisterij</>,
                <>druivensuiker</>,
                <>druktemaker</>,
                <>dubbeldekker</>,
                <>dubbelportret</>,
                <>dynamica</>,
                <>echtgenote</>,
                <>ecologist</>,
                <>eenjarige</>,
                <>eerbiedwaardig</>,
                <>eersteklasser</>,
                <>Egyptenaar</>,
                <>EHBO</>,
                <>eierdopje</>,
                <>einzelgänger</>,
                <>eliteschool</>,
                <>emotioneel</>,
                <>energiedrank</>,
                <>enveloppen</>,
                <>etnografie</>,
                <>Europeaan</>,
                <>executeur</>,
                <>familiehulp</>,
                <>familielid</>,
                <>fietsenmaker</>,
                <>filmproducent</>,
                <>filterkoffie</>,
                <>formatteren</>,
                <>fortissimo</>,
                <>functionaris</>,
                <>geboortegolf</>,
                <>geboortejaar</>,
                <>geestelijke</>,
                <>gelijkmaker</>,
                <>gemeentehuis</>,
                <>gendarmerie</>,
                <>generatie</>,
                <>geroezemoes</>,
                <>gevangene</>,
                <>gevangenis</>,
                <>geveltoerist</>,
                <>gezagvoerder</>,
                <>gezelschapsdier</>,
                <>gouvernement</>,
                <>granaatappel</>,
                <>grappenmaker</>,
                <>groenteschotel</>,
                <>grondgebonden</>,
                <>grootwarenhuis</>,
                <>gymnasium</>,
                <>harmonica</>,
                <>helikopter</>,
                <>hermafrodiet</>,
                <>hongerlijder</>,
                <>houtgravure</>,
                <>huisartspraktijk</>,
                <>hulpprogramma</>,
                <>illegitiem</>,
                <>in goeden doen</>,
                <>indiaantje</>,
                <>industrieel</>,
                <>informateur</>,
                <>internettijd</>,
                <>interruptie</>,
                <>Italiaan</>,
                <>jaargetijde</>,
                <>kalfstournedos</>,
                <>kameleon</>,
                <>kanariekooi</>,
                <>kaneelappel</>,
                <>kanonskogel</>,
                <>karbonade</>,
                <>Karikatuur</>,
                <>kinderkopje</>,
                <>kinderportret</>,
                <>klavecimbel</>,
                <>koeienletters</>,
                <>koelkastlampje</>,
                <>kofﬁekopje</>,
                <>kofﬁemolen</>,
                <>komkommersla</>,
                <>koperdiepdruk</>,
                <>leverancier</>,
                <>lilliputter</>,
                <>limonade</>,
                <>loopbaanplanning</>,
                <>luciferdoos</>,
                <>Luxemburger</>,
                <>mandoline</>,
                <>marchanderen</>,
                <>mecanicien</>,
                <>medewerker</>,
                <>medewerkster</>,
                <>memoriaal</>,
                <>mensenkennis</>,
                <>middageten</>,
                <>millennium</>,
                <>minimumloon</>,
                <>misdaadroman</>,
                <>mousseline</>,
                <>muziekwinkel</>,
                <>nectarine</>,
                <>nivelleren</>,
                <>offsetdrukwerk</>,
                <>onderdrukker</>,
                <>onderwijzer</>,
                <>ongezouten</>,
                <>oorbeschermer</>,
                <>opdrachtgever</>,
                <>openingskoers</>,
                <>openingstijd</>,
                <>oplaadstation</>,
                <>oploskoffie</>,
                <>opvangcentrum</>,
                <>ovenschotel</>,
                <>overdrijven</>,
                <>overloper</>,
                <>overmorgen</>,
                <>overtuiging</>,
                <>overvaren</>,
                <>pathologie</>,
                <>patisserie</>,
                <>patriarchaal</>,
                <>pejoratief</>,
                <>periode</>,
                <>pico bello</>,
                <>plagiëren</>,
                <>plannenmaker</>,
                <>poppenspeler</>,
                <>portretteren</>,
                <>potsenmaker</>,
                <>prijsuitreiking</>,
                <>puntenslijper</>,
                <>ratatouille</>,
                <>rattenvanger</>,
                <>residentie</>,
                <>reutemeteut</>,
                <>rondhuppelen</>,
                <>salamander</>,
                <>samenzweerder</>,
                <>schatbewaarder</>,
                <>schijnheiligheid</>,
                <>schokbestendig</>,
                <>schroevendraaier</>,
                <>Senegalees</>,
                <>sinaasappel</>,
                <>sint-jakobsschelp</>,
                <>sluipmoordenaar</>,
                <>stationsroman</>,
                <>stekelvarken</>,
                <>stemmingmaker</>,
                <>sterrenkijker</>,
                <>stoomstrijkijzer</>,
                <>studentenraad</>,
                <>supporteren</>,
                <>synthesizer</>,
                <>tandenborstel</>,
                <>tautologie</>,
                <>tegelzetter</>,
                <>theelichthouder</>,
                <>therapeute</>,
                <>tierelantijn</>,
                <>titularis</>,
                <>transpositie</>,
                <>trappistenbier</>,
                <>twintigste-eeuws</>,
                <>uitroepteken</>,
                <>uitzondering</>,
                <>ultimatum</>,
                <>Universum</>,
                <>vergadering</>,
                <>verloskunde</>,
                <>verrekijker</>,
                <>verschuiving</>,
                <>verzamelaar</>,
                <>volksgezondheid</>,
                <>voluptueus</>,
                <>voorbijganger</>,
                <>vruchtenplukker</>,
                <>wasmachine</>,
                <>waterdrager</>,
                <>werkzaamheden</>,
                <>wijnproducent</>,
                <>winterkwartier</>,
                <>winterverblijf</>,
                <>zegezeker</>,
                <>zeveraarster</>,
                <>zoetwatervis</>,
                <>zorgverlener</>,
            ],
            pickRandom: 10,
            syllables: 40,
            syllablesFactor: [0.6, 2.0],
        }
    },
    exercise10: {
        NL: {
            text: [
                <>
                    Vertel gedurende 1 minuut wat je gisteren gedaan hebt. Denk
                    hierbij aan volgende zaken:
                </>,
                <>
                    • Wat heb je gegeten? <br />
                    • Wie heb je ontmoet? <br />
                    • Wat heb je in de krant gelezen, of op televise/radio gehoord?
                </>,
            ],
            pickRandom: false,
            syllables: 220,
            syllablesFactor: [0.2, 5.0],
        }
    },
    exercise11: {
        NL: {
            text: [
                <>
                    Vertel gedurende 1 minuut over je laatste reis die je
                    gemaakt hebt. Denk hierbij aan volgende zaken:
                </>,
                <>
                    • Wat was de bestemming? <br />
                    • Wat heb je bezocht? <br />
                    • Wie was er met je mee? <br />
                    • Wat vond je het leukste? <br />
                    • Wat vond je het minst leuke?
                </>,
            ],
            pickRandom: false,
            syllables: 220,
            syllablesFactor: [0.2, 5.0],
        }
    },
};

export const infos = {
    info1: [<>{/* Intro */}</>],
    info2: [<>{/* Houding */}</>],
    info3: [
        <>
            Deze opdracht bestaat erin om de 10 woorden (of beter “uitroepen”)
            onderaan deze pagina voor te lezen. Doe dit op een rustige manier.
        </>
    ],
    info4: [
        <>
            Deze opdracht bestaat erin om de 10 woorden onderaan deze pagina
            voor te lezen. Doe dit op een rustige manier.
        </>
    ],
    info5: [
        <>
            Deze opdracht bestaat erin om de 10 woorden onderaan deze pagina
            voor te lezen. Doe dit op een rustige manier.
        </>
    ],
    info6: [
        <>
            Deze opdracht bestaat erin om de 10 woorden onderaan deze pagina
            voor te lezen. Doe dit op een rustige manier.
        </>
    ],
    info7: [
        <>
            Deze opdracht bestaat erin om de 10 woorden onderaan deze pagina
            voor te lezen. Doe dit op een rustige manier.
        </>
    ],
    info8: [
        <>
            Deze opdracht bestaat erin om de 10 woorden onderaan deze pagina
            voor te lezen. Doe dit op een rustige manier.
        </>
    ],
    info9: [
        <>
            Deze opdracht bestaat erin om de 10 woorden onderaan deze pagina
            voor te lezen. Doe dit op een rustige manier.
        </>
    ],
    info10: [
        <>
            Doe de spreekopdracht onderaan deze pagina op een rustige manier.
        </>
    ],
    info11: [
        <>
            Doe de spreekopdracht onderaan deze pagina op een rustige manier.
        </>
    ],
};

export const exercisesData = [
    {
        /* Null */
    },
    {
        /* Introduction */
    },
    {
        /*Houding en Ontspanning*/
    },
    {
        exerciseNumber: 3,
        title: 'Uitroepen',
        titleExercise: 'Exercise: Uitroepen'
    },
    {
        exerciseNumber: 4,
        title: 'Één lettergreep (gemakkelijk)',
        titleExercise: 'Exercise: één lettergreep (gemakkelijk)'
    },
    {
        exerciseNumber: 5,
        title: 'Één lettergreep (moeilijk)',
        titleExercise: 'Exercise: één lettergreep (moeilijk)'
    },
    {
        exerciseNumber: 6,
        title: 'Twee lettergrepen (gemakkelijk)',
        titleExercise: 'Exercise: twee lettergrepen (gemakkelijk)'
    },
    {
        exerciseNumber: 7,
        title: 'Twee lettergrepen (moeilijk)',
        titleExercise: 'Exercise: twee lettergrepen (moeilijk)'
    },
    {
        exerciseNumber: 8,
        title: 'Drie lettergrepen',
        titleExercise: 'Exercise: drie lettergrepen'
    },
    {
        exerciseNumber: 9,
        title: 'Vier lettergrepen',
        titleExercise: 'Exercise: vier lettergrepen',
    },
    {
        exerciseNumber: 10,
        title: 'Hoe was je dag?',
        titleExercise: 'Exercise: generalisatie: hoe was je dag?',
    },
    {
        exerciseNumber: 11,
        title: 'Hoe was je laaste reis?',
        titleExercise: 'Exercise: generalisatie: hoe was je laaste reis?',
    },
];
