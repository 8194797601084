import React from 'react';
import { Typography } from '@material-ui/core/';

const style = {
    Loading: {
        fontSize: '70px',
        textAlign: 'center',
        color: 'white',
    }
};

const LoadingPage = () => (
    <div className="is-flex">
        <Typography component="h1" style={style.Loading}>
            Loading
        </Typography>
    </div>
);

export default LoadingPage
