import React from 'react';
import PropTypes from 'prop-types';

import { Grid, InputAdornment, TextField, Paper } from '@material-ui/core/';

class ParkiParamsEditor extends React.Component {
    static propTypes = {
        parkiParams: PropTypes.shape({
            minVolume: PropTypes.number.isRequired,
            maxPitch: PropTypes.number.isRequired,
            maxPace: PropTypes.number.isRequired,
        }).isRequired,
        setUserState: PropTypes.func.isRequired,
    };

    scales = {
        minVolume: 100,
        maxPitch: 1,
        maxPace: 60,
    };

    changeParam = (param, scaledValue) => {
        const value = scaledValue / this.scales[param];
        const parkiParams = {
            ...this.props.parkiParams,
            [param]: value,
        };
        this.props.setUserState({ parkiParams });
    };

    handleChange = event => {
        const { name: param, value: scaledValue } = event.target;
        this.changeParam(param, Number(scaledValue));
    };

    ParamInput = ({ param, label, unit, step, helperText }) => {
        const value = Math.round(
            this.scales[param] * this.props.parkiParams[param]
        );
        const startAdornment = (
            <InputAdornment position="start">
                {param.startsWith('min') ? 'Min.' : 'Max.'}
            </InputAdornment>
        );
        const endAdornment = (
            <InputAdornment position="end">
                {unit}
            </InputAdornment>
        );
        return (
            <Grid item xs={12} sm={4} styles={{ textAlign: 'center' }}>
                <TextField
                    name={param}
                    label={label}
                    style={{ width: '100%' }}
                    value={value}
                    InputProps={{
                        startAdornment: startAdornment,
                        endAdornment: endAdornment,
                        inputProps: {
                            pattern: '\\d*',
                            min: 0,
                            step: step,
                        },
                    }}
                    helperText={helperText}
                    onChange={this.handleChange}
                    type="number"
                    variant="outlined"
                />
            </Grid>
        );
    };

    render () {
        return (
            <Paper
                elevation={1}
                className="customPaper"
            >
                <Grid container spacing={24}>
                    <this.ParamInput
                        param="minVolume"
                        label="Volume"
                        unit="%"
                        step={1}
                    />
                    <this.ParamInput
                        param="maxPitch"
                        label="Toonhoogte"
                        unit="Hz"
                        step={10}
                    />
                    <this.ParamInput
                        param="maxPace"
                        label="Snelheid"
                        unit="lg/min."
                        step={6}
                        helperText="lettergrepen per minuut"
                    />
                </Grid>
            </Paper>
        );
    }
}

export default ParkiParamsEditor;