import Rebase from "re-base";
import firebase from "firebase";

const productionDomains = [
    'speech.winston-analytics.com',
    'vrt.winston-analytics.com',
    'parki.winston-analytics.com',
    'staging-prod.winston-analytics.com',
    'localhost.prod',
    'winston-analytics.web.app',
    'winston-analytics.firebaseapp.com',
];

let firebaseApp;
if (productionDomains.includes(window.location.hostname.toLowerCase())) {
    firebaseApp = firebase.initializeApp({
        apiKey: "AIzaSyBF2x8U6HY-tegZm2Yvhj1YSJGRajk1_lI",
        authDomain: "winston-analytics.firebaseapp.com",
        databaseURL: "https://winston-analytics.firebaseio.com",
        projectId: "winston-analytics",
        storageBucket: "winston-analytics.appspot.com",
        messagingSenderId: "677626188746",
        appId: "1:677626188746:web:07f777608eff506daf3533"
    });
} else {
    firebaseApp = firebase.initializeApp({
        apiKey: "AIzaSyBRwNl8AHkxITaETPLx-lefAIYHSVtWdaA",
        authDomain: "winston-analytics-staging.firebaseapp.com",
        databaseURL: "https://winston-analytics-staging.firebaseio.com",
        projectId: "winston-analytics-staging",
        storageBucket: "winston-analytics-staging.appspot.com",
        messagingSenderId: "818395138716",
        appId: "1:818395138716:web:69718950261ed3c7f67b59",
    });
}

const rebase = Rebase.createClass(firebaseApp.database());

// This is a named export
export { firebaseApp, rebase };
