export const speechType = (speechType) => {
    switch (speechType) {
        case 'celebration speech':
            return {
                title: "Focus on the others",
                highlight: "You should be like a copper wire: an energy conductor",
                summary: "Important when celebrating someone or something is to bring your speaking energy from you towards the subject. Don't put yourself in the center of the attention, but focus on the celebrated party, so their achievements will be remembered by the audience.",
                tips: [
                    "When using humour, put deprecation on you and make them the hero of your story.",
                    "Use storytelling to convey your message: anecdotes are the foundation of a good celebration.",
                    "Keep it short and make use of the audience's short attention span.",
                    "Don't rush through your speech: let your audience enjoy the moment.",
                ],
            };
        case 'elevator pitch':
            return {
                title: "Tailor your pitch",
                highlight: "You don't sell sausages to your banker",
                summary: "An elevator pitch contains typically following 5 elements: who you are (the team), the problem you want to tackle, the solution you propose (incl. which market), what you already have reached, and what you need from them. As the pitch is typically very short, be sure you know your audience and tailor your pitch to them, and if needed, omit one of the 5 elements if you think your audience don't care.",
                tips: [
                    "Practise is key: the shorter the pitch, the better you should know it by hearth.",
                    "If you have competition, this is a good sign: it means your market is ready.",
                    "Dear to dream: what is your ultimate goal?",
                ],
            };
        case 'informative speech':
            return {
                title: "Focus on your main message",
                highlight: "Informative ≠ boring",
                summary: "Even when you give an informative speech, you should have a main message you want to convey. Enforce that message with a combination of facts, anecdotes and humour.",
                tips: [
                    "Try to limit the body of your speech to three topics: that's the ideal amount of things to remember by your audience.",
                    "The more difficult your topic, the slower you need to speak.",
                    "Know your audience and adjust your speech to their level of expertise.",
                    "Don't try to tell everything, but refer to a report with additional information.",
                ],
            };
        case 'inspirational speech':
            return {
                title: "Delivery is key",
                highlight: "You can't inspire someone in their sleep",
                summary: "Important when giving an inspirational speech is to have a clear purpose, backed by a personal story. However, if you speech delivery is quite dull, your audience will just fall asleep. Try to excel in all dimensions: use a varied pitch, don't speak too fast, use a lot of pauses, etc.",
                tips: [
                    "It is hard to focus on all dimensions, like pitch, pace etc., simultaneously. Try to master first one dimension and move then to the next one.",
                    "Try to limit your speech to one main message and make sure to have a call to action at the end of your speech.",
                    "Play on emotions: bring your audience in the right mood through your story, so they are ready to be inspired.",
                    "Don't read your speech: when not using notes, the audience will perceive you as being more authentic.",
                ],
            };
        case 'sales pitch':
            return {
                title: "Use your voice to close deals",
                highlight: "It's not only what you say, but how you say it",
                summary: "If you use an energetic voice (varied pitch and volume), you can transfer your message more easily to your prospects. By preparing well, you can build confidence in your voice. Try, however, to not rattle off a memorised story, but engage in a dialog to adjust your story along the way.",
                tips: [
                    "Speed up when you are talking about things the prospects are excited about: you will sound more enthusiastic.",
                    "Slow down when you are making the offer: it will help your prospects remember.",
                    "Pause after you offer to let your prospects react on that.",
                ],
            };
        case 'TED talk':
            return {
                title: "Inspire your audience",
                highlight: "May the Force be with you",
                summary: "One common aspect for all popular TED, TEDx or TED like talks, is inspiration. Whether you are presenting a new idea, new product, new insight, the audience needs to feel they can help you change the world. Explain why your story is relevant!",
                tips: [
                    "It is hard to focus on all dimensions, like pitch, pace etc., simultaneously. Try to master first one dimension and move then to the next one.",
                    "Try to limit your speech to one main message and make sure to have a call to action at the end of your speech.",
                    "Play on emotions: bring your audience in the right mood through your story, so they are ready to be inspired.",
                    "Don't read your speech: when not using notes, the audience will perceive you as being more authentic.",
                ],
            };
        case 'Toastmasters story':
            return {
                title: "The standard for competent communication",
                highlight: "Storytelling rocks",
                summary: "Distinguished Toastmaster, world champions tell their story with a high pitch variation, uncomfortable long pauzes and a constant changing speaking pace. This might come across as American and theatrical to a European crowd but remember that life starts at the end of your comfort zone. So why not try this once?",
                tips: [
                    "What works in Toastmasters may not work outside your club.",
                    "It's sometimes more important to focus on the goals of your path than to beat Winston.",
                    "Use the club's speaking slots as a sandbox and experiment together with Winston.",
                ],
            };
        default:
            return;
    }
};

export const pace = (pace, range) => {
    if (pace < range[0]) {
        return {
            title: "Slow speed of delivery",
            highlight: "Did we forget to wind you up before speaking?",
            summary: "A slow speaking pace may have some advantages but the pitfall is... the listener's mind starts to wander and will not come back. If you're telling a story, you can speed up when you're describing the context. If you're hesitant because you don't know the language or script, there is only one thing you have to do: practice, practice, practice.",
            shortSummary: "There is a thin line between sounding boring and speaking slow.",
            tips: [
                "Speed up to keep your audience awake.",
                "Is your content too difficult? This can reduce the pace.",
                "Convey your pace through emotions.",
                "Speak for the ear, don't write for the eye.",
                "Can you slow down sections to let the audience know these are important?",
            ],
            exercises: null,
            exercisesUrl: null,
        };
    } else if (pace >= range[0] && pace <= range[1]) {
        return {
            title: "Good speed of delivery",
            highlight: "Cicero would be proud",
            summary: "You have a great medium pace which is great to explain complex things. You are doing a great job! Try to sometimes vary your pace a bit to surprise your audience.",
            shortSummary: "There is a thin line between sounding boring and speaking slow.",
            tips: [
                "Emphasize your message by varying the pace during this single sentence.",
                "Exercise and exaggerate to see what your limits are.",
                "Shorter sentences are easier to pronounce.",
                "Speak for the ear, don't write for the eye.",
            ],
            exercises: null,
            exercisesUrl: null,
        };
    } else {
        return {
            title: "You speak fast, maybe even too fast.",
            highlight: "The Usain Bolt of speech has risen",
            summary: "To you, a speech is a sprint. This is fine and a great approach for the inspirator you want to be. Don't forget however that a consistent high speed has disadvantages. Have mercy on the audience and take a break yourself.",
            shortSummary: "Running fast is cool, speaking fast is difficult to understand.",
            tips: [
                "To slow down your speaking, consciously take a breath before you begin.",
                "Remember to stop and breathe between ideas.",
                "Emphasize your message by varying the pace during this single sentence.",
                "Shorter sentences are easier to pronounce.",
                "How about speeding up when you are reciting, for example, a long list of items you don't care about individually, but which make an impact by their sheer number?",
            ],
            exercises: "Winston has some exercises for you to help you slow down!",
            exercisesUrl: "/speaktoofast/",
        };
    }
};

export const pitch = (pitch, range) => {
    if (pitch < range[0]) {
        return {
            title: "Monotonous with little pitch variation",
            highlight: "Is that your voice or the refrigerator?",
            summary: "Warning! Your voice may put people to sleep. Go for some variation in your pitch, this adds colour to your presentation. Increasing your vocal range will pop melody in your speech. We all like rainbows and music in a speech, isn't it?",
            shortSummary: "A monotonous voice has a negative impact on your perceived confidence.",
            tips: [
                "Adjust your posture: look up, don't bend over and open up your arms.",
                "Before you utter a word, make sure your back is in a relaxed, vertical position. Your knees are slightly bent, your pelvis dropped and the crown of your head held up.",
                "Warm up your voice with tongue twisters and breathing exercises.",
                "Let us hear a question as a question and a statement as a statement.",
                "Use enough air to avoid sounding dull and lifeless.",
                "Hydrate your vocal chords: drink some water (not coffee!).",
                "On the inhale let your lower belly totally relax, filling it up with air like a balloon. Then on the exhale, let the air out, and let your belly flatten out until it's empty.",
                "It's unusual, even challenging, to speak in a monotone in a personal conversation. At its best, a presentation or speech is a personal conversation with many individuals at the same time. Imagine you will be speaking to your best friend in the back of the room.",
            ],
        };
    } else if (pitch >= range[0] && pitch <= range[1]) {
        return {
            title: "Good use of broad vocal range",
            highlight: "You owe an octave and you know how to use it",
            summary: "Your voice is like milk and honey. Some pitch variation adds vividness and colour to your presentation. Maybe there are moments in your speech when you can work even more with your voice to emphasize certain key points?",
            shortSummary: "The good news: you do have feeling for vocal variety within your varied pitch range.",
            tips: [
                "Exaggerate during practice so you know your limits on stage.",
                "Let us hear a question as a question and a statement as a statement.",
                "Use enough air to avoid sounding dull and lifeless.",
                "Read the sentence aloud and alter your pitch every time you read it: \"I'm not driving you to school today\".",
                "Take some deep breaths with your belly just before you start.",
                "When inhaling, expand your belly, not your chest.",
                "Yawn big to stretch your voice muscles.",
                "Roller coaster with hm-mm sound.",
            ],
        };
    } else {
        return {
            title: "Extreme variation in pitch",
            highlight: "Have you ever considered a career in opera?",
            summary: "You do know how to use your voice and are not afraid to do so. There surely is something like too much variation in pitch, it is called overacting. Or are you hyper nervous? Be aware: too much is never ok. Are you aware of your vocal punches? For the best results, apply some variation deliberately to the key points.",
            shortSummary: "Your vocal range is HhhuUUGGgeEEe. Remember that the dose of pitch variation can make the poison.",
            tips: [
                "Exaggerate during practice so you know your limits at the stage.",
                "Let us hear a question as a question and a statement as a statement.",
                "A good posture is the root of a warm voice.",
                "Inhale and exhale calmly to train the muscles of your voice to increase control.",
                "Reduce your nerves and be more calm gives you a balanced voice.",
                "Use enough air to avoid sounding dull and lifeless.",
            ],
        };
    }
};

export const pause = (pause, range) => {
    if (pause < range[0]) {
        return {
            title: "No long pauses",
            highlight: "You're a human machine gun, firing from all barrels",
            summary: "All humans need to breathe, you too. Don't forget to cool down from time to time. A pause is really needed to make it worth listening to. Elegant use of pauses is the speaker's highest grace. Whatwouldhappenifyouwouldskipthespacebetweenwordswhenreading?",
            shortSummary: "You don't use long pauses. Go easy on yourself and take a break and a breathe, the audience will love you for it.",
            tips: [
                "Be aware when you are running out of oxygen.",
                "Breath to remind yourself what you are going to say.",
                "Breath before you say something important.",
                "How would you feel to increase your pauses?",
                "When in doubt, pause for a moment longer.",
                "Pause takes 3 heartbeats.",
            ],
        };
    } else if (pause >= range[0] && pause <= range[1]) {
        return {
            title: "Well balanced use of pauses",
            highlight: "Meditate + Monk = You. You are the master of balance between breathing and reflection time",
            summary: "You're balancing between hesitant and deliberate speech and that's quite an achievement. The trick is to pause - breath - think, what you've seemed to master.",
            shortSummary: "You show mercy on the listener: a good balance between breathing and thinking provides us all with the right flow.",
            tips: [
                "Be comfortable with your text so you can think about intentional pauses.",
                "Are your pauses always deliberate?",
                "Edit your script like a film director: put [pause] before a key message and then apply.",
                "Make a point - pause - make another point.",
                "Use your pause to walk to another part of the stage.",
            ],
        };
    } else {
        return {
            title: "Long pauses",
            highlight: "Paradox alert! Are you the leader who dares to take his time or are you the minion who has no clue what he is telling?",
            summary: "Speaking starts with taking a pause. Literally: it shows you are in control. Figuratively: it gives you time to breathe and enables the audience a moment to digest your message. A potent pause at the right spot definitely marks your confidence as a speaker. A good pause can make a word a thousand times more impactful. On the other hand, peculiar pauses - on places where the audience doesn't expect a break - hint you're nervous. Although a well aimed pause is gold, too many breaks kill the flow. Try to be more fluent and only pause for breathing and to put emphasis on the key message.",
            shortSummary: "We are not sure if you are totally in control or nervous as hell. Are your long pauses deliberate?",
            tips: [
                "Is every pause deliberate? Does it help to convey your message?",
                "Proper preparation prevents hesitating.",
                "Be comfortable with your text so you can think about deliberate pauses.",
                "Speaker notes can help, if they only consist of keywords.",
                "Study the beginning and ending of your speech by heart.",
                "Practice with distance between you and your text, literally and figuratively.",
            ],
        };
    }
};

export const volume = (volume, range) => {
    if (volume < range[0]) {
        return {
            title: "Low energy",
            highlight: "If YOU are not passionate or energetic, why should WE care?",
            summary: "You seem to have had a very consistent volume, however too flat to be perfect. This doesn't mean you only whisper or shout. It implies you don't shift gears in volume. This undermines the power of your engine (your voice) to get to your destination (your message). If you're always too soft: you sound insecure. If you're always too loud: you're enervating. When you combine a change in volume with a change in rate or pitch, you'll definitely add interest and gain attention.",
            shortSummary: "No volume variation shows the audience you are not passionate. It kills their attention span.",
            tips: [
                "Leverage volume as a way to keep your audience engaged.",
                "High volume before a dramatic pause works perfectly.",
                "Sudden low volume before a dramatic pause works perfectly.",
                "Stand up straight, take a big breath, and use more air to carry the sound as you speak up.",
                "Exhale all the air in your lungs when you recite one phrase.",
                "Finish sentences strong.",
                "Adding volume variety (e.g. whispers, much louder, in between, etc.) can alleviate monotony.",
            ],
        };
    } else if (volume >= range[0] && volume <= range[1]) {
        return {
            title: "You know how to shift gears within volume",
            highlight: "Well, shake it up baby now. Twist and shout. You get the crowd crazy, just like The Beatles.",
            summary: "People who are passionate vary the volume with which they speak - whispering at appropriate points or rising to a crescendo to hammer home a point. This variety makes the speaker more dynamic and eliminates monotony. You do use volume as a way of keeping your audience engaged, but there is always some room for improvement.",
            shortSummary: "Your volume variation shows your passion, the audience loves this energy.",
            tips: [
                "Leverage volume as a way to keep your audience active.",
                "High volume before a dramatic pause works perfectly.",
                "Sudden low volume before a dramatic pause works perfectly.",
                "Use lots of words beginning with \"w\" or \"p\" to stretch the jaw muscles as you speak, like: \"Woo-Woe-War-Wow\".",
            ],
        };
    } else {
        return {
            title: "High energy by virtue of switching gears",
            highlight: "Enthusiasm is your middle name",
            summary: "Dozing off is not an option. You reanimate dead audience members with the energy of your voice alone. Make sure your gearbox (the voice) doesn't break down with all that acrobatics. Pamper your vocal cords or you get overrun by zombies.",
            shortSummary: "How to vary in speaking volume has no secret for you, the key is to use it when it matters most.",
            tips: [
                "Use your volume variations deliberately, right before or during the key message.",
                "High volume variation can be tiring for the audience, try to save it for when it matters most.",
                "Like every effect, when overused, it loses its power.",
                "Vibrant volume is not shouting. A powerful speaker is one who is relaxed and comfortable.",
            ],
        };
    }
};
