import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, SnackbarContent } from '@material-ui/core/';

class Result extends React.Component {
    static propTypes = {
        // From Router
        parkiParams: PropTypes.shape({
            minVolume: PropTypes.number.isRequired,
            maxPitch: PropTypes.number.isRequired,
            maxPace: PropTypes.number.isRequired,
        }).isRequired,
        // From index.js
        exerciseNumber: PropTypes.number.isRequired,
        // From RecordingExercise (parent) or its inheritors
        stats: PropTypes.object.isRequired,
        averageSyllables: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
    };

    failText = exerciseNumber => {
        const { stats, averageSyllables } = this.props;
        if (stats.syllable_count < averageSyllables * 0.8) {
            return (
                <>
                    Winston hoorde maar {stats.syllable_count} lettergrepen.
                    Hij had er {averageSyllables} verwacht. Als je de oefening
                    goed hebt gedaan, kan er iets fout zijn met de microfoon.
                    Om dit te verifiëren, ga naar het rapport via onderstaande
                    knop. Onderaan het rapport kun je je opname beluisteren.
                </>
            );
        } else {
            return (
                <>
                    Winston hoorde {stats.syllable_count} lettergrepen. Hij had
                    er maar {averageSyllables} verwacht. De oorzaak kan ruis of
                    achtergrondgeluid zijn, die foutief als lettergrepen werden
                    geteld. Om dit te verifiëren, ga naar het rapport via
                    onderstaande knop. Onderaan het rapport kun je je opname
                    beluisteren.
                </>
            );
        }
    };

    trafficLight = status => {
        const line = 2;
        const outerR = 25;
        const innerR = 18;
        const dy = 45;
        const topColor = !status ? 'red' : 'silver';
        const botColor = status ? 'limegreen' : 'silver';

        return (
            <svg
                width={2 * outerR + line + 1}
                height={2 * outerR + line + dy + 1}
            >
                <path
                    d={[
                        'M', line/2, outerR + line/2,
                        'a', outerR, outerR, 0, 0, 1, outerR, -outerR,
                        'a', outerR, outerR, 0, 0, 1, outerR, outerR,
                        'v', dy,
                        'a', outerR, outerR, 0, 0, 1, -outerR, outerR,
                        'a', outerR, outerR, 0, 0, 1, -outerR, -outerR,
                        'v', -dy,
                        'Z',
                    ].join(' ')}
                    fill="lightgrey"
                    stroke="black"
                    strokeWidth={line}
                />
                <circle
                    cx={outerR + line/2}
                    cy={outerR + line/2}
                    r={innerR}
                    fill={topColor}
                    stroke="black"
                    strokeWidth={line}
                />
                <circle
                    cx={outerR + line/2}
                    cy={outerR + line/2 + dy}
                    r={innerR}
                    fill={botColor}
                    stroke="black"
                    strokeWidth={line}
                />
            </svg>
        );
    };

    successText = exerciseNumber => {
        const { stats: {
            absolute_peak_whole: volume,
            absolute_pitch_whole: pitch,
            speaking_rate: pace,
        } }  = this.props;

        const volumeOK = volume >= this.props.parkiParams.minVolume;
        const pitchOK = pitch <= this.props.parkiParams.maxPitch;
        const paceOK = pace <= this.props.parkiParams.maxPace;

        const volumeFeedback = 'Je klonk nogal stil. Probeer luider te spreken.';
        const pitchFeedback = 'Je stem klonk nogal hoog. Probeer je stem constant laag te houden.';
        const paceFeedback = 'Je sprak nogal snel. Probeer trager te spreken.';

        return (
            <React.Fragment>
                <Grid item xs={4} style={{ textAlign: 'center' }}>
                    {this.trafficLight(volumeOK)}
                    <Typography variant="button">Luid</Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center' }}>
                    {this.trafficLight(pitchOK)}
                    <Typography variant="button">Laag</Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center' }}>
                    {this.trafficLight(paceOK)}
                    <Typography variant="button">Traag</Typography>
                </Grid>
                <Grid item xs={12}>
                    {(
                        volumeOK && pitchOK && paceOK
                    ) ? (
                        <React.Fragment>
                            <Typography gutterBottom>
                                Goed zo!
                            </Typography>
                            <Typography>
                                Ging de oefening vlot? Doe dan een andere
                                oefening. Indien niet, probeer gerust opnieuw.
                                Als je stem vermoeid is, dan wacht je best tot
                                morgen.
                            </Typography>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <React.Fragment>
                                {
                                    !volumeOK &&
                                    <Typography gutterBottom>
                                        {volumeFeedback}
                                    </Typography>
                                }
                                {
                                    !pitchOK &&
                                    <Typography gutterBottom>
                                        {pitchFeedback}
                                    </Typography>
                                }
                                {
                                    !paceOK &&
                                    <Typography gutterBottom>
                                        {paceFeedback}
                                    </Typography>
                                }
                            </React.Fragment>
                            <Typography>
                                Probeer deze oefening nog eens te doen. Als je
                                stem vermoeid is, probeer dan morgen eens
                                opnieuw.
                            </Typography>
                        </React.Fragment>
                    )}
                </Grid>
            </React.Fragment>
        );
    };

    render() {
        return this.props.type === 'fail' ? (
            <SnackbarContent
                className="is-warning-snackbar"
                message={this.failText(this.props.exerciseNumber)}
            />
        ) : this.props.type === 'success' ? (
            this.successText(this.props.exerciseNumber)
        ) : null;
    }
}

export default Result;
