import React from 'react';
import DateTimePicker from 'react-datetime-picker';
import PropTypes from 'prop-types';
import {
    Typography,
    FormControl,
    MenuItem,
    Select,
    Grid,
    SnackbarContent,
} from '@material-ui/core/';
import SpeechComments from './SpeechComments';

import {
    debounce,
    toTitleCase,
    speechTypes,
    vrtBrands,
    vrtRoles,
    isVrt,
} from '../helpers';

import { site } from './../config.js';

const style = {
    Select: {
        minWidth: '200px',
        width: '100%',
    },
    BustaFlex: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    SameSize: {
        fontSize: '0.875rem',
    },
};

class EditSpeechForm extends React.Component {
    static propTypes = {
        speech: PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            speechType: PropTypes.string,
            vrtRole: PropTypes.string,
            vrtBrand: PropTypes.string,
            comment: PropTypes.string,
            timestamp: PropTypes.number.isRequired,
            owner: PropTypes.string,
            ownerEmail: PropTypes.string,
            sharedWith: PropTypes.object,
        }).isRequired,
        updateSpeech: PropTypes.func.isRequired,
        shareSpeech: PropTypes.func.isRequired,
        unShareSpeech: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        getUserId: PropTypes.func.isRequired,
        getUserEmail: PropTypes.func.isRequired,
        commentEnabled: PropTypes.bool.isRequired,
        comments: PropTypes.object.isRequired
    };

    state = {
        title: '',
        speechType: '',
        vrtRole: '',
        vrtBrand: '',
        isVrt: isVrt(),
        comment: '',
        timeString: '',
        shareString: '',
        shareHelp: '',
        shareSuccess: false,
        shareEmails: [],
        newComment: '',
    };

    componentDidMount() {
        // Decouple state with props for performance reasons
        const {
            title,
            speechType,
            vrtRole,
            vrtBrand,
            comment,
        } = this.props.speech;

        this.setState({ title, speechType, vrtRole, vrtBrand, comment });
        this.setTimeString(this.props.speech.timestamp);
        if (this.isOwner()) {
            this.setInitialShareEmails(this.props.speech.sharedWith);
        }
    }

    componentDidUpdate(prevProps) {
        ['title', 'speechType', 'vrtRole', 'vrtBrand', 'comment'].forEach(
            key => {
                if (prevProps.speech[key] !== this.props.speech[key]) {
                    this.setState({ [key]: this.props.speech[key] });
                }
            }
        );
        if (prevProps.speech.timestamp !== this.props.speech.timestamp) {
            this.setTimeString(this.props.speech.timestamp);
        }
    }

    isOwner = () => this.props.speech.owner === this.props.user.uid;

    setTimeString = timestamp => {
        const timeString = timestamp
            ? new Date(timestamp).toLocaleString()
            : '';
        this.setState({ timeString });
    };

    setInitialShareEmails = sharedWith => {
        if (sharedWith) {
            const emailPromises = Object.keys(sharedWith)
                .sort().map(userId => this.props.getUserEmail(userId));
        
            Promise.all(emailPromises).then(emails => {
                this.setState({ shareEmails: emails });
            });
        }
    };

    updateSpeechDebounce = debounce(this.props.updateSpeech, 250);

    handleChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
        this.updateSpeechDebounce(this.props.speech.id, name, value);
    };

    handleTimestampChange = timeString => {
        const newTimestamp = (new Date(timeString)).getTime();
        this.setState({ timeString });

        this.props.updateSpeech(
            this.props.speech.id,
            'timestamp',
            newTimestamp,
        );
    };

    handleShareChange = event => {
        if (this.state.shareSuccess)
            this.setState({ shareSuccess: false });

        const shareString = event.currentTarget.value;
        if (event.currentTarget.checkValidity()) {
            this.setState({ shareString });
            this.handleShareChangeDebounce(shareString);
        } else {
            this.setState({
                shareString,
                shareHelp: 'Please provide a valid e-mail address',
            });
        }
    };

    _handleShareChange = async shareString => {
        let shareHelp = this.state.shareHelp;
        if (shareString) {
            const userId = await this.props.getUserId(shareString);
            const prevSharedWith = this.props.speech.sharedWith;
            if(userId && userId !== this.props.speech.owner)
            {
                if (!prevSharedWith || !Object.keys(prevSharedWith).includes(userId)) 
                {
                    this.props.shareSpeech(this.props.speech.id, userId);
                    this.setState({
                        shareEmails: [...this.state.shareEmails, shareString ] ,
                        shareSuccess: true,
                        shareString: ''
                    });

                    shareHelp = `Speech shared with ${shareString.toLowerCase()}.`;
                } else if (Object.keys(prevSharedWith).includes(userId))
                    shareHelp = `You are already sharing your speech with ${shareString}.`;
            }
            else if (userId)
            {
                shareHelp = `The user ${shareString} is also the owner.`
            }
            else 
                shareHelp = `The user ${shareString} does not have an account for ${site.name}.`;
        } else 
            shareHelp = '';
        
        this.setState({ shareHelp });
    };

    removeShare = async shareEmails => {
        let email = [...this.state.shareEmails]; 
        this.setState({ shareHelp: '' });
        const index = email.indexOf(shareEmails);
        if (index !== -1) {
            const userId = await this.props.getUserId(shareEmails);
        
            email.splice(index, 1);
            this.setState({ shareEmails: email });

            this.props.unShareSpeech(this.props.speech.id, userId);
        }
    };

    handleShareChangeDebounce = debounce(this._handleShareChange, 250);

    render () {
        return (
            <form onSubmit={event => event.preventDefault()}>
                <Grid container spacing={24}>
                    <Grid item xs={3} md={2}>
                        <label htmlFor="title">
                            <Typography>Title</Typography>
                        </label>
                    </Grid>
                    <Grid item xs={9} md={10}>
                        <input
                            type="text"
                            name="title" id="title"
                            disabled={!this.isOwner()}
                            onChange={this.handleChange}
                            value={this.state.title || ''}
                        />
                    </Grid>
                    {
                        this.state.isVrt
                            ? <React.Fragment>
                                <Grid item xs={3} md={2}>
                                    <label htmlFor="vrtRole">
                                        <Typography>Role</Typography>
                                    </label>
                                </Grid>
                                <Grid item xs={9} md={10}>
                                    <FormControl variant="filled">
                                        <Select
                                            style={style.Select}
                                            name="vrtRole"
                                            disabled={!this.isOwner()}
                                            onChange={this.handleChange}
                                            value={this.state.vrtRole || ''}
                                        >
                                            <MenuItem key="" value="" />
                                            {vrtRoles.map(
                                                e => <MenuItem key={e} value={e}>{e}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3} md={2}>
                                    <label htmlFor="vrtBrand">
                                        <Typography>Brand</Typography>
                                    </label>
                                </Grid>
                                <Grid item xs={9} md={10}>
                                    <FormControl variant="filled">
                                        <Select
                                            style={style.Select}
                                            name="vrtBrand"
                                            disabled={!this.isOwner()}
                                            onChange={this.handleChange}
                                            value={this.state.vrtBrand || ''}
                                        >
                                            <MenuItem key="" value="" />
                                            {vrtBrands.map(
                                                e => <MenuItem key={e} value={e}>{e}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </React.Fragment>
                            : <React.Fragment>
                                <Grid item xs={3} md={2}>
                                    <label htmlFor="speechType">
                                        <Typography>Type</Typography>
                                    </label>
                                </Grid>
                                <Grid item xs={9} md={10}>
                                    <FormControl style={style.Select}>
                                        <Select
                                            name="speechType"
                                            disabled={!this.isOwner()}
                                            onChange={this.handleChange}
                                            value={this.state.speechType || ''}
                                        >
                                            <MenuItem key="" value="" />
                                            {speechTypes.map(e =>
                                                <MenuItem key={e} value={e} style={style.SameSize}>{toTitleCase(e)}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </React.Fragment>
                    }
                    <Grid item xs={3} md={2}>
                        <label htmlFor="comment">
                            <Typography>Notes</Typography>
                        </label>
                    </Grid>
                    <Grid item xs={9} md={10}>
                    <textarea
                        name="comment" id="comment"
                        disabled={!this.isOwner()}
                        onChange={this.handleChange}
                        value={this.state.comment || ''}
                        placeholder="Add some personal notes or insights"
                    />
                    </Grid>
                    <Grid item xs={3} md={2}>
                        <label htmlFor="timestamp">
                            <Typography>Timestamp</Typography>
                        </label>
                    </Grid>
                    <Grid item xs={9} md={10}>
                        <DateTimePicker
                            calendarClassName="custom-DateTimePicker"
                            className="custom-DateTimePicker"
                            id="timestamp"
                            onChange={this.handleTimestampChange}
                            value={new Date(this.props.speech.timestamp)}
                            disabled={!this.isOwner()}
                            disableClock={true}
                            clearIcon={null}
                        />
                    </Grid>
                    {
                        this.isOwner() &&
                        <React.Fragment>
                            <Grid item xs={3} md={2}>
                                <label htmlFor="shareWith">
                                    <Typography>Share with</Typography>
                                </label>
                            </Grid>
                            <Grid item xs={9} md={10}>
                            <div style={style.BustaFlex}>
                            {this.state.shareEmails.length > 0 && (
                                this.state.shareEmails.map(value => (
                                    <div key={value} className="email-share">
                                        <span>
                                            {value}
                                        </span>
                                        <button 
                                            type="button" 
                                            onClick={() => this.removeShare(value)}
                                        >
                                            &#10005;
                                        </button>
                                    </div>
                                  )))
                                                                  
                            }
                             </div>      
                                <input
                                    type="email"
                                    name="shareWith" id="shareWith"
                                    placeholder="example@coldmail.com"
                                    onChange={this.handleShareChange}
                                    value={ this.state.shareString || ''}
                                />
                               
                                {
                                    this.state.shareHelp &&
                                    <React.Fragment>
                                        <SnackbarContent 
                                        className={this.state.shareSuccess ? 'is-success-snackbar' : 'is-warning-snackbar'}
                                        //replace with logo in the future ?
                                        message={(this.state.shareSuccess ? '✔ ': '⚠️ ') + this.state.shareHelp} 
                                        />
                                    </React.Fragment>
                                }
                            </Grid>
                        </React.Fragment>
                    }
                    <SpeechComments
                        {...this.props}
                        collapsible={false}
                    />
                </Grid>
            </form>
        );
    }
}

export default EditSpeechForm;