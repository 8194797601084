import React from 'react';
import PropTypes from 'prop-types';

import {Typography, Divider} from '@material-ui/core/';

const style = {
    Divider: {
        margin: '1em 0',
    },

    ContainerHeader: {
        maxWidth: 'calc(100% - 90px)'
    }
};

const ContainerHeader = ({ title, subtitle, pill , print }) => (
    <React.Fragment>
        {
            pill &&
            <div
                key={pill.value}
                title={pill.info}
                className="container-badge"
            >
                {pill.value}
            </div>
        }
        <Typography
            component="h1"
            variant={print ? "h4" : "h6"}
            className="ff-header"
            style={style.ContainerHeader}>{title}</Typography>
        <Typography gutterBottom>{subtitle}</Typography>
        <Divider variant="middle" style={style.Divider} />
    </React.Fragment>
);

ContainerHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    pill: PropTypes.shape({
        value: PropTypes.string.isRequired,
        info: PropTypes.string,
    }),
};

export default ContainerHeader;