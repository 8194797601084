import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import firebase from 'firebase';

import AddSpeechPage from './AddSpeechPage';
import LoadingPage from './LoadingPage';
import NotFoundPage from './NotFoundPage.js';
import ReportPage from './ReportPage';
import SignOnPage from './SignOnPage';
import SpeechesPage from './SpeechesPage';
import ExercisesPage from './ExercisesPage';
import Questionnaire from './Questionnaire';
import SpeakTooFast from './Exercises/SpeakTooFastExercises'
import ParkinsonExercise from './Exercises/ParkinsonExercises'
import SpeakTooFastAdvanced from './Exercises/SpeakTooFastExercises-Advanced'
import BrowserError from './BrowserError';
import { rebase } from '../rebase';
import { Header } from '../Layouts';
import { isEdge, isIE, isParki, isEmptyObject } from '../helpers';

class Router extends React.Component {
    state = {
        dataUsageWarning: null,
        lastLanguage: null,
        lastSpeech: null,
        mySpeeches: {},
        receivedSpeeches: {},
        mySpeechesSynced: false,
        receivedSpeechesSynced: false,
        user: null,
        exercises: null,
        parkiParams: null,
        medicalTrial: null,
        lastQuestionnaireResponse: null,
        browserError: false,
    };

    rebaseRefs = {
        dataUsageWarning: null,
        lastLanguage: null,
        lastSpeech: null,
        mySpeeches: null,
        receivedSpeeches: null,
        exercises: null,
        parkiParams: null,
        medicalTrial: null,
        lastQuestionnaireResponse: null,
    };

    rebaseSpeechesRefs = {};

    rebaseFuncs = {
        mySpeeches: rebase.syncState,
        receivedSpeeches: rebase.bindToState,
    };

    defaultSpeeches = {
        'b6a83fc6-98a1-478a-8a2e-1640a9a7ab9a': true,
        'd8a811df-93f7-4c00-94e4-48514510f68f': true,
        'ee99082d-9d38-4584-bafe-8cf319ba6a90': true,
    };

    defaultExercises = {
        speakTooFast: { unlocked: 2 },
    };

    componentDidMount() {
        if (!isIE() && !isEdge()) this.firebaseMakeConnections();
        else this.setState({ browserError: true });
    }

    updateStateViaProps = (state, status) => {
        this.setState({ [state]: status });
    };

    firebaseMakeConnections = () => {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.setState({ user: user });
                rebase.post(
                    `reverseUsers/${user.email.replace(/\./g, '%2E')}`,
                    { data: user.uid })
                    .catch(err => this.onSyncError(err));
                rebase.post(
                    `users/${user.uid}/email`,
                    { data: user.email })
                    .catch(err => this.onSyncError(err));
                this.rebaseRefs.dataUsageWarning = rebase.syncState(
                    `users/${user.uid}/dataUsageWarning`,
                    {
                        context: this,
                        state: 'dataUsageWarning',
                        then: () => {
                            this.setDefaultState('dataUsageWarning', false);
                        },
                        onFailure: this.onSyncError,
                    },
                );
                this.rebaseRefs.lastLanguage = rebase.syncState(
                    `users/${user.uid}/lastLanguage`,
                    {
                        context: this,
                        state: 'lastLanguage',
                        then: () => {
                            this.setDefaultState('lastLanguage', 'en-US');
                        },
                        onFailure: this.onSyncError,
                    },
                );
                this.rebaseRefs.lastSpeech = rebase.syncState(
                    `users/${user.uid}/lastSpeech/`,
                    {
                        context: this,
                        state: 'lastSpeech',
                        onFailure: this.onSyncError,
                    },
                );
                this.rebaseRefs.exercises = rebase.syncState(
                    `users/${user.uid}/exercises/`,
                    {
                        context: this,
                        state: 'exercises',
                        then: this.setDefaultExercises,
                        onFailure: this.onSyncError,
                    },
                );
                this.rebaseRefs.parkiParams = rebase.syncState(
                    `users/${user.uid}/parkiParams/`,
                    {
                        context: this,
                        state: 'parkiParams',
                        then: () => {
                            this.setDefaultState('parkiParams', {
                                'minVolume': 0.1,
                                'maxPitch': 300,
                                'maxPace': 3.8,
                            });
                        },
                        onFailure: this.onSyncError,
                    },
                );
                this.rebaseRefs.medicalTrial = rebase.syncState(
                    `users/${user.uid}/medicalTrial`,
                    {
                        context: this,
                        state: 'medicalTrial',
                        then: () => {
                            this.setDefaultState('medicalTrial', false);
                        },
                        onFailure: this.onSyncError,
                    },
                );
                this.rebaseRefs.lastQuestionnaireResponse = rebase.syncState(
                    `users/${user.uid}/lastQuestionnaireResponse`,
                    {
                        context: this,
                        state: 'lastQuestionnaireResponse',
                        onFailure: this.onSyncError,
                    },
                );

                // Don't sync, but fetch and handle further in listenToSpeeches
                rebase.fetch(
                    `users/${user.uid}/mySpeeches`,
                    {
                        context: this,
                        onFailure: this.onSyncError,
                    },
                ).then(speechIds => {
                    this.syncSpeeches('mySpeeches', speechIds);
                    this.rebaseSpeechesRefs.mySpeeches = rebase.listenTo(
                        `users/${user.uid}/mySpeeches`,
                        {
                            context: this,
                            then: speechIds => {
                                this.syncSpeeches('mySpeeches', speechIds);
                            },
                            onFailure: this.onSyncError,
                        },
                    );
                });

                rebase.fetch(
                    `users/${user.uid}/receivedSpeeches`,
                    {
                        context: this,
                        onFailure: this.onSyncError,
                    },
                ).then(speechIds => {
                    if (isEmptyObject(speechIds)) {
                        rebase.update(
                            `users/${user.uid}/receivedSpeeches`,
                            {
                                data: this.defaultSpeeches,
                                then: err => {
                                    if (err) {
                                        this.onSyncError(err);
                                    } else {
                                        this.syncSpeeches(
                                            'receivedSpeeches',
                                            speechIds
                                        );
                                    }
                                },
                            },
                        );
                    } else {
                        this.syncSpeeches('receivedSpeeches', speechIds);
                    }
                    this.rebaseSpeechesRefs.receivedSpeeches = rebase.listenTo(
                        `users/${user.uid}/receivedSpeeches`,
                        {
                            context: this,
                            then: speechIds => {
                                this.syncSpeeches(
                                    'receivedSpeeches',
                                    speechIds
                                );
                            },
                            onFailure: this.onSyncError,
                        },
                    );
                });
            }
        });
    };

    componentWillUnmount() {
        Object.values(this.rebaseRefs).forEach(value => {
            rebase.removeBinding(value);
        });
        Object.values(this.rebaseSpeechesRefs).forEach(value => {
            rebase.removeBinding(value)
        });
    }

    onSyncError = err => {
        console.log('Failed to sync with Firebase:', err);
    };

    setDefaultState = (key, value) => {
        if (isEmptyObject(this.state[key])) {
            this.setState({ [key]: value });
        }
    };

    setUserState = newState => new Promise(resolve => {
        this.setState(newState, resolve);
    });

    setDefaultExercises = () => {
        if (isEmptyObject(this.state.exercises)) {
            this.setState({ exercises: this.defaultExercises })
        } else {
            const newExercises = Object.entries(this.defaultExercises).reduce(
                (result, [key, value]) => {
                    if (!this.state.exercises[key]) {
                        result[key] = value;
                    }
                    return result
                },
                {},
            );
            this.setState({
                exercises: {
                    ...this.state.exercises,
                    ...newExercises,
                },
            });
        }
    };

    updateSpeech = (speechId, key, value) => {
        const speech = {
            ...this.state.mySpeeches[speechId],
            [key]: value,
        };
        const mySpeeches = {
            ...this.state.mySpeeches,
            [speechId]: speech,
        };
        return new Promise(resolve => {
            this.setState({ mySpeeches }, resolve);
        });
    };

    shareSpeech = (speechId, userId) => {
        let sharedWith = this.state.mySpeeches[speechId].sharedWith;
        if (sharedWith && Object.keys(sharedWith).includes(userId)  ) {
            sharedWith[userId] = true;
        } else {
            sharedWith = {...sharedWith, [userId]: true };
        }
        this.updateSpeech(speechId, 'sharedWith', sharedWith)
            .then(() => rebase.post(
                `users/${userId}/receivedSpeeches/${speechId}`,
                { data: true },
            ))
            .catch(err => this.onSyncError(err));
    };

    unShareSpeech = (speechId, userId) => {
        rebase.remove(`users/${userId}/receivedSpeeches/${speechId}`)
            .then(() => {
                let sharedWith = this.state.mySpeeches[speechId].sharedWith;
                sharedWith[userId] = null;
                this.updateSpeech(speechId, 'sharedWith', sharedWith);
            })
            .catch(err => this.onSyncError(err));
    };

    deleteSpeech = speechId => {
        // Stop sharing the speeches (note: spied speeches will still be seen)
        let sharedWith = this.state.mySpeeches[speechId].sharedWith;
        if (!sharedWith) {
            sharedWith = {};
        }
        let promises = Object.keys(sharedWith).map(userId =>
            rebase.remove(`users/${userId}/receivedSpeeches/${speechId}`));

        Promise.all(promises)
            .then(() => this.updateSpeech(speechId, 'sharedWith', null))
            // Shallowly delete the speeches (only unreferencing them)
            .then(() => this.updateSpeech(speechId, 'deleted', true))
            .then(() => rebase.remove(
                `users/${this.state.user.uid}/mySpeeches/${speechId}`))
            .catch(err => this.onSyncError(err));
    };

    addSpeechToState = (stateKey, speechId) => {
        this.rebaseSpeechesRefs[speechId] = this.rebaseFuncs[stateKey](
            `speeches/${speechId}`,
            {
                context: this,
                state: `${stateKey}.${speechId}`,
                onFailure: this.onSyncError,
            },
        );
    };

    removeSpeechFromState = (stateKey, speechId) => {
        rebase.removeBinding(this.rebaseSpeechesRefs[speechId]);
        const speeches = { ...this.state[stateKey] };
        delete speeches[speechId];
        return new Promise(resolve => {
            this.setState({ [stateKey]: speeches }, resolve);
        });
    };

    syncSpeeches = (stateKey, speechIds) => {
        Object.keys(speechIds).forEach(speechId => {
            if (!(speechId in this.state[stateKey])) {
                this.addSpeechToState(stateKey, speechId);
            }
        });
        Object.keys(this.state[stateKey]).forEach(speechId => {
            if (!(speechId in speechIds)) {
                this.removeSpeechFromState(stateKey, speechId)
            }
        });
        if (this.state[stateKey + 'Synced'] === false) {
            this.waitForSpeeches(stateKey, Object.keys(speechIds).length)
                .then(() => this.setState({[stateKey + 'Synced']: true}));
        }
    };

    waitForSpeeches = (stateKey, numberOfSpeeches) => new Promise(resolve => {
        const check = () => {
            if (Object.keys(this.state[stateKey]).length >= numberOfSpeeches) {
                resolve();
            } else {
                setTimeout(check, 100);
            }
        };
        setTimeout(check, 100);
    });

    isSynced = () => (
        this.state.dataUsageWarning != null
        && this.state.lastLanguage != null
        && this.state.medicalTrial != null
        && this.state.lastQuestionnaireResponse != null
        && this.state.mySpeechesSynced === true
        && this.state.receivedSpeechesSynced === true
    );

    getUserId = async userEmail => {
        const userId = await rebase.fetch(
            `reverseUsers/${userEmail.toLowerCase().replace(/\./g, '%2E')}`,
            {
                context: this,
                onFailure: this.onSyncError,
            },
        );
        return (typeof userId === 'string') ? userId : '';
    };

    getUserEmail = async userId => {
        const email = await rebase.fetch(
            `users/${userId}/email`,
            {
                context: this,
                onFailure: this.onSyncError,
            },
        );
        return (typeof email === 'string') ? email : '';
    };

    showQuestionnaire = () => {
        if (!this.state.medicalTrial) return false;
        if (isEmptyObject(this.state.lastQuestionnaireResponse)) return true;
        const midnight = (new Date()).setHours(0, 0, 0, 0);
        return this.state.lastQuestionnaireResponse < midnight;
    };

    render() {
        const isSynced = this.isSynced();
        return <BrowserRouter>
            <ScrollToTop>
                {this.state.browserError ?
                <BrowserError
                    firebaseMakeConnections={this.firebaseMakeConnections}
                    updateStateViaProps={this.updateStateViaProps}
                    /> :
                <Switch>
                    <Route
                        path="/sign-on"
                        component={SignOnPage}
                    />
                    <PrivateRoute
                        path="/add-speech"
                        isSynced={isSynced}
                        render={props =>
                            <AddSpeechPage
                                {...props}
                                lastLanguage={this.state.lastLanguage}
                                lastSpeech={this.state.lastSpeech}
                                mySpeeches={this.state.mySpeeches}
                                user={this.state.user}
                                setUserState={this.setUserState}
                            />
                        }
                    />

                    <PrivateRoute
                        path="/speaktoofast"
                        isSynced={isSynced}
                        render={props =>
                            <SpeakTooFast
                                {...props}
                                lastLanguage={this.state.lastLanguage}
                                user={this.state.user}
                                mySpeeches={this.state.mySpeeches}
                                exercises={this.state.exercises}
                                setUserState={this.setUserState}
                            />
                        }
                    />

                    <PrivateRoute
                        path="/stemoefeningen"
                        isSynced={isSynced}
                        render={props =>
                            <ParkinsonExercise
                                {...props}
                                lastLanguage={this.state.lastLanguage}
                                user={this.state.user}
                                mySpeeches={this.state.mySpeeches}
                                exercises={this.state.exercises}
                                parkiParams={this.state.parkiParams}
                                setUserState={this.setUserState}
                            />
                        }
                    />

                    <PrivateRoute
                        path="/speaktoofast-advanced"
                        isSynced={isSynced}
                        render={props =>
                            <SpeakTooFastAdvanced
                                {...props}
                                lastLanguage={this.state.lastLanguage}
                                user={this.state.user}
                                mySpeeches={this.state.mySpeeches}
                                exercises={this.state.exercises}
                                setUserState={this.setUserState}
                            />
                        }
                    />

                    <PrivateRoute
                        path="/reports/:reportId/print"
                        isSynced={isSynced}
                        disableNav={true}
                        render={props =>
                            <ReportPage
                                {...props}
                                print={true}
                                speech={
                                    this.state.mySpeeches[
                                        props.match.params.reportId]
                                    || this.state.receivedSpeeches[
                                        props.match.params.reportId]
                                }
                                parkiParams={this.state.parkiParams}
                                updateSpeech={this.updateSpeech}
                                shareSpeech={this.shareSpeech}
                                unShareSpeech={this.unShareSpeech}
                                user={this.state.user}
                                getUserId={this.getUserId}
                                getUserEmail={this.getUserEmail}
                            />
                        }
                    />
                    <PrivateRoute
                        path="/reports/:reportId"
                        isSynced={isSynced}
                        render={props =>
                            <ReportPage
                                {...props}
                                print={false}
                                speech={
                                    this.state.mySpeeches[
                                        props.match.params.reportId]
                                    || this.state.receivedSpeeches[
                                        props.match.params.reportId]
                                }
                                parkiParams={this.state.parkiParams}
                                updateSpeech={this.updateSpeech}
                                shareSpeech={this.shareSpeech}
                                unShareSpeech={this.unShareSpeech}
                                user={this.state.user}
                                getUserId={this.getUserId}
                                getUserEmail={this.getUserEmail}
                            />
                        }
                    />
                    <PrivateRoute
                        path={isParki() ? '/reports' : '/(reports|)'}
                        isSynced={isSynced}
                        render={props =>
                            <SpeechesPage
                                {...props}
                                dataUsageWarning={this.state.dataUsageWarning}
                                mySpeeches={this.state.mySpeeches}
                                receivedSpeeches={this.state.receivedSpeeches}
                                user={this.state.user}
                                setUserState={this.setUserState}
                                updateSpeech={this.updateSpeech}
                                deleteSpeech={this.deleteSpeech}
                            />
                        }
                    />
                    <PrivateRoute
                        path={isParki() ? '/(exercises|)' : '/exercises'}
                        isSynced={isSynced}
                        render={props => this.showQuestionnaire()
                            ? <Questionnaire
                                {...props}
                                user={this.state.user}
                                setUserState={this.setUserState}
                            />
                            : <ExercisesPage {...props} />
                        }
                    />
                    <Route
                        component={NotFoundPage}
                    />
                </Switch>
                }
            </ScrollToTop>
        </BrowserRouter>;
    }
}

const PrivateRoute = ({render: renderFunc, isSynced, disableNav, ...rest}) => (
    <Route
        {...rest}
        render={props => {
            if (!firebase.auth().currentUser) {
                return <SignOnPage {...props} />;
            }
            if (!isSynced) {
                return <LoadingPage {...props} />;
            }
            return (
                <React.Fragment>
                    {renderFunc(props)}
                    {!disableNav &&  <Header history={props.history} />}
                </React.Fragment>
            );
        }}
    />
);
PrivateRoute.propTypes = {
    render: PropTypes.func.isRequired,
    isSynced: PropTypes.bool.isRequired,
    history: PropTypes.object,
    disableNav: PropTypes.bool,
};

class _ScrollToTop extends React.Component {
    static propTypes = {
        children: PropTypes.any,
        location: PropTypes.object.isRequired,
    };

    componentDidUpdate(prevProps) {
        const { pathname, search } = this.props.location;
        const { prevPathname, prevSearch } = prevProps.location;
        if (pathname !== prevPathname || search !== prevSearch) {
            window.scrollTo(0, 0);
        }
    }
    render() {
        return this.props.children;
    }
}
const ScrollToTop = withRouter(_ScrollToTop);

export default Router;