import React from 'react';
import {
    Typography,
    Grid,
    Card,
    Paper,
    CardMedia
} from '@material-ui/core/';

const style = {
    Advertisement: {
        maxWidth: '560px',
        margin: '0 auto'
    },
    Card: {
        height: '100%',
        position: 'relative'
    },
    SpacingTitle: {
        padding: '0.5em 0 1em 0'
    },
    PaperSameHeight: {
        height: '100%'
    }
};

class ParkinsonExerciseIntro extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Paper elevation={1} className="customPaper">
                    <Typography component="h2" variant="title" gutterBottom>
                        Inleiding: Wat is PLVT?
                    </Typography>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography>
                                We geven hier enkele oefeningen die kaderen in
                                de Pitch Limiting Voice Treatment (
                                <strong className="is-custom-strong">
                                    PLVT
                                </strong>
                                ). Dit is dé behandeling voor spraakproblemen
                                bij Parkinsonpatiënten. Het doel is om jou als
                                patiënt heel intensief te begeleiden om beter
                                te spreken.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                Het motto bij PLVT is: &quot;
                                <strong className="is-custom-strong">
                                    Spreek luid en laag!
                                </strong>
                                &quot;
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                Als je altijd luid en laag spreekt, heeft dit
                                een grote positieve invloed op je
                                verstaanbaarheid. Daarmee verminderen ook de
                                andere problemen:
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography component="ul" gutterBottom>
                                <li>
                                    Een zachte stem wordt luider en dus beter
                                    hoorbaar.
                                </li>
                                <li>
                                    Een hese stem wordt helderder. De ademing
                                    wordt vanzelf dieper, omdat luid spreken
                                    zonder diepere ademing niet mogelijk is.
                                </li>
                                <li>
                                    De articulatie en het spreektempo
                                    verbeteren tegelijkertijd, want
                                    binnensmonds spreken en snel spreken zijn
                                    moeilijk vol te houden als je luider
                                    spreekt.
                                </li>
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
                <Grid container spacing={8}>
                    <Grid item xs={12} sm={7}>
                        <Card style={style.Advertisement}>
                            <CardMedia
                                component="iframe"
                                width="560"
                                height="315"
                                title="Parkinson logopedie oefeningen"
                                src="https://www.youtube.com/embed/wqVDe7onwvQ"
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen={true}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Paper
                            elevation={1}
                            className="customPaper"
                            style={style.PaperSameHeight}
                        >
                            <Typography
                                component="h2"
                                variant="title"
                                style={style.SpacingTitle}
                            >
                                Dit is een logopedische therapie!
                            </Typography>
                            <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    <Typography gutterBottom>
                                        Deze oefeningen mogen uitsluitend
                                        gebruikt worden na overleg met je
                                        logopedist.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        Winston presenteert deze oefeningen
                                        louter als demo.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography gutterBottom>
                                        Interessant link: <br />
                                        <a
                                            href="https://www.parkinsonnet.nl/media/14954/folder%20verstaanbaar%20spreken.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title={
                                                'Parkinson Centrum Nijmegen document'
                                            }
                                        >
                                            Parkinson Centrum Nijmegen
                                        </a>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}
export default ParkinsonExerciseIntro;
