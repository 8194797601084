import React from 'react';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    Paper,
    CardMedia
} from '@material-ui/core/';

const style = {
    Fraction: {
        textAlign: 'center',
        marginTop: '2px',
        fontSize: '1em'
    },
    Advertisement: {
        maxWidth: '560px',
        margin: '0 auto'
    },
    Media: {
        height: '75%'
    },
    Card: {
        height: '100%',
        position: 'relative'
    },
    SpacingTitle: {
        padding: '0.5em 0 1em 0'
    }
};

class SpeakTooFastPageIntro extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Paper elevation={1} className="customPaper">
                    <Typography component="h2" variant="title" gutterBottom>
                        Introduction
                    </Typography>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography>
                                Compare these two examples:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Card style={style.Card}>
                                <CardMedia
                                    component="iframe"
                                    width="560"
                                    height="315"
                                    title="How great leaders inspire action | Simon Sinek"
                                    src="https://www.youtube.com/embed/qp0HIF3SfI4?start=340"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen={true}
                                />
                                <CardContent style={style.CardContent}>
                                    <Typography gutterBottom>
                                        Simon Sinek - ​How Great Leaders
                                        Inspire Action​
                                    </Typography>
                                    <Typography>
                                        TEDxPuget Sound, September 2009
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Card style={style.Card}>
                                <CardMedia
                                    component="iframe"
                                    width="560"
                                    height="315"
                                    title="2018 World Champion of Public Speaking - Ramona Smith - Still Standing"
                                    src="https://www.youtube.com/embed/BK7f0b5JvbY?start=258"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen={true}
                                />
                                <CardContent style={style.CardContent}>
                                    <Typography gutterBottom>
                                        Ramona Smith - ​Still Standing​
                                    </Typography>
                                    <Typography>
                                        Toastmasters 2018 World Champion of
                                        Public Speaking
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                Do you notice a difference? Ramona Smith
                                speaks 100 syllables per minute (spm). Simon
                                Sinek uses 230 spm per minute, which is more
                                than double as fast!
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                Syllables per minute (spm) is the unit we use
                                to measure the speaking pace.
                            </Typography>
                            <Typography>
                                The formula is very easy and is as follows:
                            </Typography>
                            <Typography
                                variant="caption"
                                style={style.Fraction}
                            >
                                <sup>total number of syllables in speech</sup>
                                &frasl;
                                <sub>duration of the speech in minutes</sub>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography gutterBottom>
                                If we look at the speech of Ramona Smith, we
                                measured a total of 700 syllables / 7 minutes =
                                100 spm.
                            </Typography>
                            <Typography gutterBottom>
                                What is your impression if you compare these
                                two speeches?
                            </Typography>
                            <Typography gutterBottom>
                                The speaking pace affects how the audience
                                perceive the speaker’s knowledge and passion.
                                The pace also hints at the confidence level
                                of the speaker. In general, speakers with a
                                high pace (above 200 spm) are perceived as
                                passionate and knowledgeable. The downside of
                                speaking too fast: it is difficult to
                                understand everything you say and you may seem
                                nervous.
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper elevation={1} className="customPaper">
                    <Typography
                        component="h2"
                        variant="title"
                        style={style.SpacingTitle}
                    >
                        This is an example of what happens when you speak too
                        fast:
                    </Typography>
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={12}>
                            <Card style={style.Advertisement}>
                                <CardMedia
                                    component="iframe"
                                    width="560"
                                    height="315"
                                    title="Voltaren Forte"
                                    src="https://www.youtube.com/embed/Uz-sElJkrns"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen={true}
                                />
                                <CardContent>
                                    <Typography>Bulgarian ad</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography gutterBottom>
                                You listen to a commercial drug advertisement
                                in Bulgarian whereby all the legal requirements
                                are squeezed in the last 5 seconds. Even
                                without understanding the language, you hear
                                the speeding up in the last seconds. How do you
                                feel about this product? Are you more or less
                                concerned about possible side effects?
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={1} className="customPaper">
                    <Typography
                        component="h1"
                        variant="title"
                        style={style.SpacingTitle}
                    >
                        Learning objectives for this module
                    </Typography>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography component="ul" gutterBottom>
                                <li>Be aware of your own speaking pace.</li>
                                <li>
                                    Have some tools in your toolbox to
                                    deliberately change your speaking pace.
                                </li>
                            </Typography>
                            <Typography>
                                Throughout this manual, we will use Rapunzel, a
                                fairytale by the Brothers Grimm. Through
                                different exercises we will work together to
                                reduce your speaking pace. We have prepared the
                                exercises in English and in Dutch.
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </React.Fragment>
        );
    }
}
export default SpeakTooFastPageIntro;
