import React from 'react';
import { Typography } from '@material-ui/core/';
import PropTypes from 'prop-types';
import { randomSubArray } from '../../../helpers';
import { exercisesData, exercises, infos } from './Texts';
import Result from './Result';

import ParkinsonExerciseIntro from './ParkinsonExerciseIntro';
import HoudingEnOntspanning from './HoudingEnOntspanning';
import RecordingExercise from '../RecordingExercise';
import Navigation from '../Navigation';
import ParkiParamsEditor from './ParkiParamsEditor';

class ParkinsonExercise extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        lastLanguage: PropTypes.string.isRequired,
        user: PropTypes.object.isRequired,
        mySpeeches: PropTypes.object.isRequired,
        exercises: PropTypes.object.isRequired,
        parkiParams: PropTypes.object.isRequired,
        setUserState: PropTypes.func.isRequired,
    };

    state = {
        pageNumber: 1,
    };

    exerciseType = 'stemoefeningen';
    title = 'Stemoefeningen PLVT (Parkinson)';

    updateStateViaProps = (state, status) => {
        this.setState({ [state]: status });
    };

    randomizeExercisesContent = exercisesContent => {
        return Object.entries(exercisesContent)
            .map(([language, content]) => [language, {
                ...content,
                text: content.pickRandom
                    ? randomSubArray(content.text, content.pickRandom)
                    : content.text,
            }])
            .reduce((obj, [k, v]) => ({ ...obj, [k]: v }), {});
    };

    ParkiExercise = ({ seq, numbered }) => {
        const exercisesContent = this.randomizeExercisesContent(
            exercises[`exercise${exercisesData[seq].exerciseNumber}`]
        );
        return (
            <React.Fragment>
                <RecordingExercise
                    key={`ParkisonExercise${seq}`}
                    // From Router
                    history={this.props.history}
                    lastLanguage={this.props.lastLanguage}
                    user={this.props.user}
                    mySpeeches={this.props.mySpeeches}
                    exercises={this.props.exercises}
                    parkiParams={this.props.parkiParams}
                    setUserState={this.props.setUserState}
                    // Defined here
                    exerciseType={this.exerciseType}
                    exerciseLanguage={this.exerciseLanguage}
                    exercisesContent={exercisesContent}
                    exerciseNumber={exercisesData[seq].exerciseNumber}
                    titleExercise={exercisesData[seq].titleExercise}
                    title={exercisesData[seq].title}
                    infos={infos}
                    result={Result}
                    language={'nl-NL'}
                    numbered={numbered}
                    parkinsonException={true}
                />
                <ParkiParamsEditor
                    parkiParams={this.props.parkiParams}
                    setUserState={this.props.setUserState}
                />
            </React.Fragment>
        );
    };

    getContent = (pageNumber, words) => {
        let maximumPages = 1;
        let content = <Typography>Failed.</Typography>;
        if (pageNumber && words) {
            switch (words) {
                case 'uitroepen':
                    maximumPages = 3;
                    switch (pageNumber) {
                        case 1:
                            content = <ParkinsonExerciseIntro />;
                            break;
                        case 2:
                            content = <HoudingEnOntspanning />;
                            break;
                        case 3:
                            content = <this.ParkiExercise seq={3} numbered={true} />;
                            break;
                        default:
                            content = <Typography>Coming soon ...</Typography>;
                    }
                    break;
                case 'eenLettergreep':
                    maximumPages = 3;
                    switch (pageNumber) {
                        case 1:
                            content = <HoudingEnOntspanning />;
                            break;
                        case 2:
                            content = <this.ParkiExercise seq={4} numbered={true} />;
                            break;
                        case 3:
                            content = <this.ParkiExercise seq={5} numbered={true} />;
                            break;
                        default:
                            content = <Typography>Coming soon ...</Typography>;
                    }
                    break;
                case 'tweeLettergrepen':
                    maximumPages = 3;
                    switch (pageNumber) {
                        case 1:
                            content = <HoudingEnOntspanning />;
                            break;
                        case 2:
                            content = <this.ParkiExercise seq={6} numbered={true} />;
                            break;
                        case 3:
                            content = <this.ParkiExercise seq={7} numbered={true} />;
                            break;
                        default:
                            content = <Typography>Coming soon ...</Typography>;
                    }
                    break;
                case 'drieLettergrepen':
                    maximumPages = 2;
                    switch (pageNumber) {
                        case 1:
                            content = <HoudingEnOntspanning />;
                            break;
                        case 2:
                            content = <this.ParkiExercise seq={8} numbered={true} />;
                            break;
                        default:
                            content = <Typography>Coming soon ...</Typography>;
                    }
                    break;
                case 'vierLettergrepen':
                    maximumPages = 2;
                    switch (pageNumber) {
                        case 1:
                            content = <HoudingEnOntspanning />;
                            break;
                        case 2:
                            content = <this.ParkiExercise seq={9} numbered={true} />;
                            break;
                        default:
                            content = <Typography>Coming soon ...</Typography>;
                    }
                    break;
                case 'generalisatie':
                    maximumPages = 3;
                    switch (pageNumber) {
                        case 1:
                            content = <HoudingEnOntspanning />;
                            break;
                        case 2:
                            content = <this.ParkiExercise seq={10} numbered={false} />;
                            break;
                        case 3:
                            content = <this.ParkiExercise seq={11} numbered={false} />;
                            break;
                        default:
                            content = <Typography>Coming soon ...</Typography>;
                    }
                    break;
                default:
                    content = <Typography>Failed.</Typography>;
            }
        }
        return [content, maximumPages];
    };

    exerciseLanguage = () => {
        return 'NL';
    };

    render() {
        const params = new URLSearchParams(this.props.location.search);
        const pageNumber = Math.max(1, parseInt(params.get('page')) || 1);
        const [
            content,
            maximumPages,
        ] = this.getContent(pageNumber, params.get('words'));
        return (
            <React.Fragment>
                <Navigation
                    history={this.props.history}
                    location={this.props.location}
                    title={this.title}
                    maximumPages={maximumPages}
                    pageNumber={pageNumber}
                    unlockedNum={{unlocked: maximumPages}}
                    updateStateViaProps={this.updateStateViaProps}
                >
                    {content}
                </Navigation>
            </React.Fragment>
        );
    }
}
export default ParkinsonExercise;
