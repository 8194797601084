import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { pickRandom } from '../../../../helpers';
import ContainerHeader from './ContainerHeader';

import {Paper, Typography} from '@material-ui/core/';

class DetailTab extends React.Component {
    static propTypes = {
        print: PropTypes.bool.isRequired,
        header: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        pill: PropTypes.shape({
            value: PropTypes.string.isRequired,
            info: PropTypes.string,
        }),
        title: PropTypes.string.isRequired,
        highlight: PropTypes.string.isRequired,
        summary: PropTypes.string.isRequired,
        tips: PropTypes.arrayOf(PropTypes.string).isRequired,
        exercises: PropTypes.string,
        exercisesUrl: PropTypes.string,
    };

    state = { tip: null };

    componentDidMount() {
        this.setState({ tip: pickRandom(this.props.tips) })
    }

    renderNoPrint = () => (
        <React.Fragment>
        <Paper elevation={1} className="customPaper">
            <ContainerHeader
                title={this.props.header}
                subtitle={this.props.description}
                pill={this.props.pill}
                print={this.props.print}
            />
            <Typography variant="h6">{this.props.title}</Typography>
            <Typography gutterBottom>
                {this.props.highlight}
            </Typography>
            <Typography gutterBottom className="mt-1">{this.props.summary}</Typography>
            <Typography variant="h6" className="mt-1">Tip</Typography>
            <Typography gutterBottom>{this.state.tip}</Typography>
            {
                this.props.exercises &&
                <React.Fragment>
                    <Typography variant="h6" className="mt-1">
                        Exercises
                    </Typography>
                    <Typography>{this.props.exercises} Click <Link
                            to={this.props.exercisesUrl}
                        >here</Link> to start.
                    </Typography>
                </React.Fragment>
            }
        </Paper>
        </React.Fragment>
    );

    renderPrint = () => (
        <React.Fragment>
           <div style={{position: "relative"}} >
            <ContainerHeader
                title={this.props.header}
                subtitle={this.props.description}
                pill={this.props.pill}
                print={this.props.print}
            />
            
            <Typography variant="h5">{this.props.title}</Typography>
            <Typography gutterBottom>{this.props.highlight}</Typography>
            <br />
            <Typography gutterBottom>{this.props.summary}</Typography>
            <br />
            <Typography variant="h5" gutterBottom>Helpful tips for your next speech</Typography>
            <ul>
                {
                    this.props.tips.map(
                        (tip, index) => <li key={index}><Typography>{tip}</Typography></li>
                    )
                }
            </ul>
            {
                this.props.pill && this.props.pill.info &&
                <React.Fragment>
                    <Typography variant="h5" gutterBottom>What does {this.props.pill.value} mean?</Typography>
                    <Typography gutterBottom>{this.props.pill.info}</Typography>
                </React.Fragment>
            }
            <div className="page-break"></div>
            </div>
        </React.Fragment>
    );

    render() {
        if (this.props.print) {
            return this.renderPrint();
        } else {
            return this.renderNoPrint();
        }
    }
}

export default DetailTab;
