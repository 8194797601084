import React from 'react';
import PropTypes from 'prop-types';

import { convertTimeStamp } from '../../../../helpers';
import ContainerHeader from './ContainerHeader';
import {Paper} from '@material-ui/core/';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const style = {
    tableLeft: {
        padding: '0',
        border: 'none',
        minWidth: '55px'
    },
    tableRight: {
        padding: '7px',
        border: 'none'
    }
}

const TranscriptTab = ({ print, sentences, pill }) => {
    const sentencesView = sentences && sentences.map((s, i) => ( s.transcript &&
        <React.Fragment key={i}>
          <TableRow>
            <TableCell style={style.tableLeft}>
                {sentences.length > 2 && <strong>{convertTimeStamp(s.start_s)}</strong>}
            </TableCell>
            <TableCell style={style.tableRight}>
                {s.transcript}
            </TableCell>
          </TableRow>
        </React.Fragment>)
    );
    if (!print) {
        return (
            <Paper elevation={1} className="customPaper">
                <ContainerHeader
                    title="Your transcript"
                    subtitle="Speech-to-text with the help from Google"
                    pill={pill}
                />


                <Table>
                    <TableBody>
                        {sentencesView}
                    </TableBody>
                </Table>
            </Paper>
        );
    } else {
        return (
            <React.Fragment>
                 <div style={{position: "relative"}} >
                <ContainerHeader
                    print={print}
                    title="Your transcript"
                    subtitle="Speech-to-text with the help from Google"
                    pill={pill}
                />
                <Table>
                    <TableBody>
                        {sentencesView}
                    </TableBody>
                </Table>
                </div>
            </React.Fragment>
        );
    }
};

TranscriptTab.propTypes = {
    print: PropTypes.bool.isRequired,
    sentences: PropTypes.arrayOf(PropTypes.object).isRequired,
    pill: PropTypes.shape({
        value: PropTypes.string.isRequired,
        info: PropTypes.string,
    }),
};

export default TranscriptTab;
