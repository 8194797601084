export function toMinute(sec) {
    return (sec * 60).toFixed(2);;
}

export function convertDuration(ms) {
    const minutes = Math.floor(ms / 60000);
    const seconds = ((ms % 60000) / 1000).toFixed(0);
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
}

export function convertTimeStamp(s) {
    const minutes = Math.floor(s / 60);
    const seconds = (s % 60).toFixed(2);
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
}

export function pickRandom(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
}

export function randomSubArray(arr, n) {
    let len = arr.length;
    const taken = new Array(len);
    const result = new Array(n);
    if (n > len)
        throw new RangeError('randomSubArray: more elements taken than available');
    while (n--) {
        let x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}

export function isiOS() {
    return (!!navigator.platform &&
        /iPad|iPhone|iPod/.test(navigator.platform));
}

export function isEdge() {
    return window.navigator.userAgent.indexOf('Edge') > -1;
}

export function isIE() {
    return window.navigator.userAgent.indexOf('MSIE') > -1;
}

export function isVrt() {
    return(window.location.host.toLocaleLowerCase().includes('vrt'));
}

export function isParki() {
    return(window.location.host.toLocaleLowerCase().includes('parki'));
}

export function isLocal() {
    if (window.location.hostname.startsWith('localhost')) return true;
    return /^(10\.\d+\.\d+\.\d+)|(127\.\d+\.\d+\.\d+)|(172\.(1[6-9]|2[0-9]|3[0-1])\.\d+\.\d+)|(192\.168\.\d+\.\d+)$/.test(window.location.hostname)
}

export function toTitleCase(str) {
    const strs = str.split(' ');
    for (let i = 0; i < strs.length; i++) {
        strs[i] = strs[i].charAt(0).toUpperCase() + strs[i].slice(1);
    }
    return strs.join(' ');
}

export function debounce(func, delay) {
    let inDebounce;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => func.apply(context, args), delay);
    };
}

export function isVersion(thisVersion, {gte = '0.0.0', lt = '9999.9.9'} = {}) {
    if (thisVersion === gte) return true;
    if (thisVersion === lt) return false;
    const thisV = thisVersion.split('.').map(x => parseInt(x));
    const gteV = gte.split('.').map(x => parseInt(x));
    const ltV = lt.split('.').map(x => parseInt(x));
    return isGt(thisV, gteV) && isLt(thisV, ltV);
}

function isGt(a, b) {
    if (a[0] > b[0]) return true;
    if (a[0] < b[0]) return false;
    return isGt(a.slice(1), b.slice(1))
}

function isLt(a, b) {
    if (a[0] < b[0]) return true;
    if (a[0] > b[0]) return false;
    return isLt(a.slice(1), b.slice(1))
}

export function isEmptyObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export const speechTypes = [
    'celebration speech',
    'elevator pitch',
    'informative speech',
    'inspirational speech',
    'sales pitch',
    'TED talk',
    'Toastmasters story',
    'PLVT exercise',
    'other',
];

export const vrtRoles = [
    'journaalanker tv',
    'nieuwsanker radio',
    'sportanker radio/tv',
    'anker weer / verkeer radio/tv',
    'dragende presentator / wrapper / host tv',
    'dragende presentator / DJ radio',
    'spotstem voor promo of trailers radio/tv',
    'inlezen documentaires',
    'duiding in studio',
    'verslaggeving (stand-up, montage, sport...)',
    'doorlezer bij beeldmateriaal',
    'inlezen eigen of andermans reportages',
];

export const vrtBrands = [
    'VRT NWS',
    'Sporza',
    'één',
    'Canvas',
    'Ketnet',
    'Radio 1',
    'Radio 2',
    'Studio Brussel',
    'MNM',
    'Klara',
];

export const languages = {
    'af-ZA': 'Afrikaans (South Africa)',
    'am-ET': 'Amharic (Ethiopia)',
    'ar-DZ': 'Arabic (Algeria)',
    'ar-BH': 'Arabic (Bahrain)',
    'ar-EG': 'Arabic (Egypt)',
    'ar-IQ': 'Arabic (Iraq)',
    'ar-IL': 'Arabic (Israel)',
    'ar-JO': 'Arabic (Jordan)',
    'ar-KW': 'Arabic (Kuwait)',
    'ar-LB': 'Arabic (Lebanon)',
    'ar-MA': 'Arabic (Morocco)',
    'ar-OM': 'Arabic (Oman)',
    'ar-QA': 'Arabic (Qatar)',
    'ar-SA': 'Arabic (Saudi Arabia)',
    'ar-PS': 'Arabic (State of Palestine)',
    'ar-TN': 'Arabic (Tunisia)',
    'ar-AE': 'Arabic (United Arab Emirates)',
    'hy-AM': 'Armenian (Armenia)',
    'az-AZ': 'Azerbaijani (Azerbaijan)',
    'eu-ES': 'Basque (Spain)',
    'bn-BD': 'Bengali (Bangladesh)',
    'bn-IN': 'Bengali (India)',
    'bg-BG': 'Bulgarian (Bulgaria)',
    'ca-ES': 'Catalan (Spain)',
    'yue-Hant-HK': 'Chinese, Cantonese (Traditional, Hong Kong)',
    'cmn-Hans-CN': 'Chinese, Mandarin (Simplified, China)',
    'cmn-Hans-HK': 'Chinese, Mandarin (Simplified, Hong Kong)',
    'cmn-Hant-TW': 'Chinese, Mandarin (Traditional, Taiwan)',
    'hr-HR': 'Croatian (Croatia)',
    'cs-CZ': 'Czech (Czech Republic)',
    'da-DK': 'Danish (Denmark)',
    'nl-NL': 'Dutch (Netherlands)',
    'en-AU': 'English (Australia)',
    'en-CA': 'English (Canada)',
    'en-GH': 'English (Ghana)',
    'en-IN': 'English (India)',
    'en-IE': 'English (Ireland)',
    'en-KE': 'English (Kenya)',
    'en-NZ': 'English (New Zealand)',
    'en-NG': 'English (Nigeria)',
    'en-PH': 'English (Philippines)',
    'en-ZA': 'English (South Africa)',
    'en-TZ': 'English (Tanzania)',
    'en-GB': 'English (United Kingdom)',
    'en-US': 'English (United States)',
    'fil-PH': 'Filipino (Philippines)',
    'fi-FI': 'Finnish (Finland)',
    'fr-CA': 'French (Canada)',
    'fr-FR': 'French (France)',
    'gl-ES': 'Galician (Spain)',
    'ka-GE': 'Georgian (Georgia)',
    'de-DE': 'German (Germany)',
    'el-GR': 'Greek (Greece)',
    'gu-IN': 'Gujarati (India)',
    'he-IL': 'Hebrew (Israel)',
    'hi-IN': 'Hindi (India)',
    'hu-HU': 'Hungarian (Hungary)',
    'is-IS': 'Icelandic (Iceland)',
    'id-ID': 'Indonesian (Indonesia)',
    'it-IT': 'Italian (Italy)',
    'ja-JP': 'Japanese (Japan)',
    'jv-ID': 'Javanese (Indonesia)',
    'kn-IN': 'Kannada (India)',
    'km-KH': 'Khmer (Cambodia)',
    'ko-KR': 'Korean (South Korea)',
    'lo-LA': 'Lao (Laos)',
    'lv-LV': 'Latvian (Latvia)',
    'lt-LT': 'Lithuanian (Lithuania)',
    'ms-MY': 'Malay (Malaysia)',
    'ml-IN': 'Malayalam (India)',
    'mr-IN': 'Marathi (India)',
    'ne-NP': 'Nepali (Nepal)',
    'nb-NO': 'Norwegian Bokmål (Norway)',
    'fa-IR': 'Persian (Iran)',
    'pl-PL': 'Polish (Poland)',
    'pt-BR': 'Portuguese (Brazil)',
    'pt-PT': 'Portuguese (Portugal)',
    'ro-RO': 'Romanian (Romania)',
    'ru-RU': 'Russian (Russia)',
    'sr-RS': 'Serbian (Serbia)',
    'si-LK': 'Sinhala (Sri Lanka)',
    'sk-SK': 'Slovak (Slovakia)',
    'sl-SI': 'Slovenian (Slovenia)',
    'es-AR': 'Spanish (Argentina)',
    'es-BO': 'Spanish (Bolivia)',
    'es-CL': 'Spanish (Chile)',
    'es-CO': 'Spanish (Colombia)',
    'es-CR': 'Spanish (Costa Rica)',
    'es-DO': 'Spanish (Dominican Republic)',
    'es-EC': 'Spanish (Ecuador)',
    'es-SV': 'Spanish (El Salvador)',
    'es-GT': 'Spanish (Guatemala)',
    'es-HN': 'Spanish (Honduras)',
    'es-MX': 'Spanish (Mexico)',
    'es-NI': 'Spanish (Nicaragua)',
    'es-PA': 'Spanish (Panama)',
    'es-PY': 'Spanish (Paraguay)',
    'es-PE': 'Spanish (Peru)',
    'es-PR': 'Spanish (Puerto Rico)',
    'es-ES': 'Spanish (Spain)',
    'es-US': 'Spanish (United States)',
    'es-UY': 'Spanish (Uruguay)',
    'es-VE': 'Spanish (Venezuela)',
    'su-ID': 'Sundanese (Indonesia)',
    'sw-KE': 'Swahili (Kenya)',
    'sw-TZ': 'Swahili (Tanzania)',
    'sv-SE': 'Swedish (Sweden)',
    'ta-IN': 'Tamil (India)',
    'ta-MY': 'Tamil (Malaysia)',
    'ta-SG': 'Tamil (Singapore)',
    'ta-LK': 'Tamil (Sri Lanka)',
    'te-IN': 'Telugu (India)',
    'th-TH': 'Thai (Thailand)',
    'tr-TR': 'Turkish (Turkey)',
    'uk-UA': 'Ukrainian (Ukraine)',
    'ur-IN': 'Urdu (India)',
    'ur-PK': 'Urdu (Pakistan)',
    'vi-VN': 'Vietnamese (Vietnam)',
    'zu-ZA': 'Zulu (South Africa)',
};
