import React from 'react';
import {
    Typography,
    Grid,
    Card,
    CardHeader,
    Avatar,
    Slide,
} from '@material-ui/core/';
import PropTypes from 'prop-types';
import { availableLanguages , exercisesData, exercises, infos } from './Texts';
import Result from './Result';
import RecordingExercise from '../RecordingExercise';
import LanguageChange from '../LanguageChange';

const style = {
    CenterInfo: {
        textAlign: 'center'
    }
};

class SpeakTooFastAdvanced extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        lastLanguage: PropTypes.string.isRequired,
        user: PropTypes.object.isRequired,
        mySpeeches: PropTypes.object.isRequired,
        exercises: PropTypes.object.isRequired,
        setUserState: PropTypes.func.isRequired,
    };

    state = {
        language: this.props.lastLanguage
    };

    updateStateViaProps = (state, status) => {
        this.setState({ [state]: status });
    };
    
    menu = () => {
        return (
            <React.Fragment>
                <LanguageChange 
                    language={this.state.language}
                    availableLanguages={availableLanguages}
                    updateStateViaProps={this.updateStateViaProps}
                />
            </React.Fragment>
        );
    };

    navigationExercises = pageNumber => {
        return (
            <React.Fragment>
                <Slide
                    direction="up"
                    in={pageNumber === 1}
                    mountOnEnter
                    unmountOnExit
                >
                    <RecordingExercise
                        key={`SpeakTooFastAdvancedRecordingExercise${1}`}
                        {...this.props}
                        menu={this.menu}
                        language={this.state.language}
                        exerciseLanguage={this.exerciseLanguage}
                        exerciseNumber={exercisesData[1].exerciseNumber}
                        title={exercisesData[1].title}
                        titleExercise={exercisesData[1].titleExercise}
                        exercisesContent={exercises[
                            `exercise${exercisesData[1].exerciseNumber}`]}
                        infos={infos}
                        exerciseType={'speakTooFastAdvanced'}
                        result={Result}
                    />
                </Slide>

                <Slide
                    direction="up"
                    in={pageNumber === 2}
                    mountOnEnter
                    unmountOnExit
                >
                     <RecordingExercise
                        {...this.props}
                        menu={this.menu}
                        language={this.state.language}
                        exerciseLanguage={this.exerciseLanguage}
                        key={`SpeakTooFastRecordingExercise${2}`}
                        exerciseNumber={exercisesData[2].exerciseNumber}
                        title={exercisesData[2].title}
                        titleExercise={exercisesData[2].titleExercise}

                        exercisesContent={exercises[
                            `exercise${exercisesData[2].exerciseNumber}`]}
                        infos={infos}
                        exerciseType={'speakTooFastAdvanced'}
                        result={Result}
                    />
                </Slide>
                <Slide
                    direction="up"
                    in={pageNumber === 3}
                    mountOnEnter
                    unmountOnExit
                >
                   <RecordingExercise
                        key={`SpeakTooFastRecordingExercise${3}`}
                        // From Router
                        history={this.props.history}
                        lastLanguage={this.props.lastLanguage}
                        user={this.props.user}
                        mySpeeches={this.props.mySpeeches}
                        exercises={this.props.exercises}
                        setUserState={this.props.setUserState}
                        // Defined here
                        exerciseType={'speakTooFastAdvanced'}
                        exerciseLanguage={this.exerciseLanguage}
                        exercisesContent={exercises[
                            `exercise${exercisesData[3].exerciseNumber}`]}
                        exerciseNumber={exercisesData[3].exerciseNumber}
                        titleExercise={exercisesData[3].titleExercise}
                        title={exercisesData[3].title}
                        infos={infos}
                        result={Result}
                        language={this.state.language}
                        menu={this.menu}
                    />
                </Slide>
                <Slide
                    direction="left"
                    in={!pageNumber}
                    mountOnEnter
                    unmountOnExit
                >
                    <Typography style={style.CenterInfo}>
                        We invite you to choose one of the exercise above.
                    </Typography>
                </Slide>
            </React.Fragment>
        );
    };

    exerciseLanguage = () => {
        switch (this.state.language) {
            case 'nl-NL':
                return 'NL';
            default:
                return 'EN';
        }
    };

    changePage = (curPage, destPage) => {
        const newPage = curPage === destPage ? 0 : destPage;
        const { pathname } = this.props.location;
        if (newPage > 0) {
            this.props.history.push(`${pathname}?page=${newPage}`);
        } else {
            this.props.history.push(pathname);
        }
    };

    render() {
        const pageNumber = Math.max(0, Math.min(3,
            parseInt(new URLSearchParams(
                this.props.location.search).get('page')) || 0));
        return (
            <React.Fragment>
                <main>
                    <div className="container">
                        <Typography component="h1" variant="h4" gutterBottom>
                            Speak Too Fast - Advanced exercises
                        </Typography>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={4}>
                                <Card
                                    style={style.Card}
                                    className="has-pointer"
                                    onClick={() => {
                                        this.changePage(pageNumber, 1);
                                    }}
                                >
                                    <CardHeader
                                        avatar={<Avatar>1</Avatar>}
                                        title="Prestissimo"
                                    />
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Card
                                    style={style.Card}
                                    className="has-pointer"
                                    onClick={() => {
                                        this.changePage(pageNumber, 2);
                                    }}
                                >
                                    <CardHeader
                                        avatar={<Avatar>2</Avatar>}
                                        title="Staccato"
                                    />
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Card
                                    style={style.Card}
                                    className="has-pointer"
                                    onClick={() => {
                                        this.changePage(pageNumber, 3);
                                    }}
                                >
                                    <CardHeader
                                        avatar={<Avatar>3</Avatar>}
                                        title="Metronome "
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                {this.navigationExercises(pageNumber)}
                            </Grid>
                        </Grid>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}
export default SpeakTooFastAdvanced;
