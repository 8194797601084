import React from 'react';
import PropTypes from 'prop-types';

import { Button, Grid, Paper, Typography, Divider } from '@material-ui/core/';

import { convertDuration } from '../../helpers';
import SpeechRecording from './SpeechRecording';

const style = {
    Result: {
        maxWidth: '570px',
        margin: '0 auto'
    },
    Divider: {
        margin: '1em 0 1em'
    },
    Paper: {
        minHeight: '185px'
    },
    Tab: {
        display: 'inline-block',
        width: '40px'
    },
};

class RecordingExercise extends React.Component {
    static propTypes = {
        // From Router
        history: PropTypes.object.isRequired,
        lastLanguage: PropTypes.string.isRequired,
        user: PropTypes.object.isRequired,
        mySpeeches: PropTypes.object.isRequired,
        exercises: PropTypes.object.isRequired,
        parkiParams: PropTypes.object,
        setUserState: PropTypes.func.isRequired,
        // From index.js (parent) or its inheritors
        exerciseType: PropTypes.string.isRequired,
        exerciseLanguage: PropTypes.func.isRequired,
        exercisesContent: PropTypes.object.isRequired,
        exerciseNumber: PropTypes.number.isRequired,
        titleExercise: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        infos: PropTypes.object.isRequired,
        result: PropTypes.func.isRequired,
        language: PropTypes.string.isRequired,
        numbered: PropTypes.bool,
        menu: PropTypes.func,
        unlockExercise: PropTypes.func,
        parkinsonException: PropTypes.bool,
    };

    state = {
        uploadStatus: 'READY', // READY, COMPRESSING, UPLOADING, FAILED
        finish: false,
        report: undefined,
        progress: 0,
        remove: false,
        exercise: this.props.exercisesContent[this.props.exerciseLanguage()],
        reportViewed: false,
        expectionalState: undefined
    };

    initialState = this.state;

    checkSyllables() {
        const {
            exercise: {
                syllables,
                syllablesFactor: [lowerMargin, upperMargin],
            },
            report: {
                stats: { syllable_count } ,
            },
        } = this.state;
        return (
            syllable_count >= Math.floor(lowerMargin * syllables) &&
            syllable_count <= Math.ceil(upperMargin * syllables)
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.language !== prevProps.language) this.changeLanguage();
        if (this.state.report && !prevState.report) this.reportReceived();
        // If you extend this, please do this for the inherited classes, too
    }

    resetState = () => {
        // Don't forget the inherited classes hen updating this method
        const { exercise, reportViewed, ...state } = this.initialState;
        this.setState(state);
    };

    updateStateViaProps = (state, status) => {
        this.setState({ [state]: status });
    };

    changeLanguage = (callback = () => {}) => {
        this.setState({
            exercise: this.props.exercisesContent[this.props.exerciseLanguage()]
        }, callback);
    };

    reportReceived = () => {
        if (this.checkSyllables()) {
            const unlockExercise = this.props.unlockExercise;
            const exerciseNumber = this.props.exerciseNumber;
            unlockExercise && unlockExercise(exerciseNumber + 1);
        }
    };

    exerciseInfo = () => {
        const info = this.props.infos[`info${this.props.exerciseNumber}`];
        return info.map((el, paragraph) => (
            <Typography
                key={paragraph}
                component={el.type === 'ul' ? el.type : null}
                children={el.props.children ? el.props.children : el}
                gutterBottom
            />
        ));
    };

    exerciseText = () => {
        const ExerciseTool = this.props.exercisesContent.ExerciseTool;
        const ExerciseToolComponent = ExerciseTool && ExerciseTool.type;
        const exerciseContent = this.state.exercise.text.map(
            (text, paragraph) => (
                <React.Fragment key={paragraph}>
                    <Grid item xs={12}>
                        <Typography gutterBottom>
                            {this.props.numbered && (
                                <span style={style.Tab}>{paragraph + 1}.</span>
                            )}
                            {text}
                        </Typography>
                    </Grid>
                </React.Fragment>
            )
        );

        return (
            <React.Fragment>
                {this.props.menu ? this.props.menu() : null}
                <Divider style={style.Divider} />
                <Grid
                    container
                    spacing={this.props.numbered ? 8 : 24}
                    children={exerciseContent}
                />
                {
                    ExerciseToolComponent &&
                    <ExerciseToolComponent
                        updateStateViaProps={this.updateStateViaProps}
                        expectionalStateName={'expectionalState'}
                    />
                }
            </React.Fragment>
        );
    };

    showExerciseResult() {
        const stats = this.state.report.stats;
        const time = convertDuration(stats.clipped_duration * 1000);
        const ResultComponent = () => (
            <this.props.result
                // From Router
                parkiParams={this.props.parkiParams}
                // From index.js
                exerciseNumber={this.props.exerciseNumber}
                // Defined here
                stats={stats}
                averageSyllables={this.state.exercise.syllables}
                type={this.checkSyllables() ? 'success' : 'fail'}
                expectionalState={this.state.expectionalState}
            />
        );
        return (
            <Grid container spacing={24}>
                {!this.checkSyllables()
                    ? <React.Fragment>
                        <Grid item xs={12}>
                            <Typography>
                                You spoke {stats.syllable_count} syllables
                                in {time}.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={style.Result}>
                            <ResultComponent/>
                        </Grid>
                    </React.Fragment>
                    : <ResultComponent/>
                }
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="icon-button btn--primary"
                        onClick={() => {
                            this.props.history.push(
                                `/reports/${this.state.report.id}/`
                            );
                        }}
                    >
                        {this.props.parkinsonException
                            ? 'Bekijk het rapport'
                            : 'Go to your report'
                        }
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="icon-button btn--primary"
                        onClick={() => {
                            this.resetState();
                        }}
                    >
                        {this.props.parkinsonException
                            ? 'Probeer opnieuw'
                            : 'Retry'
                        }
                    </Button>
                </Grid>
            </Grid>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Paper
                    elevation={1}
                    style={style.Paper}
                    className="customPaper"
                >
                    <Typography component="h2" variant="title" gutterBottom>
                        {this.props.title}
                    </Typography>
                    {!this.state.finish ? (
                        <React.Fragment>
                            <SpeechRecording
                                // From Router
                                user={this.props.user}
                                mySpeeches={this.props.mySpeeches}
                                exercises={this.props.exercises}
                                setUserState={this.props.setUserState}
                                parkiParams={this.props.parkiParams}
                                // From index.js
                                exerciseNumber={this.props.exerciseNumber}
                                exerciseType={this.props.exerciseType}
                                parkinsonException={this.props.parkinsonException}
                                titleExercise={this.props.titleExercise}
                                // Defined here
                                exerciseInfo={this.exerciseInfo()}
                                updateStateViaProps={this.updateStateViaProps}
                                resetState={this.resetState}
                                reportViewed={this.state.reportViewed}
                                language={
                                    this.props.language ||
                                    this.props.lastLanguage
                                }
                            />
                            {!this.state.remove && this.exerciseText()}
                        </React.Fragment>
                    ) : this.state.report ? (
                        this.showExerciseResult()
                    ) : null}
                </Paper>
                {this.state.uploadStatus === 'UPLOADING' &&
                    !this.state.remove && (
                        <Typography gutterBottom>
                            Uploading ({this.state.progress.toFixed(0)}%)
                        </Typography>
                    )}
                {this.state.uploadStatus === 'COMPRESSING' &&
                    !this.state.remove && (
                        <Typography gutterBottom>
                            Compressing audio...
                        </Typography>
                    )}
            </React.Fragment>
        );
    }
}

export default RecordingExercise;
export { style };
