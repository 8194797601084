import React from 'react';
import PropTypes from 'prop-types';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import * as firebaseui from 'firebaseui';
import { Paper, Typography } from '@material-ui/core/';

import { site } from './../config.js';
import { isVrt } from '../helpers';
import LoadingPage from './LoadingPage';

const style = {
    Paper: {
        maxWidth: '720px',
        margin: '3em auto',
        padding: '2em 3em',
    },
    Logo: {
        maxWidth: '300px',
        marginBottom: '2em',
    },
    Loading: {
        fontSize: '70px',
        textAlign: 'center',
        color: 'white',
    },
};

class SignOnPage extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
    };

    state = {
        loginStarted: false,
    };

    uiConfig = {
        signInFlow: 'redirect',  // 'popup' cannot handle e-mail sign-in
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
                forceSameDevice: false,
                emailLinkSignIn: () => {
                    return {
                        url: window.location.href,
                        handleCodeInApp: true,
                    }
                },
            },
        ],
        credentialHelper: firebaseui.auth.CredentialHelper.NONE,
        callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                console.log('User is logged in');
                return false;
            },
        },
    };

    componentDidMount () {
        console.log('Wait for login');
        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            () => this.setState({ loginStarted: true })
        );
    }

    componentWillUnmount() {
        this.unregisterAuthObserver();
    }

    render () {
        if (!this.state.loginStarted) {
            return <LoadingPage />;
        }
        return (
            <Paper elevation={1} style={style.Paper}>
                <object
                    type="image/svg+xml"
                    data={site.logo}
                    aria-label={"Logo " + site.name}
                    style={style.Logo}
                />
                <Typography gutterBottom>
                    Dear user, welcome at {site.nameShort}&apos;s speaking arena.
                </Typography>
                <Typography gutterBottom>
                    Meet {site.nameShort}, our coaching bot that
                    improves your communication skills through dynamic
                    feedback, analysis and smart exercises, based on voice
                    analysis and artificial intelligence.
                </Typography>
                <Typography gutterBottom>
                    Our privacy notice can be read <a
                        href={`${site.website}privacy-notice`}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Privacy Notice"
                    >here</a>.
                </Typography>
                <StyledFirebaseAuth
                    uiConfig={this.uiConfig}
                    firebaseAuth={firebase.auth()}
                />
                {
                    isVrt() &&
                    <React.Fragment>
                        <br/>
                        <Typography component="h3" variant="h5" gutterBottom>Beste VRT stem</Typography>
                        <Typography gutterBottom>
                            Samen met de stemcoaches van de VRT onderzoeken wij bij {site.name} manieren om het stemcoachingsproces te kwantificeren en ondersteuning te bieden die gedeeltelijk geautomatiseerd is. Hiervoor verwerken we geluidsopnames om die te analyseren en u zo feedback te kunnen geven.
                        </Typography>
                        <Typography component="h3" variant="h6" gutterBottom>
                            Welke data gebruikt {site.nameShort}?
                        </Typography>
                        <Typography component="ul">
                            <li>
                                De geluidsopname die u oplaadt of opneemt, inclusief data die u invult in de tekstvelden zoals titel en opmerkingen.
                            </li>
                            <li>
                                Uw e-mailadres zodat u toegang krijgt tot uw account.
                            </li>
                        </Typography>
                        <Typography component="h3" variant="h6" gutterBottom>
                            Wat gebeurt er met de data van de gebruiker?
                        </Typography>
                        <Typography gutterBottom>
                            We analyseren de geluidsopnames anoniem en automatisch, op basis hiervan tonen we een rapport met het resultaat. Na afloop van het traject verwijderen we alle data.
                        </Typography>
                        <Typography gutterBottom>
                            Dit is de <a
                            href="https://www.winston-analytics.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title={"Website " + site.name}
                        >link naar de website van {site.name}</a> voor meer informatie. U kunt mij ook
                            altijd contacteren via <a
                            href="mailto:thomas@winston-analytics.com"
                            title={"E-mail " + site.name}
                        >
                            e-mail
                        </a>.
                        </Typography>
                        <Typography gutterBottom>
                            Bedankt om mee te werken aan dit experiment. Het helpt ons en de VRT meer inzicht te krijgen in de eigenschappen van een goede stem.
                        </Typography>
                        <br />
                        <Typography gutterBottom>
                            Thomas Vervaet<br />
                            {site.name}
                        </Typography>
                    </React.Fragment>
                }
            </Paper>
        );
    }
}

export default SignOnPage;