import React from 'react';
import { rebase } from '../rebase';
import PropTypes from 'prop-types';
import {
    Typography,
    Grid,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    IconButton
} from '@material-ui/core/';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddComment from '@material-ui/icons/AddComment';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { isEmptyObject } from '../helpers';

const style = {
    SpeechComment: {
        paddingTop: '.65em'
    },
    DeleteCommentButton: {
        position: 'absolute',
        marginTop: '-0.65em',
    },
    DeleteCommentButtonCheat: {
        display: 'none',
    },
};

class SpeechComments  extends React.Component {
    static propTypes = {
        speech: PropTypes.shape({
            id: PropTypes.string.isRequired,
            owner: PropTypes.string,
            sharedWith: PropTypes.object
        }).isRequired,
        user: PropTypes.object.isRequired,
        commentEnabled: PropTypes.bool.isRequired,
        comments: PropTypes.object.isRequired,
        collapsible: PropTypes.bool.isRequired
    };

    state = {
        newComment:'',
        panelOpen: false,
        styleCheat: true
    };

    componentDidUpdate(prevProps) {
        if (this.props.commentEnabled !== prevProps.commentEnabled)
            if (this.props.commentEnabled !== this.state.panelOpen && 
                this.props.commentEnabled) 
                    this.handleChange();
    }

    isOwner = () => this.props.speech.owner === this.props.user.uid;

    canComment = () =>
        this.isOwner() ||
        (this.props.speech.sharedWith &&
            this.props.speech.sharedWith[this.props.user.uid]);

    hasComments = () => !isEmptyObject(this.props.comments);

    addComment = () => {
        const { newComment } = this.state;
        if (newComment && newComment.replace(/\s/g, '').length)
        {
            const data = {
                commenter: this.props.user.uid,
                commenterEmail: this.props.user.email,
                timestamp: Date.now(),
                text: newComment,
            };
            rebase.push(`speechComments/${this.props.speech.id}`, { data })
                .then(() => {
                    this.setState({ newComment: '' });
                })
                .catch(err => {
                    console.log('Failed to push comment to Firebase:', err);
                });
        }
        else console.log("Unadapted comment")
    };

    deleteComment = commentId => {
        rebase.remove(`speechComments/${this.props.speech.id}/${commentId}`)
            .catch(err => {
                console.log('Failed to delete comment from Firebase:', err);
            });
    };

    handleChange = () => {
        if (this.state.panelOpen) {
            this.setState({ styleCheat: true });
            setTimeout(
                function() {
                    this.setState(prevState => ({
                        panelOpen: !prevState.panelOpen
                    }));
                }.bind(this),
                500
            );
        } else {
            this.setState(prevState => ({ panelOpen: !prevState.panelOpen }));
            setTimeout(
                function() {
                    this.setState({ styleCheat: false });
                }.bind(this),
                500
            );
        }
    };


    render() {
        const { styleCheat, panelOpen, newComment } = this.state;
        const { collapsible, comments, commentEnabled, user } = this.props;

        return collapsible ? (
            <React.Fragment>
                {this.hasComments() && Object.entries(comments).length > 0 && (
                    <Grid item xs={12}>
                        <ExpansionPanel
                            expanded={panelOpen}
                            onChange={() => this.handleChange()}
                        >
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography>
                                    Comments ({Object.entries(comments).length})
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    {Object.entries(comments).map(
                                        ([commentId, comment]) => (
                                            <Grid item xs={12} key={commentId}>
                                                <Typography
                                                    style={style.SpeechComment}
                                                    color="textSecondary"
                                                >
                                                    {comment.commenterEmail} on {new Date(
                                                        comment.timestamp
                                                    ).toLocaleString()}
                                                    {commentEnabled &&
                                                        panelOpen &&
                                                        comment.commenter ===
                                                            user.uid && (
                                                            <IconButton
                                                                style={ styleCheat ? style.DeleteCommentButtonCheat : style.DeleteCommentButton }
                                                                aria-label="Delete comment"
                                                                onClick={() =>
                                                                    this.deleteComment(
                                                                        commentId
                                                                    )
                                                                }
                                                            >
                                                                <DeleteOutline />
                                                            </IconButton>
                                                        )}
                                                </Typography>
                                                <Typography>
                                                    {comment.text}
                                                </Typography>
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                )}

                {this.canComment() && commentEnabled && (
                    <React.Fragment>
                        <Grid item xs={10} md={11}>
                            <input
                                type="text"
                                name="newComment"
                                id="newComment"
                                disabled={!this.canComment()}
                                value={newComment}
                                placeholder={
                                    this.isOwner()
                                        ? 'Interact with the people you share your speech with'
                                        : 'Interact with the owner of the speech'
                                }
                                onChange={e => this.setState({newComment: e.target.value})}
                                onKeyDown={e => e.key === 'Enter' && this.addComment()}
                            />
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <IconButton
                                aria-label="Add comment"
                                disabled={!this.canComment()}
                                onClick={this.addComment}
                            >
                                <AddComment />
                            </IconButton>
                        </Grid>
                    </React.Fragment>
                )}
            </React.Fragment>
        ) : (
            (this.canComment() || this.hasComments()) && (
                <React.Fragment>
                    <Grid item xs={3} md={2}>
                        <label htmlFor="newComment">
                            <Typography>Comments</Typography>
                        </label>
                    </Grid>
                    <Grid item xs={9} md={10}>
                        <Grid container>
                            {Object.entries(comments).map(
                                ([commentId, comment]) => (
                                    <Grid item xs={11} md={11} key={commentId}>
                                        <Typography
                                            style={style.SpeechComment}
                                            color="textSecondary"
                                        >
                                            {comment.commenterEmail} on{' '}
                                            {new Date(
                                                comment.timestamp
                                            ).toLocaleString()}
                                            {comment.commenter ===
                                                this.props.user.uid && (
                                                <IconButton
                                                    style={
                                                        style.DeleteCommentButton
                                                    }
                                                    aria-label="Delete comment"
                                                    onClick={() =>
                                                        this.deleteComment(
                                                            commentId
                                                        )
                                                    }
                                                >
                                                    <DeleteOutline />
                                                </IconButton>
                                            )}
                                        </Typography>
                                        <Typography>{comment.text}</Typography>
                                    </Grid>
                                )
                            )}
                            {this.canComment() && (
                                <React.Fragment>
                                    <Grid item xs={10} md={11}>
                                        <input
                                            type="text"
                                            name="newComment"
                                            id="newComment"
                                            disabled={!this.canComment()}
                                            value={newComment}
                                            placeholder={
                                                this.isOwner()
                                                    ? 'Interact with the people you share your speech with'
                                                    : 'Interact with the owner of the speech'
                                            }
                                            onChange={e => this.setState({ newComment: e.target.value })}
                                            onKeyDown={e => e.key === 'Enter' && this.addComment()}
                                        />
                                    </Grid>
                                    <Grid item xs={2} md={1}>
                                        <IconButton
                                            aria-label="Add comment"
                                            disabled={!this.canComment()}
                                            onClick={this.addComment}
                                        >
                                            <AddComment />
                                        </IconButton>
                                    </Grid>
                                </React.Fragment>
                            )}
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        );
    }
}

export default SpeechComments;
