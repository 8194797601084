import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core/';


import { isVrt } from '../helpers';
import SpeechComments from './SpeechComments';

const style = {
    WhiteSpace : {
        whiteSpace : "pre-wrap"
    }
};

class SpeechDetails extends React.Component {
    static propTypes = {
        speech: PropTypes.shape({
            id: PropTypes.string.isRequired,
            speechType: PropTypes.string,
            vrtRole: PropTypes.string,
            vrtBrand: PropTypes.string,
            comment: PropTypes.string,
            timestamp: PropTypes.number.isRequired,
            owner: PropTypes.string,
            ownerEmail: PropTypes.string,
            sharedWith: PropTypes.object
        }).isRequired,
        shareSpeech: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        getUserEmail: PropTypes.func.isRequired,
        commentEnabled: PropTypes.bool.isRequired,
        comments: PropTypes.object.isRequired
    };

    state = {
        speechType: '',
        vrtRole: '',
        vrtBrand: '',
        isVrt: isVrt(),
        comment: '',
        timeString: '',
        shareEmails: [],
    };

   
    componentDidMount() {
        // Decouple state with props for performance reasons
        const { speechType, vrtRole, vrtBrand, comment } = this.props.speech;

        this.setState({ speechType, vrtRole, vrtBrand, comment });
        this.setTimeString(this.props.speech.timestamp);
        if (this.isOwner()) {
            this.setInitialShareEmails(this.props.speech.sharedWith);
        }
    }


    componentDidUpdate(prevProps) {
        ['speechType', 'vrtRole', 'vrtBrand', 'comment'].forEach(key => {
            if (prevProps.speech[key] !== this.props.speech[key]) {
                this.setState({ [key]: this.props.speech[key] });
            }
        });
        if (prevProps.speech.timestamp !== this.props.speech.timestamp) {
            this.setTimeString(this.props.speech.timestamp);
        }
    }

    isOwner = () => this.props.speech.owner === this.props.user.uid;

    setTimeString = timestamp => {
        const timeString = timestamp
            ? new Date(timestamp).toLocaleString()
            : '';
        this.setState({ timeString });
    };

    setInitialShareEmails = sharedWith => {
        if (sharedWith) {
            const emailPromises = Object.keys(sharedWith)
                .sort()
                .map(userId => this.props.getUserEmail(userId));

            Promise.all(emailPromises).then(emails => {
                this.setState({ shareEmails: emails });
            });
        }
    };

    render() {
        return (
            <form onSubmit={event => event.preventDefault()}>
                <Grid container spacing={16}>
                    {this.state.isVrt ? (
                        <React.Fragment>
                            {this.state.vrtRole && (
                                <>
                                    <Grid item xs={3} md={2}>
                                        <Typography>Role</Typography>
                                    </Grid>

                                    <Grid item xs={9} md={10}>
                                        <Typography>
                                            {this.state.vrtRole}
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                            {this.state.vrtBrand && (
                                <>
                                    <Grid item xs={3} md={2}>
                                        <Typography>Brand</Typography>
                                    </Grid>
                                    <Grid item xs={9} md={10}>
                                        <Typography>
                                            {this.state.vrtBrand}
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                        </React.Fragment>
                    ) : (
                        this.state.speechType && (
                            <React.Fragment>
                                <Grid item xs={3} md={2}>
                                    <Typography>Type</Typography>
                                </Grid>
                                <Grid item xs={9} md={10}>
                                    <Typography>
                                        {this.state.speechType}
                                    </Typography>
                                </Grid>
                            </React.Fragment>
                        )
                    )}

                    {this.state.comment && (
                        <>
                            <Grid item xs={3} md={2}>
                                <Typography>Notes</Typography>
                            </Grid>
                            <Grid item xs={9} md={10}>
                                <Typography style={style.WhiteSpace} >
                                    {this.state.comment}
                                </Typography>
                            </Grid>
                        </>
                    )}

                    {this.props.speech.timestamp && (
                        <>
                            <Grid item xs={3} md={2}>
                                <Typography>Timestamp</Typography>
                            </Grid>
                            <Grid item xs={9} md={10}>
                                <Typography>
                                    {
                                        new Date(this.props.speech.timestamp)
                                            .toLocaleString()
                                    }
                                </Typography>
                            </Grid>
                        </>
                    )}

                    {this.isOwner() && this.state.shareEmails.length > 0 && (
                        <>
                            <Grid item xs={3} md={2}>
                                <Typography>Shared with</Typography>
                            </Grid>

                            <Grid item xs={9} md={10}>
                                {this.state.shareEmails.length > 0 &&
                                    this.state.shareEmails.map(value => (
                                        <Grid item xs={12} key={value}>
                                            <Typography>{value} </Typography>
                                        </Grid>
                                    ))}
                            </Grid>
                        </>
                    )}

                    <SpeechComments  
                        {...this.props}
                        collapsible={true}
                    />

                </Grid>
            </form>
        );
    }
}

export default SpeechDetails;
