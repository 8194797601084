import React from 'react';

import RecordingExercise, { style } from '../RecordingExercise';
import {
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    LinearProgress,
    Typography
} from '@material-ui/core/';

const extendedStyle = {
    ...style,
    Card: {
        textAlign: 'center'
    },
    Exercise: {
        margin: '0 auto'
    },
    Button: {
        width: '45%'
    },
    Unset: {
        overflow: 'unset'
    },
};

class SpeakTooFastPageOneWordAtTime extends RecordingExercise {
    constructor(...props) {
        super(...props);
        this.state = {
            word: 0,
            wordList: [],
            ...this.state,
        };
        this.initialState = this.state;
    }

    componentDidMount() {
        this.setState({ wordList: this.getExerciseContent() });
        document.addEventListener('keydown', this.keyPress, false);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.language !== prevProps.language) {
            this.changeLanguage(
                () => this.setState({
                    wordList: this.getExerciseContent(),
                    word: 0,
                })
            );
        }
        if (this.state.report && !prevState.report) this.reportReceived();
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keyPress, false);
    }

    getExerciseContent = () => {
        const exerciseSplit = this.state.exercise.text.map(text => {
            return text.props.children.split(' ');
        });

        return [].concat(...exerciseSplit);
    };

    resetState = () => {
        // Don't forget the inherited classes hen updating this method
        const { wordList, exercise, reportViewed, ...state } = this.initialState;
        this.setState(state);
    };

    keyPress = e => {
        switch (e.keyCode) {
            case 37:
                if (this.state.word > 0)
                    this.setState({ word: this.state.word - 1 });
                break;
            case 39:
                if (this.state.word < this.state.wordList.length - 1)
                    this.setState({ word: this.state.word + 1 });
                break;
            default:
                console.log('not left or right');
                break;
        }
    };

    exerciseText = () => {
        const normalise = value =>
            ((value - 0) * 100) / (this.state.wordList.length - 1);
        return (
            <React.Fragment>
                {this.props.menu()}
                <Divider style={extendedStyle.Divider} />
                <Grid item xs={12} sm={6} style={extendedStyle.Exercise}>
                    <Card style={extendedStyle.Card}>
                        <CardContent>
                            <Typography variant="h4" style={extendedStyle.Unset}>
                                {this.state.wordList[this.state.word]}
                            </Typography>
                        </CardContent>
                        <LinearProgress
                            variant="determinate"
                            value={normalise(this.state.word)}
                        />
                    </Card>

                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className="icon-button btn--primary"
                            style={extendedStyle.Button}
                            disabled={!(this.state.word > 0)}
                            onClick={() => {
                                this.setState({ word: this.state.word - 1 });
                            }}
                        >
                            ◄
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className="icon-button btn--primary"
                            style={extendedStyle.Button}
                            disabled={
                                !(
                                    this.state.word <
                                    this.state.wordList.length - 1
                                )
                            }
                            onClick={() => {
                                this.setState({ word: this.state.word + 1 });
                            }}
                        >
                            ►
                        </Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

}

export default SpeakTooFastPageOneWordAtTime;
