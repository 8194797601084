import React from 'react';

import {Typography, IconButton} from '@material-ui/core/';
import SadIcon from '@material-ui/icons/SentimentDissatisfied';

const style = {
    NotFound: {
        fontSize: '20px',
        color: 'white',
        textAlign: 'center'
    },
    SadIcon: {
        width: '100px',
        fontSize: '100px',
        color: 'white'
    },
    Text: {
        color: 'white'
    }
};

const NotFoundPage = () => (
    <div className="is-flex">
        <div style={style.NotFound}>
            <IconButton><SadIcon  style={style.SadIcon} /></IconButton>
            <Typography component="h2" variant="h3" style={style.Text} gutterBottom>
                404 - Page Not Found!
            </Typography>

            <Typography component="h2" variant="h5" style={style.Text}>
                <a href="/"  style={style.Text}>Go home</a>
            </Typography>
        </div>
    </div>
);

export default NotFoundPage;