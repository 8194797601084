import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, Paper, Typography, SnackbarContent } from '@material-ui/core/';
import { convertDuration, toMinute } from '../../../helpers';

const style = {
    Result: {
        maxWidth: '570px',
        margin: '0 auto',
        paddingTop: '2em'
    }
};

class Result extends React.Component {
    static propTypes = {
        // From index.js
        exerciseNumber: PropTypes.number.isRequired,
        // From RecordingExercise (parent) or its inheritors
        stats: PropTypes.object.isRequired,
        averageSyllables: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
    };

    time = convertDuration(this.props.stats.clipped_duration * 1000);
    ppm = toMinute(this.props.stats.pause_rate);
    spm = toMinute(this.props.stats.speaking_rate);

    storyEvaluation = stats => {
        const { speaking_rate, range_good_pace } = stats;
        return (
            <>
                <Typography>
                    {speaking_rate < range_good_pace[0] ? (
                        <>This is below {toMinute(range_good_pace[0])} spm.</>
                    ) : speaking_rate > range_good_pace[1] ? (
                        <>This is above {toMinute(range_good_pace[1])} spm.</>
                    ) : (
                        <>
                            This is within the range of{' '}
                            {toMinute(range_good_pace[0])}
                            and {toMinute(range_good_pace[1])} spm.
                        </>
                    )}
                </Typography>
                <Typography>
                    For a story, we consider this to be
                    {speaking_rate < range_good_pace[0] ? (
                        <span> slow</span>
                    ) : speaking_rate > range_good_pace[1] ? (
                        <span> fast</span>
                    ) : (
                        <span> good</span>
                    )}
                    .
                </Typography>
            </>
        );
    };

    commonResult = () => {
        const { stats } = this.props;
        return (
            <>
                <Grid item xs={12}>
                    <Typography>
                        You spoke {stats.syllable_count} syllables 
                        in {this.time}. This brings your speaking rate on:
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Paper elevation={1} className="customPaper">
                        <Typography>{this.spm} spm</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    {this.storyEvaluation(stats)}
                </Grid>
            </>
        );
    };

    successText = {
        exercise2: (
            <Grid item xs={12} style={style.Result}>
                <SnackbarContent
                    className="is-success-snackbar"
                    message="The test is succesfull. Your microphone works well and we invite you to try your first exercise: Baseline exercise"
                />
            </Grid>
        ),

        exercise3: (
            <>
                {this.commonResult()}
                <Grid item xs={12}>
                    <Typography>
                        When doing this exercise, do you have the impression
                        that you speak slower of faster than usual? What do you
                        think causes this variation? How do you compare to
                        other circumstances when you are speaking?
                    </Typography>
                    <Typography>
                        Go to next page to try some other exercises and see how
                        this affects your pace.
                    </Typography>
                </Grid>
            </>
        ),

        exercise4: (
            <>
                {this.commonResult()}
                <Grid item xs={12}>
                    <Typography>
                        If your pace is faster than the first exercise, please
                        retry and slow down the rate when you go to the next
                        word.
                    </Typography>
                </Grid>
            </>
        ),

        exercise5: (
            <>
                {this.commonResult()}
                <Grid item xs={12}>
                    <Typography>
                        Did it help to slow down, compared to your first
                        exercise?
                    </Typography>
                    <Typography>
                        Go to next page to try some other exercises and see how
                        this affects your pace.
                    </Typography>
                </Grid>
            </>
        ),

        exercise6: (
            <>
                <Grid item xs={12}>
                    <Typography>
                        You spoke {this.props.stats.syllable_count} syllables
                        in {this.time}.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        Now, look at your pace, but also to your pauses. If you
                        have done the exercise right, you should have more than
                        20 pauses per minute (ppm). This is a lot, but it is
                        for the sake of the exercise.
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Paper elevation={1} className="customPaper">
                        <Typography>{this.spm} spm</Typography>
                        <Typography>{this.ppm} ppm</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    {this.storyEvaluation(this.props.stats)}
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        Pausing should have a significant impact on your pace.
                        In the following exercises, we will build on this.
                    </Typography>
                </Grid>
            </>
        ),
        exercise7: (
            <>
                <Grid item xs={12}>
                    <Typography>
                        You spoke {this.props.stats.syllable_count} syllables
                        in {this.time}.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        When looking for your pauses in the report, you should
                        end-up above 15 ppm (pauses per minute).
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Paper elevation={1} className="customPaper">
                        <Typography>{this.spm} spm</Typography>
                        <Typography>{this.ppm} ppm</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    {this.storyEvaluation(this.props.stats)}
                </Grid>
                <Grid item xs={12}>
                    <Typography gutterBottom>
                        Congratulations, you’ve completed the track “I Speak
                        Too Fast”. If you want more exercises to have control
                        over your speaking rate, you can have a look at 
                        our <Link to="/speaktoofast-advanced/">
                        advanced exercises</Link>.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography gutterBottom>
                        Keep these two simple tips in mind when you want to
                        speak slower:
                        <Typography component="ul" gutterBottom>
                            <li>
                                Emphasize words in your script.
                            </li>
                            <li>
                                Deliberately add pauses to replace
                                punctuations.
                            </li>
                        </Typography>
                    </Typography>
                    <Typography>
                        Good luck with your next speaking gigs.
                    </Typography>
                </Grid>
            </>
        )
    };

    failText = exerciseNumber => {
        const { stats, averageSyllables } = this.props;
        if (stats.syllable_count < averageSyllables * 0.8) {
            switch (exerciseNumber) {
                case 2:
                    return (
                        <>
                            The test has failed. If you have read the whole
                            text, there might be something wrong with your
                            microphone. Please listen to your recording on the
                            Reports page and retry the test.
                        </>
                    );
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    return (
                        <>
                            Winston only heard {stats.syllable_count} syllables
                            while he should have heard {averageSyllables}. If
                            you have read the whole text, there might be
                            something wrong with your microphone. Please listen
                            to your recording on the bottom of your speech
                            report.
                        </>
                    );
                default:
                    return null;
            }
        } else {
            switch (exerciseNumber) {
                case 2:
                    return (
                        <>
                            The test has failed. If you have read the whole
                            text, there might be something wrong with your
                            microphone. Please listen to your recording on the
                            Reports page and retry the test.
                        </>
                    );
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    return (
                        <>
                            Winston heard {stats.syllable_count} syllables
                            while he should have heard only {averageSyllables}.
                            This can be caused by noise, which can be falsely
                            interpreted as syllables. Please listen to your
                            recording on the bottom of your speech report to
                            find out.
                        </>
                    );
                default:
                    return null;
            }
        }
    };

    render() {
        return this.props.type === 'fail' ? (
            <SnackbarContent
                className="is-warning-snackbar"
                message={this.failText(this.props.exerciseNumber)}
            />
        ) : this.props.type === 'success' ? (
            this.successText[`exercise${this.props.exerciseNumber}`]
        ) : null;
    }
}

export default Result;
